<style scoped>
</style>

<template >
  <div class="row mt-4">
    <div class="col-12 mx-auto align-middle">
      <TeeboxForm
        :course="course"
        :isEdit="isEdit"
        @sendForm="onGetForm($event)"
      ></TeeboxForm>
    </div>
  </div>
</template>

<script>
import TeeboxForm from '@/components/teeboxForm/TeeboxForm'
import CourseService from '@/services/CourseService'
import { mapActions } from 'vuex'

export default {
  name: 'Directory',
  components: {
    TeeboxForm
  },
  data: function () {
    return {
      isEdit: true,
      course: {
        teeboxes: []
      }
    }
  },
  mounted () {
    this.getCourse()
  },
  methods: {
    ...mapActions([
      'teebox/setTeeboxes'
    ]),
    getCourse: function () {
      CourseService.getCourseInfo(+this.$route.params['cId'])
        .then(res => {
          // set course
          this.course = res.data.course
          // format phone number
          this.course.phonenum = this.course.phonenum.replace(/\D+/g, '')
          // set teeboxes, because teeboxes get from baseTable, so it's nested object
          // instead of array of object
          let teeboxes = Object.keys(res.data.teeboxes).map(function (key) {
            return res.data.teeboxes[key]
          })
          this.course.teeboxes = teeboxes
        })
        .catch(errors => {
          console.log(errors)
        })
    },
    onGetForm: function (data) {
      this.$updateloading(1)
      CourseService.editCourseTeebox(data)
        .then(res => {
          this.$updateloading(-1)
          this['teebox/setTeeboxes'](data.teeboxes)
          this.$router.push({ name: 'Add Round', params: { tIds: res.data.updatedTeeboxIds } })
        })
        .catch(errors => {
          this.$updateloading(-1)
          this.$notify({
            type: 'error',
            group: 'errors',
            title: 'Unable to Save Teeboxes',
            text: errors
          })
        })
    }
  }
}
</script>
