import Vue from 'vue'
import Router from 'vue-router'
import UserService from '@/services/UserService'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/reset',
      name: 'Password Reset',
      meta: { layout: 'default', title: 'Password Reset' },
      component: require('@/views/resetPwdOrEmail/ResetPwdOrEmail').default // load sync
    },
    {
      path: '/login',
      name: 'Login',
      meta: { layout: 'default', title: 'Login' },
      component: require('@/views/login/Login').default // load sync
    },
    {
      path: '/home',
      name: 'Home',
      meta: { layout: 'default', title: 'Home' },
      component: require('@/views/Home').default // load sync
    },
    {
      path: '/recentPostings',
      name: 'Recent Postings',
      meta: { layout: 'default', title: 'Recent Postings' },
      component: require('@/views/recentPostings/RecentPostings').default // load sync
    },
    {
      path: '/currentOutings',
      name: 'Current Outings',
      meta: { layout: 'default', title: 'Current Outings' },
      component: require('@/views/Home').default // load sync
    },
    {
      path: '/handicapLookup',
      name: 'Handicap Lookup',
      meta: { layout: 'default', title: 'Handicap Lookup' },
      component: require('@/views/handicapLookup/HandicapLookup').default // load sync
    },
    {
      path: '/viewCourses',
      name: 'View Courses',
      meta: { layout: 'default', title: 'View Courses' },
      component: require('@/views/ViewCourses').default // load sync
    },
    {
      path: '/postRound',
      meta: { layout: 'default', title: 'Post Round' },
      component: require('@/views/PostRound').default, // load sync
      children: [
        {
          path: '',
          name: 'Set Round Course',
          meta: { layout: 'default', title: 'Set Round Course' },
          component: require('@/views/SetRoundCourse').default // load sync
        },
        {
          path: 'course',
          name: 'Select Round Course',
          meta: { layout: 'default', title: 'Set Round Course' },
          component: require('@/views/SetRoundCourse').default // load sync
        },
        {
          path: 'round',
          name: 'Add Round',
          meta: { layout: 'default', title: 'Add Round' },
          component: require('@/views/addRound/AddRound').default // load sync
        },
        {
          path: 'golfers',
          name: 'Select Golfers',
          meta: { layout: 'default', title: 'Select Golfers' },
          component: require('@/views/SetRoundGolfers').default // load sync
        },
        {
          path: 'golfers/scores',
          name: 'Set Golfers Information',
          meta: { layout: 'default', title: 'Set Golfers Information' },
          component: require('@/views/SetGolfersScore').default // load sync
        },
        {
          path: 'reviews',
          name: 'Review New Round',
          meta: { layout: 'default', title: 'Review New Round' },
          component: require('@/views/PostRoundReview').default // load sync
        }
      ]
    },
    {
      path: '/viewCourses/:cId',
      name: 'View Courses Detail',
      meta: { layout: 'default', title: 'View Courses Detail' },
      component: require('@/views/ViewCourseDetail').default, // load sync
      children: [
        {
          path: 'rounds',
          name: 'View Course Rounds',
          meta: { layout: 'default', title: 'View Course Rounds' },
          component: require('@/views/ViewMyCourseRounds').default // load sync
        },
        {
          path: 'memberRounds',
          name: 'View Member Rounds',
          meta: { layout: 'default', title: 'View Member Rounds' },
          component: require('@/views/ViewCourseRounds').default // load sync
        },
        {
          path: 'handicaps',
          name: 'View Course Handicaps',
          meta: { layout: 'default', title: 'View Course Handicaps' },
          component: require('@/views/ViewCourseHandicaps').default // load sync
        }
      ]
    },
    {
      path: '/leaderboard',
      name: 'Leaderboard',
      component: require('@/views/Leaderboard').default,
      children: [
        {
          path: '',
          name: 'Leaderboard',
          component: require('@/views/leaderboard/Code.vue').default
        },
        {
          path: ':code/:group?',
          name: 'Leaderboard',
          props: true,
          component: require('@/views/leaderboard/Main.vue').default
        }
      ]
    },
    {
      path: '/teetime/:tId/tourney/players',
      name: 'Tourney Players',
      props: true,
      meta: { layout: 'default', title: 'Tourney Players' },
      component: require('@/views/tourneyPlayers/TourneyPlayers').default // load sync
    },
    {
      path: '/teetime/:tId/tourney/signup',
      name: 'Tourney Signup',
      props: true,
      meta: { layout: 'default', title: 'Tourney Signup' },
      component: require('@/views/tourneyPlayers/TourneySignup').default // load sync
    },
    {
      path: '/addCourse/:prevRoute',
      name: 'Add Course',
      props: true,
      meta: { layout: 'default', title: 'Add Course' },
      component: require('@/views/AddCourse').default // load sync
    },
    {
      path: '/editCourse/:cId',
      name: 'Edit Course',
      props: true,
      meta: { layout: 'default', title: 'Edit Course' },
      component: require('@/views/EditCourse').default // load sync
    },
    {
      path: '/course/:cId/editTeebox',
      name: 'Edit Course Teebox',
      props: true,
      meta: { layout: 'default', title: 'Edit Course Teebox' },
      component: require('@/views/EditTeebox').default // load sync
    },
    {
      path: '/handicapDetail/:uId/:type',
      name: 'Handicap Detail',
      props: true,
      meta: { layout: 'default', title: 'Handicap Detail' },
      component: require('@/views/handicapDetail/HandicapDetail').default // load sync
    },
    {
      path: '/sendFeeler',
      name: 'Send Feeler',
      meta: { layout: 'default', title: 'Send Feeler' },
      component: require('@/views/addFeeler/AddFeeler').default // load sync
    },
    {
      path: '/editFeeler/:fId',
      name: 'Edit Feeler',
      meta: { layout: 'default', title: 'Edit Feeler' },
      component: require('@/views/editFeeler/EditFeeler').default // load sync
    },
    {
      path: '/handicapLookup/:uId',
      name: 'Handicap Lookup',
      props: true,
      meta: { layout: 'default', title: 'Handicap Lookup' },
      component: require('@/views/handicapLookup/HandicapLookup').default // load sync
    },
    {
      path: '/addTeetime',
      name: 'Add Teetime',
      meta: { layout: 'default', title: 'Add Teetime' },
      component: require('@/views/addTeetime/AddTeetime').default // load sync
    },
    {
      path: '/editTeetime/:tId',
      name: 'Edit Teetime',
      meta: { layout: 'default', title: 'Edit Teetime' },
      component: require('@/views/editTeetime/editTeetime').default // load sync
    },
    {
      path: '/settings',
      name: 'Settings',
      meta: { layout: 'default', title: 'Settings' },
      component: require('@/views/ChangeInfo').default // load sync
    },
    {
      path: '/tournament',
      name: 'Tournament',
      meta: { layout: 'default', title: 'Tournament' },
      component: require('@/views/Tournament').default // load sync
    },
    {
      path: '/visitors',
      name: 'Visitors',
      meta: { layout: 'default', title: 'Visitors' },
      component: () => import('@/views/Visitors')// load sync
    },
    {
      path: '/directory',
      name: 'Directory',
      meta: { layout: 'default', title: 'Directory' },
      component: () => import('@/views/Directory')// load sync
    },
    {
      path: '/contactUs',
      name: 'Contact US',
      meta: { layout: 'default', title: 'Contact US' },
      component: require('@/views/ContactUs').default // load sync
    },
    {
      path: '/resetPwd',
      name: 'Reset Temp Password',
      meta: { layout: 'default', title: 'Reset Temp Password' },
      component: require('@/views/ResetTempPwd').default // load sync
    },
    {
      path: '/register',
      name: 'Register',
      meta: { layout: 'default', title: 'Register' },
      component: require('@/views/Register').default // load sync
    },
    {
      path: '/confirm/:hash',
      name: 'Confirm',
      props: true,
      meta: { layout: 'default', title: 'Register' },
      component: require('@/views/Confirm').default // load sync
    },
    {
      path: '/redirect',
      name: 'Redirect Page',
      meta: { layout: 'default', title: 'Redirect Page' },
      component: require('@/views/RedirectPage').default // load sync
    },
    {
      path: '*',
      component: require('@/views/PageNotFound').default
    }
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // Those are the path 'name' not the url
  // For example:  name: 'Visitors'
  const publicPagesName = ['Login', 'Register', 'Confirm', 'Visitors', 'Contact US', 'Password Reset', 'Handicap Lookup', 'Handicap Detail', 'Tourney Signup', 'Tourney Players', 'Leaderboard']
  const authRequired = !publicPagesName.includes(to.name)
  const loggedIn = UserService.loggedIn()

  // first we need to check if we need to redirect or not
  if (to.path !== '/redirect') {
    // second check if user login
    if (authRequired && !loggedIn) {
      return next('/login')
    }

    // third check if user has set his own password
    if (authRequired && loggedIn && !UserService.isConfirmed() && to.fullPath !== '/resetPwd') {
      return next('/resetPwd')
    }
  }
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = 'Desert Nomads Golf Club - ' + nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

router.name = 'router'

export default router
