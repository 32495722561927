<template>
  <div>
    <button
      class="btn btn-info edit-col"
      v-on:click.prevent="editRound()"
    >Edit</button>
    <b-modal
      v-model="openModal"
      ok-title="Save"
      size="xl"
      title="Edit Round"
      @ok="saveRound"
      @shown="init"
      @hidden="loaded = false"
    >
      <div v-if="loaded">
        <RoundForm
          :isEdit="isEdit"
          :isModal="isModal"
          :round="round"
          :regions="regions"
          :teeboxes="teeboxes"
        ></RoundForm>
      </div>
      <div v-else style="text-align:center">
        <b-spinner variant="success" label="Loading" style="width: 3rem; height: 3rem;"></b-spinner>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import UserService from '@/services/UserService'
import RoundForm from '@/components/roundForm/RoundForm'

export default {
  name: 'EditRound',
  components: {
    RoundForm
  },
  data: function () {
    return {
      openModal: false,
      round: {
        course: {}
      },
      loaded: false
    }
  },
  props: {
    roundId: {
      default () {
        return [0]
      }
    },
    isEdit: {
      default () {
        return true
      }
    },
    isModal: {
      default () {
        return true
      }
    }
  },
  computed: {
    ...mapGetters({
      regions: 'info/regions',
      teeboxes: 'teebox/teeboxes'
    })
  },
  methods: {
    async init () {
      this.loaded = false
      await this.getRound()
      await this.getTeeboxes()
      await this.getRegions()
      this.loaded = true
    },
    ...mapActions([
      'teebox/searchTeeboxes',
      'info/getRegions'
    ]),
    ...mapMutations([
      'course/selectedCourses',
      'round/setRound'
    ]),
    async getTeeboxes () {
      this.$updateloading(1)
      let filters = this.createSearchFilter()
      await this['teebox/searchTeeboxes'](filters)
      this.$updateloading(-1)
    },
    async getRegions () {
      this.$updateloading(1)
      await this['info/getRegions']()
      this.$updateloading(-1)
    },
    async getRound () {
      this.$updateloading(1)
      let filters = { round_id: this.roundId }
      try {
        const response = await axios.get(UserService.baseUrl() + 'round/search', { params: filters })
        if (response && response.data.rounds) {
          this.round = response.data.rounds.results[0]
          this.round.course = {
            id: this.round.course_id,
            name: this.round.coursename
          }
        }
        this.$updateloading(-1)
      } catch (error) {
        console.dir(error)
      }
      this.$updateloading(-1)
    },
    createSearchFilter () {
      return {
        course_id: {
          operator: '=',
          value: this.round.course.id
        }
      }
    },
    editRound () {
      this.openModal = true
    },
    async saveRound () {
      this.errors = []
      if (!this.round.date) {
        this.errors.push('Date is Required!')
      }
      if (!this.round.teebox_id) {
        this.errors.push('Teebox is Required!')
      }
      if (!this.round.score) {
        this.errors.push('Score is Required!')
      }
      if (this.errors.length === 0) {
        try {
          const response = await axios.post(UserService.baseUrl() + 'round/save', JSON.stringify(this.round))
          if (response && response.data.round) {
            this.$parent.$emit('round-delete', [this.roundId])
          }
          this.$updateloading(-1)
        } catch (error) {
          console.dir(error)
        }
      }
    }
  }
}
</script>
