<style scoped>
</style>

<template >
  <div>
    <CourseRoundsTable
      :filters="filters"
      :roundInfos="roundInfos"
      @updateFilters="onGetFilters($event)"
      title="My Rounds At This Course"
    >
    </CourseRoundsTable>
  </div>
</template>

<script>
import RoundService from '@/services/RoundService'
import UserService from '@/services/UserService'
import CourseRoundsTable from '@/components/courseRoundsTable/CourseRoundsTable'
import AimCommonMixin from '@/mixins/aim.common.mixin'

export default {
  name: 'ViewMyCourseRounds',
  mixins: [AimCommonMixin],
  components: {
    CourseRoundsTable
  },
  props: {
    teeboxes: null,
    course: null
  },
  data: function () {
    return {
      filters: {
        sort: 'date',
        desc: true,
        size: 10,
        page: 1
      },
      roundInfos: null
    }
  },
  mounted: function () {
    this.filters['course_id'] = +this.$route.params.cId
    var user = UserService.getUser()
    if (user) {
      this.filters['user_id'] = +user.id
    }
    this.searchRounds()
  },
  methods: {
    searchRounds () {
      this.$updateloading(1)
      RoundService.searchRounds(this.SerializeFiltersMixin(this.filters))
        .then(res => {
          this.$updateloading(-1)
          this.roundInfos = res.data.rounds
        }).catch(errors => {
          this.$updateloading(-1)
          console.log(errors)
        })
    },
    onGetFilters (filters) {
      this.filters = filters
      this.searchRounds()
    }
  }
}
</script>
