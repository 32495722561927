<template>
  <div style="overflow: auto;">
    <table class="text-center table-lines">
      <tr>
        <th>Player</th>
        <th>Thru</th>
        <th>Gross</th>
        <th>Net</th>
        <th>Wolf Points</th>
      </tr>
      <tr v-for="s in scores" :key="s.key">
        <td>
          {{ s.last }} {{ s.first | initial}}
        </td>
        <td>{{ s.thru }}</td>
        <td>{{ s.gross | withE }}</td>
        <td>{{ s.net | withE }}</td>
        <td>{{ s.wscore | noScore }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    scores: {
      type: Array,
      default: () => { return [] }
    },
    wolf_scores: Object
  },

  data: function () {
    return {
      wolf_info: null
    }
  },

  filters: {
    initial (val) {
      return val.substring(0, 1)
    },
    withE (val) {
      if (val == null) {
        return 0
      }
      return val === 0 || val === '0' ? 'E' : val
    },
    noScore (val) {
      if (val == null) {
        return 0
      } else {
        return val
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .table-lines,.table-lines td,.table-lines th {
    border-collapse: collapse;
    border: 1px solid black;
    padding: 0.5rem 0;
  }

  table {
    width: 100%;
  }
</style>
