import InfoService from '@/services/InfoService'
import TeetimeService from '@/services/TeetimeService'

const initalState = {
  teetime: [],
  members: [],
  feeler: [],
  regions: []
}

export const state = { ...initalState }

// notice : actions handle asynchronous data
export const actions = {
  async fetchTeetime (context) {
    const response = await TeetimeService.getTeetimes()
    context.commit('setTeetime', response)
    return response
  },
  async fetchMemebers (context) {
    const response = await InfoService.getMembers()
    context.commit('setMembers', response)
    return response
  },
  async fetchFeeler (context) {
    const response = await InfoService.getFeelers()
    context.commit('setFeeler', response)
    return response
  },
  async getRegions (context) {
    const response = await InfoService.getRegions()
    context.commit('setRegions', response)
    return response
  }
}
// notice : mutation only handle synchronous data
export const mutations = {
  setTeetime (state, response) {
    state.teetime = response.data.teetime
  },
  setMembers (state, response) {
    state.members = response.data.members
  },
  setFeeler (state, response) {
    state.feeler = response.data.feeler
  },
  setRegions (state, response) {
    state.regions = response.data.regions
  }
}
const getters = {
  regions: (state) => {
    return state.regions
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
