<style lang='scss' scoped src='./CourseHandicapTable.scss'>
</style>

<template src='./CourseHandicapTable.html'>
</template>

<script>
import Page from '@/components/Page'

export default {
  components: {
    Page
  },
  props: {
    userInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    teeboxes: null,
    course: null,
    title: {
      type: String,
      default: function () {
        return 'Member Course Handicaps'
      }
    },
    filters: {
      type: Object
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    calculateCourseHandicap: function (user, teebox) {
      let a = +user.handicapindex
      let b = +teebox.slope
      let c = 113
      let d = +teebox.rating
      let e = +this.course.par
      return (a * b / c) + (d - e)
    },
    changeSortTo: function (sort) {
      if (this.filters['sort'] === sort) {
        this.filters['desc'] = !this.filters['desc']
      } else {
        this.filters['sort'] = sort
        this.filters['desc'] = true
      }
      // reset page to 1 when sorting
      this.filters['page'] = 1
      this.sentFilters()
    },
    sentFilters: function () {
      this.$emit('updateFilters', this.filters)
    },
    onChangePageTo: function (page) {
      this.filters['page'] = page
      this.sentFilters()
    },
    onGetSortFromCard (sort) {
      this.changeSortTo(sort)
    }
  }
}
</script>
