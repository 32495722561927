<style scoped lang="scss" src="./CourseRoundsTable.scss">
</style>

<template src='./CourseRoundsTable.html'>
</template>

<script>
import Page from '@/components/Page'

export default {
  name: 'CourseRoundsTable',
  components: {
    Page
  },
  props: {
    title: {
      type: String,
      default () {
        return 'Members Rounds At This Course'
      }
    },
    filters: {
      type: Object
    },
    roundInfos:
    {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    onChangePageTo: function (page) {
      this.filters['page'] = page
      this.sentFilters()
    },
    changeSortTo: function (sort) {
      if (this.filters['sort'] === sort) {
        this.filters['desc'] = !this.filters['desc']
      } else {
        this.filters['sort'] = sort
        this.filters['desc'] = true
      }
      // reset page to 1 when sorting
      this.filters['page'] = 1
      this.sentFilters()
    },
    sentFilters: function () {
      this.$emit('updateFilters', this.filters)
    }
  }
}
</script>
