<style scoped>
  .gainstroke {
    background-color: rgba(196, 0, 0, 0.8);
    color: white;
  }
</style>
<template>
  <div>
    <div class="text-right mb-4">
      <b-button @click="leave()">Logout</b-button>
    </div>
    <Scoring :code="code" @update="getScoreBoard" :group="group" :wolf_scores="scores.wolf" :new_wolf="scores.new_wolf"></Scoring>
    <div class="mt-4">
      <Sixes v-if="scores && +scores.contest_id == 14" :scores="scores.sixes"></Sixes>
      <div class="mt-4"></div>
      <IndividualStroke v-if="scores && scores.scores && scores.scores.length > 0 && scores.contest_id != 21" :scores="scores.scores" :contest_id="scores.contest_id"></IndividualStroke>
      <Wolf v-if="scores && +scores.contest_id == 21" :scores="scores.scores" :wolf_scores="scores.wolf"></Wolf>
      <div class="text-center mt-4" v-if="scores.grossskins && scores.grossskins.length > 0">
        <h3>Gross Skins</h3>
        <Skins :scores="scores.grossskins"></Skins>
      </div>
      <div class="text-center mt-4" v-if="scores.netskins && scores.netskins.length > 0">
        <h3>Net Skins</h3>
        <Skins :scores="scores.netskins"></Skins>
      </div>
    </div>
  </div>
</template>

<script>
import Scoring from '@/views/leaderboard/Scoring'
import IndividualStroke from '@/views/leaderboard/IndividualStroke'
import Sixes from '@/views/leaderboard/Sixes'
import TeetimeService from '@/services/TeetimeService'
import Skins from '@/views/leaderboard/Skins'
import Wolf from '@/views/leaderboard/Wolf'

export default {
  name: 'Leaderboard',
  data () {
    return {
      scores: [],
      notificationOpen: false
    }
  },

  components: {
    Sixes,
    Scoring,
    IndividualStroke,
    Skins,
    Wolf
  },

  props: {
    code: {
      type: [String, Number],
      default: null
    },
    group: {
      type: [String, Number],
      default: '0'
    }
  },

  mounted () {
    this.getScoreBoard({ full: true })
  },

  methods: {
    leave () {
      this.$router.push('/leaderboard')
    },

    getScoreBoard (data) {
      const full = data.full ? data.full : false
      const hole = data.hole ? (data.hole.hole ? data.hole.hole : data.hole) : 0
      TeetimeService.getScoreBoardByCode(this.code, hole).then(
        (response) => {
          this.scores = response.data.scores
          if (full && this.scores && this.scores.sixes && this.scores.sixes.notifynew && !this.notificationOpen && !response.data.readonly) {
            this.notificationOpen = true
            const h = this.$createElement
            this.$bvModal.msgBoxOk(
              [h('div', [h('p', 'Team 1: ' + this.scores.sixes.current.team1), h('p', 'Team 2: ' + this.scores.sixes.current.team2)])],
              { title: 'New teams are now assigned' }
            )
              .then(
                () => {
                  this.notificationOpen = false
                }
              )
          }
        }
      ).catch(err => {
        this.$notify({
          group: 'errors',
          title: err.response.data.error,
          type: 'error'
        })
      })
    }
  },

  computed: {
    loading () {
      return this.loadingCount > 0
    }
  }
}
</script>
