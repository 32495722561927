<template>
  <div>
    <button
      class="btn btn-danger edit-col"
      v-on:click.prevent="confirmDeleteRound()"
    >Delete</button>
    <b-modal
      v-model="openModal"
      ok-title="Delete"
      size="xl"
      title="Delete Round"
      @ok="deleteRound"
    >
      <div>
        <p>Are you sure you want to delete this round?</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import UserService from '@/services/UserService'

export default {
  name: 'DeleteRound',
  data: function () {
    return {
      openModal: false
    }
  },
  props: {
    roundId: {
      default () {
        return [0]
      }
    }
  },
  methods: {
    confirmDeleteRound: function () {
      this.openModal = true
    },
    deleteRound: async function () {
      if (this.roundId <= 0) {
        return false
      }
      this.$updateloading(1)
      try {
        const response = await axios.post(UserService.baseUrl() + 'round/delete', JSON.stringify({ round_id: this.roundId }))
        if (response) {
          this.openModal = false
          if (response.data.result === 'Success') {
            this.$parent.$emit('round-delete', [this.roundId])
          } else {
            alert('Failed to delete round!')
          }
        }
        this.$updateloading(-1)
      } catch (error) {
        console.dir(error)
      }
    }
  }
}
</script>
