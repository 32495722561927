<style scoped>
</style>

<template >
  <div class="mt-4 mb-4">
    <TeetimeForm
      :teetime="teetime"
      :isEdit="isEdit"
      :courses='courses'
      :contests='contests'
      :user='user'
      :teeboxes='teeboxes'
      @addTeetime="onAddTeetime($event)"
      @onSearchTeeboxes="onGetSearchTeeboxesFilter($event)"
    ></TeetimeForm>
  </div>
</template>

<script>
import TeetimeForm from '@/components/teetimeForm/TeetimeForm'
import CourseService from '@/services/CourseService'
import ContestService from '@/services/ContestService'
import UserService from '../../services/UserService'
import TeetimeService from '../../services/TeetimeService'
import TeeboxService from '../../services/TeeboxService'

import moment from 'moment'

export default {
  name: 'AddTeetime',
  components: {
    TeetimeForm
  },
  data: function () {
    return {
      isEdit: false,
      teetime: {
        ampm: 'am',
        date: null,
        day: null,
        hour: null,
        min: null,
        num_wanted: null,
        course_id: null,
        rate: null,
        tournament: 0,
        prepay: 0,
        contest_id: 0,
        contact_info: null,
        gross_skins: 0,
        net_skins: 0,
        send_email: null,
        // send_text: null,
        // send_text_signed: null,
        teebox_id: null
      },
      courses: [],
      contests: [],
      user: null,
      teeboxes: []
    }
  },
  created: function () {
    this.initTeetimeObj()
    this.getCourses()
    this.getContests()
    this.getUser()
  },
  methods: {
    initTeetimeObj: function () {
      this.setDate()
    },
    setDate: function () {
      let curt = new Date()
      this.teetime['date'] = moment(curt).format('YYYY-MM-DD')
      let times = this.getTimes(new Date())
      this.teetime['hour'] = times[0]
      this.teetime['min'] = times[1]
      this.teetime['ampm'] = times[2]
    },
    getTimes (date) {
      let ampm = 'am'
      let hours = date.getHours()
      let mins = +date.getMinutes()
      if (mins === 0) {
        mins = '00'
      } else if (mins <= 10) {
        mins = '10'
      } else if (mins <= 20) {
        mins = '20'
      } else if (mins <= 30) {
        mins = '30'
      } else if (mins <= 40) {
        mins = '40'
      } else if (mins <= 50) {
        mins = '50'
      } else {
        mins = '00'
        hours += 1
      }
      if (hours === 24) {
        hours = 0
      }

      if (hours >= 12) {
        ampm = 'pm'
      } else {
        ampm = 'am'
      }
      if (hours > 12) {
        hours -= 12
      } else if (hours === 12) {
        hours = 12
      }

      return [hours, mins, ampm]
    },
    getCourses: function () {
      this.$updateloading(1)
      CourseService.getCourses().then(res => {
        this.$updateloading(-1)
        this.courses = res.data.courses
      }).catch(error => {
        console.log(error)
        this.$updateloading(-1)
      })
    },
    getContests: function () {
      ContestService.getContests().then(res => {
        this.$updateloading(-1)
        this.contests = res.data.contests
      }).catch(error => {
        console.log(error)
        this.$updateloading(-1)
      })
    },
    getUser: function () {
      this.user = UserService.getUser()
    },
    onAddTeetime: function (teetime) {
      this.$updateloading(1)
      TeetimeService.addTeetime(teetime).then(res => {
        this.$router.push({ path: '/home' })
        this.$updateloading(-1)
      }).catch(error => {
        this.$notify({
          group: 'errors',
          title: 'Unable to Add Tee time',
          text: error.response.data.error,
          type: 'error'
        })
        console.log(error)
        this.$updateloading(-1)
      })
    },
    searchSelectCourseTeebox: function (filters) {
      this.$updateloading(1)
      TeeboxService.searchTeebox(filters).then(res => {
        this.$updateloading(-1)
        this.teeboxes = res.data.teeboxes
      }).catch(errors => {
        this.$updateloading(-1)
        console.log(errors)
      })
    },
    onGetSearchTeeboxesFilter: function (filters) {
      this.searchSelectCourseTeebox(filters)
    }
  }
}
</script>
