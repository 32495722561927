<style scoped>
</style>

<template >
  <div class="card mb-1" v-show="$slots.header || $slots.body || $slots.footer">
    <div class="card-header" v-if="$slots.header">
      <slot name="header"></slot>
    </div>

    <div class="card-body" v-if="$slots.body">
      <slot name="body"></slot>
    </div>

    <div class="card-footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AimActionCard',
  methods: {
    changeSelect: function (event) {
      this.$emit('updateSelect', true)
    }
  }
}
</script>
