<style lang='scss' src='./CourseFilter.scss' scoped>
</style>

<template src='./CourseFilter.html'>

</template>

<script>
import CollapseBtn from '@/components/CollapseBtn'
import InfoService from '@/services/InfoService'
import CourseService from '@/services/CourseService'
import AimCommonMixin from '@/mixins/aim.common.mixin'

export default {
  components: {
    CollapseBtn
  },
  mixins: [AimCommonMixin],
  props: {
    title: {
      type: String,
      default () {
        return 'Find Course'
      }
    },
    filters: {
      type: Object,
      default: function () {
        return {
          start_date: null,
          end_date: null
        }
      }
    }
  },
  data: function () {
    return {
      isCollapsed: false,
      regions: [],
      cities: [],
      letters: []
    }
  },
  computed: {
    isLoading () {
      return this.$isLoading()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.createLetters()
      this.getRegions()
      this.getCities()
    },
    createLetters () {
      for (var i = 10; i < 10 + 26; i++) {
        this.letters.push(i.toString(36).toUpperCase())
      }
    },
    getRegions () {
      this.$updateloading(1)
      InfoService.getRegions().then((resp) => {
        this.$updateloading(-1)
        this.regions = resp.data.regions
      }).catch(errors => {
        this.$updateloading(-1)
      })
    },
    getCities () {
      this.$updateloading(1)
      CourseService.getCourseCities().then((resp) => {
        this.$updateloading(-1)
        this.cities = resp.data.cities
      }).catch(errors => {
        this.$updateloading(-1)
      })
    },
    onClear () {
      this.filters.first_letter = null
      this.filters.region_id = null
      this.filters.city = null
      this.filters.contains = null
      this.filters.page = '0'
      this.onClickApply()
    },
    onClickApply () {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
      let copyFilterObj = JSON.parse(JSON.stringify(this.filters))
      let filterWithoutNullValue = this.getObjWithoutNullValueMixin(copyFilterObj)
      // hide the phone keyboard
      setTimeout(() => {
        // document.activeElement.blur()
      }, 1000)
      this.$emit('updateFilter', filterWithoutNullValue)
    },
    onGetCollapse: function (collapse) {
      this.isCollapsed = collapse
    },
    submit: function () {
    },
    toAddCourse: function () {
      // :to="`addCourse`"
      this.$router.push({ name: 'Add Course', params: { prevRoute: this.$route.name } })
    }
  },
  watch: {
    'filters.contains': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if ((this.filters.contains && this.filters.contains.length > 2) || this.filters.contains === '') {
          this.filters.page = '0'
          this.onClickApply()
        }
      }
    },
    'filters.city': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filters.page = '0'
        this.onClickApply()
      }
    },
    'filters.region_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filters.page = '0'
        this.onClickApply()
      }
    }
  }
}
</script>
