<style scoped>
</style>

<template >
  <div class="row">
    <div class="col-12 mx-auto align-middle">
      <!-- title -->
      <div class="card mb-1 table-title">
        <div class="card-header text-center">
          <i
            class="fa fa-arrow-circle-left back-icon"
            @click="$router.go(-1)"
          ></i>
          <b>Step 4 : Set Golfers Score</b>
        </div>
      </div>
    </div>
    <!-- for mobile -->
    <form class="col-12 p-3">
      <div
        class="card mb-1"
        v-for="(user, index) in selectedUsers"
        :key="index"
      >
        <div class="card-header">
          {{user.firstname +' '+ user.lastname}}
        </div>
        <div class="card-body">
          <div class="form-group row">
            <label
              for="score"
              class="col-5 col-md-3 col-form-label"
            >Adj Score</label>
            <div class="col-7 col-md-9">
              <input
                type="user"
                v-model="user.score"
                class="form-control"
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="teebox"
              class="col-5 col-md-3 col-form-label"
            >Teebox</label>
            <div class="col-7 col-md-9 row">
              <button
                type="button"
                v-for="teebox in teeboxes"
                :key="teebox.id"
                class="btn ml-3 mb-2"
                :class="activeButton(teebox.id, user) ? 'btn-primary' : 'btn-dark'"
                @click="selectTee(teebox.id, user)"
              > {{ teebox.description }} {{ teebox.rating }}/{{ teebox.slope }}</button>
            </div>
          </div>

          <div class="form-group row">
            <label
              for="parorbetter"
              class="col-5 col-md-3 col-form-label"
            > Holes Par
              or Better</label>
            <div class="col-7 col-md-9">
              <input
                type="user"
                v-model="user.parorbetter"
                class="form-control"
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="alcohol"
              class="col-5 col-md-3 col-form-label"
            >Alcohol Consumption</label>
            <div class="col-7 col-md-9">
              <select
                class="form-control"
                id="alcohol"
                v-model="user.alcohol"
              >
                <option value="0">None</option>
                <option value="1">Moderate</option>
                <option value="2">Excessive</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <Error
        v-if="errors.length"
        :errors="errors"
      ></Error>

      <div class="form-group row">
        <div class="col-6">
          <button
            type="button"
            style="width:100%"
            class="btn btn-primary"
            @click="onSubmitForm()"
          >Save Scores</button>
        </div>
        <div class="col-6">
          <button
            type="button"
            style="width:100%"
            class="btn btn-info"
            @click="$router.go(-1)"
          >Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import AimCommonMixin from '@/mixins/aim.common.mixin'
import AimActionMixin from '@/mixins/aim.action.mixin'
import Error from '@/components/Error'

export default {
  name: 'ViewCoures',
  mixins: [AimCommonMixin, AimActionMixin],
  components: { Error },
  data () {
    return {
      errors: []
    }
  },
  computed: {
    // use getter to get => user module's => coursesInfo getter
    ...mapGetters({
      selectedUsers: 'userInfo/selectedUsers',
      teeboxes: 'teebox/teeboxes'
    })
  },

  methods: {
    ...mapMutations([
      'round/updateGolfersTournament'
    ]),
    onSubmitForm: function () {
      this.errors = []
      this.selectedUsers.forEach(user => {
        if (!user.score) {
          this.errors.push('User ' + user.firstname + ' Score is Required!')
        }
        if (!user.parorbetter) {
          this.errors.push('User ' + user.firstname + ' Par or better is Required!')
        }
      })
      if (this.errors.length === 0) {
        this['round/updateGolfersTournament']()
        this.$router.push({ path: '/postRound/reviews' })
      }
    },
    activeButton: function (key, ref) {
      if (ref.teebox_id === key) {
        return true
      } else {
        return false
      }
    },
    selectTee: function (key, ref) {
      ref.teebox_id = key
      this.$forceUpdate()
    }
  }
}
</script>
