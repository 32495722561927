<style scoped>
.nav-btn {
  width: 100%;
}
</style>
<template >
  <div class="container mt-4 mb-4">

    <div class="row justify-content-md-center">
      <div class="col-12">
        <br>
        <p>We are now under 2020 World Handicap System, Equitable Score Control (ECS) has been replaced with a "net double-bogey"
          rule. Click <a href='https://www.usga.org/content/usga/home-page/handicapping/world-handicap-system/World-Handicap-System-5-Things-You-Need-to-Know.html'>
            here</a> to understand how this change affects you.</p>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <router-link
          class="btn btn-primary nav-btn"
          :to="`/postRound`"
        >Post Round</router-link>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <router-link
          class="btn btn-primary nav-btn"
          :to="`/recentPostings`"
        >Recent Postings</router-link>
      </div>

      <div
        class="col-12 col-md-3 mb-2"
        v-if="isAdmin"
      >
        <router-link
          :to="`/addTeetime`"
          class="btn btn-primary nav-btn"
        >Add Tee Time</router-link>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <router-link
          :to="`/tournament`"
          class="btn btn-primary nav-btn"
        >Score Round</router-link>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <router-link
          class="btn btn-primary nav-btn"
          :to="`/sendFeeler`"
        >Send Feeler</router-link>
      </div>

    </div>

    <hr v-if="feelers && feelers.length">
    <FeelerCard :feelers="feelers"></FeelerCard>

    <hr v-if="teetime && teetime.length">
    <TeetimeCard
      :teetime="teetime"
      :user='user'
    ></TeetimeCard>

    <hr v-if="hasOutings">
    <h4 class="text-center mt-3 mb-4" v-if="hasOutings">Current Outings</h4>
    <b-row v-if="myoutings && myoutings.length">
      <b-col cols="12" md="6" lg="4" class="mt-4" v-for="o in myoutings" :key="o.id">
        <OutingCard :outing="o" @outingCancelled="handleOutingCancelled"></OutingCard>
      </b-col>
    </b-row>

    <b-row v-if="otheroutings && otheroutings.length">
      <b-col cols="12" md="6" lg="4" class="mt-4" v-for="o in otheroutings" :key="o.id">
        <OutingCard :outing="o" :flagUser="user_id"></OutingCard>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import TeetimeCard from '@/views/recentPostings/components/teetimeCard/TeetimeCard'
import { mapState } from 'vuex'
import InfoService from '@/services/InfoService'
import TeetimeService from '@/services/TeetimeService'
import UserService from '@/services/UserService'
import FeelerCard from '@/views/recentPostings/components/feelerCard/FeelerCard'
import OutingCard from '@/views/leaderboard/OutingCard'

export default {
  name: 'Home',
  components: {
    TeetimeCard,
    FeelerCard,
    OutingCard
  },
  data () {
    return {
      teetime: [],
      feelers: null,
      user: null,
      myoutings: [],
      otheroutings: [],
      user_id: null
    }
  },
  computed: {
    ...mapState('info',
      {
        members: state => state.members
      }
    ),
    isAdmin: function () {
      return UserService.isAdmin()
    },
    hasOutings: function () {
      return (this.myoutings && this.myoutings.length > 0) || (this.otheroutings && this.otheroutings.length > 0)
    }
  },
  methods: {
    getFeelers: function () {
      this.$updateloading(1)
      InfoService.getFeelers()
        .then((response) => {
          this.feelers = response.data.feelers
          this.$updateloading(-1)
        }).catch(err => {
          console.log(err)
          this.$updateloading(-1)
        })
    },
    getCurrentOutings: function () {
      this.$updateloading(1)
      TeetimeService.getOutings()
        .then((response) => {
          this.myoutings = response.data.myoutings
          this.otheroutings = response.data.otheroutings
          this.$updateloading(-1)
        }).catch(err => {
          console.log(err)
          this.$updateloading(-1)
        })
    },
    handleOutingCancelled (teetimeId) {
      this.myoutings = this.myoutings.filter(outing => outing.id !== teetimeId)
    }
  },
  mounted () {
    this.getFeelers()
    this.getCurrentOutings()
    this.$updateloading(1)
    const user = UserService.getUserObject()
    if (user && user.token) {
      this.user_id = user.id
    }
    TeetimeService.getTeetimes().then(data => {
      this.teetime = data.data.teetime
      this.user = data.data.user
      this.$updateloading(-1)
    })
      .catch(errors => {
        this.$updateloading(-1)
      })
  }
}
</script>
