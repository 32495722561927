import Vue from 'vue'

Vue.directive('aimSort', {
  bind (el, binding, vnode) {
    // console.log(binding.value);
    // if(!binding.targetArray || binding.)
    el.addEventListener('click', function (event) {
    })
  }
})
