<template src='./TeetimeCard.html'>

</template>

<style scoped src='./TeetimeCard.css'>
</style>

<script>

export default {
  props: {
    'teetime':
    {
      type: Array,
      default () {
        return []
      }
    },
    'user': {

    }
  },
  methods: {
  }
}
</script>
