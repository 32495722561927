<style scoped>
</style>

<template >
  <div class="row">
    <div class="col-12 mx-auto align-middle">
      <GolferList
        :filters="filters"
        :usersInfo="usersInfo"
        :title="'Step 3 : Select Golfer Then Set Score'"
        @updateFilters="onGetFilters($event)"
        exclude-self
      >
        <template v-slot:selectedGolfers>
          <div
            v-if="selectedUsers && selectedUsers.length"
            class="card-header"
          >
            <div class="row">
              <div class="col-12">
                <b>Selected Golfers &nbsp;</b>
                <b>({{selectedUsers.length}})</b>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                <AimBadge
                  class="mr-2 mb-1"
                  v-for='(user, index) in selectedUsers'
                  :key='index'
                  :infos='[user.firstname, user.lastname]'
                  @updateDelete="onGetDelete($event, user)"
                >
                </AimBadge>

                <button
                  v-if="selectedUsers && selectedUsers[0]"
                  class="btn btn-info float-right"
                  @click="goToNextStep()"
                >Set Golfers' Scores</button>
              </div>
            </div>
          </div>
        </template>
        <!-- below is the slot in GolferList-->
        <template v-slot:filter>
          <GolferFilter
            :filters="filters"
            @updateFilter="onGetFilters($event)"
          ></GolferFilter>
        </template>
        <!-- previous is the slot in GolferList-->
      </GolferList>
    </div>

  </div>
</template>

<script>
import GolferList from '@/components/golferList/GolferList'
import GolferFilter from '@/components/golferFilter/GolferFilter'
import AimBadge from '@/components/Aim/AimBadge'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AimCommonMixin from '@/mixins/aim.common.mixin'
import AimActionMixin from '@/mixins/aim.action.mixin'
import UserService from '@/services/UserService'

export default {
  name: 'SetRoundGolfers',
  mixins: [AimCommonMixin, AimActionMixin],
  components: {
    GolferList,
    GolferFilter,
    AimBadge
  },
  data: function () {
    return {
      filters: {
        sort: 'name',
        currentuser_id: 0,
        desc: false,
        size: 0,
        page: 1
      }
    }
  },
  computed: {
    // use getter to get => user module's => coursesInfo getter
    ...mapGetters({
      round: 'round/round',
      usersInfo: 'userInfo/usersInfo',
      selectedUsers: 'userInfo/selectedUsers'
    })
  },
  mounted: function () {
    let user = UserService.getUser()
    this.filters.currentuser_id = user.id
    this.filters.course_id = this.round.course.id
    this.searchUsers()
    this.scrollToTopMixin()
  },
  methods: {
    //  get actions
    ...mapActions([
      'userInfo/searchUser'
    ]),
    ...mapMutations([
      'userInfo/setUsersInfo', 'round/setSelectedUsers'
    ]),
    async searchUsers () {
      this.$updateloading(1)
      // this.SerializeFiltersMixin is from mixins
      // we can diclare common use functions all around the project
      // we can import as much more mixins as we want
      // if current component has this.SerializeFiltersMixin() declared,
      // it will use current compoent and shadow the mixin functions.
      // otherwise use the functio from the mixins
      let filterStr = this.SerializeFiltersMixin(this.filters)
      // because we use mapActions first, it get user module's => searchUser action
      // then we can use this to call
      await this['userInfo/searchUser'](filterStr)
      this.$updateloading(-1)
    },
    onGetFilters (filters) {
      this.filters = filters
      this.searchUsers()
    },
    onGetDelete: function (evt, user) {
      this.removeOneMixin(this.selectedUsers, user)
      this['round/setSelectedUsers'](this.selectedUsers)
    },
    goToNextStep () {
      if (this.selectedUsers && this.selectedUsers.length) {
        this.pushUsersToRoundModule()
      }
    },
    pushUsersToRoundModule () {
      this['round/setSelectedUsers'](this.selectedUsers)
      this.$router.push({ path: '/postRound/golfers/scores' })
    }
  }
}
</script>
