
<style lang='scss' scoped src='./GolferList.scss'>
</style>

<template src='./GolferList.html'>
</template>

<script>
import Page from '@/components/Page'
import CourseCard from '@/components/courseCard/CourseCard'
import AimCard from '@/components/Aim/AimCard'
import AimSelectedIcon from '@/components/Aim/icons/AimSelectedIcon'
import AimActionMixin from '@/mixins/aim.action.mixin'
import { mapGetters, mapMutations } from 'vuex'
import AimCenterModal from '@/components/Aim/AimCenterModal'

export default {
  components: {
    Page,
    CourseCard,
    AimCard,
    AimSelectedIcon,
    AimCenterModal

  },
  mixins: [AimActionMixin],
  props: {
    usersInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String
    },
    filters: {
      type: Object
    },
    excludeSelf: Boolean
  },
  computed: {
    ...mapGetters({ selectedUsers: 'userInfo/selectedUsers' }),
    filteredList: function () {
      var filtered = []

      this.usersInfo.results.forEach(element => {
        if (!this.objExistedInSelectedArrayMixin(this.selectedUsers, element)) {
          filtered.push(element)
        }
      })

      if (this.excludeSelf) {
        // exclude the current user from the list of users to choose from
        filtered = filtered.filter((e) => { return +e.id !== +this.filters.currentuser_id })
      }

      return filtered
    }
  },
  methods: {
    ...mapMutations([
      'userInfo/setSelectedUsers'
    ]),
    changeSortTo: function (sort) {
      if (this.filters['sort'] === sort) {
        this.filters['desc'] = !this.filters['desc']
      } else {
        this.filters['sort'] = sort
        this.filters['desc'] = true
      }
      // reset page to 1 when sorting
      this.filters['page'] = 1
      this.sentFilters()
    },
    sentFilters: function () {
      this.$emit('updateFilters', this.filters)
    },
    onChangePageTo: function (page) {
      this.filters['page'] = page
      this.sentFilters()
    },
    onGetSortFromCard (sort) {
      this.changeSortTo(sort)
    },
    selectUser (course) {
      this.pushOneMixin(this.selectedUsers, course)
      this['userInfo/setSelectedUsers'](this.selectedUsers)
    }
  }
}
</script>
