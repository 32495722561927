<template>
  <b-container>
    <b-row>
      <b-col cols="12" lg="6">
        <b-form-group label="First Name">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.firstname"
              :state="!$v.form.firstname.$error ? null : false"
              @blur="$v.form.firstname.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('firstname', ['required'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="Last Name">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.lastname"
              :state="!$v.form.lastname.$error ? null : false"
              @blur="$v.form.lastname.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('lastname', ['required'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="Username">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.username"
              :state="!$v.form.username.$error && !usernameTaken ? null : false"
              @blur="$v.form.username.$touch()"
              @input="checkUsername()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('username', ['required'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="usernameTaken"
            >
              This username is already taken
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="Date of Birth">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.dob"
              :state="!$v.form.dob.$error ? null : false"
              @blur="$v.form.dob.$touch()"
              placeholder="MM-DD-YYYY"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('dob', ['required', 'dobFormat'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6"></b-col>
      <b-col cols="12"><hr></b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="Mobile Phone">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.phone"
              :state="!$v.form.phone.$error ? null : false"
              @blur="$v.form.phone.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('phone', ['required', 'cellPhone'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="Email">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.email"
              :state="!$v.form.email.$error ? null : false"
              @blur="$v.form.email.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('email', ['required', 'email'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="Password">
          <b-input-group>
            <b-form-input
              type="password"
              v-model="form.password"
              :state="!$v.form.password.$error ? null : false"
              @blur="$v.form.password.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('password', ['required'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="Confirm Password">
          <b-input-group>
            <b-form-input
              type="password"
              v-model="form.passwordRepeat"
              :state="!$v.form.passwordRepeat.$error ? null : false"
              @blur="$v.form.passwordRepeat.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('passwordRepeat', ['required', 'sameAsPassword'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12"><hr></b-col>
      <b-col cols="12">
        <b-form-group label="Address">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.address"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="City">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.city"
              :state="!$v.form.city.$error ? null : false"
              @blur="$v.form.city.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('city', ['required'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="3">
        <b-form-group label="State">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.state"
              :state="!$v.form.state.$error ? null : false"
              @blur="$v.form.state.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('state', ['required', 'maxLength'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="3">
        <b-form-group label="Zip">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.zip"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12"><hr></b-col>
      <b-col cols="12" lg="6">
        <b-form-group label="AGA (if you have one)">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.aga"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="How did you hear about the Nomads?">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="form.referral"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Do You want to be notified of upcoming outings and tournaments?">
          <b-form-radio-group
            v-model="form.notify"
            :options="notificationOptions"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <vue-recaptcha
          sitekey="6LdLahwUAAAAAAn5KfN0KKVBWSv2VwA_voBGLfh9"
          @verify="getVerify($event)"
        ></vue-recaptcha>
      </b-col>
      <b-col cols="12" class="text-center pt-3">
        <b-button
          variant="primary"
          :disabled="usernameTaken || isRobot"
          @click="submit()"
        >Register</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
import UserService from '@/services/UserService'
import { userEventBus } from '@/main'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  sameAs,
  maxLength
} from 'vuelidate/lib/validators'

const cellPhone = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
  }
}

const dobFormat = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return moment(value, 'MM-DD-YYYY', true).isValid()
  }
}

export default {
  data: () => {
    return {
      form: {
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        passwordRepeat: '',
        phone: '',
        address: '',
        city: '',
        state: 'AZ',
        zip: '',
        aga: '',
        referral: '',
        notify: 'Both',
        dob: ''
      },
      usernameTaken: false,
      isRobot: true,
      notificationOptions: [
        { text: 'No', value: 'None' },
        { text: 'Text', value: 'Text' },
        { text: 'Email', value: 'Email' },
        { text: 'Both', value: 'Both' }
      ]
    }
  },
  components: {
    VueRecaptcha
  },
  mixins: [ validationMixin ],
  validations: {
    form: {
      firstname: {
        required
      },
      lastname: {
        required
      },
      username: {
        required
      },
      password: {
        required
      },
      passwordRepeat: {
        required,
        sameAsPassword: sameAs('passwordRepeat')
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        cellPhone
      },
      city: {
        required
      },
      state: {
        required,
        maxLength: maxLength(2)
      },
      dob: {
        required,
        dobFormat
      }
    }
  },
  methods: {
    submit: function () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError || this.isRobot) {
        this.$notify({
          group: 'notices',
          title: 'Warning',
          text: 'Required information has not been provided',
          type: 'error',
          timeout: 3000
        })
      } else {
        if (this.form.dob) {
          this.form.dob = moment(this.form.dob, 'MM-DD-YYYY').format('YYYY-MM-DD')
        }
        UserService.register(this.form).then(
          (response) => {
            this.$updateloading(-1)
            let user = UserService.getUser()
            userEventBus.$emit('userChanged', user)
            this.$router.push('/home', function () {
              this.$notify({
                group: 'notices',
                title: 'Register Successful',
                text: 'You are now logged in.',
                type: 'info'
              })
            })
          },
          (err) => {
            console.log(err)
            this.$notify({
              group: 'errors',
              title: 'Something went wrong',
              text: 'Please try again soon. If the problem persists, contact us',
              type: 'alert-danger'
            })
          }
        )
      }
    },
    getVerify: function (res) {
      if (res) {
        this.isRobot = false
      }
    },
    checkUsername: function () {
      if (this.form.username.length > 2) {
        UserService.usernameUnique(this.form.username).then(
          (response) => {
            this.usernameTaken = !response.data.unique
          }
        )
      } else {
        this.usernameTaken = false
      }
    },
    getErrors: function (field, validators) {
      const err = []
      if (!this.$v.form[field].$dirty) return err

      validators.forEach(e => {
        if (!this.$v.form[field][e]) {
          e === 'required' && err.push('This field is required')
          e === 'maxLength' && err.push(this.capitalize(field) + ' can only be ' + this.$v.form[field].$params.maxLength.max + ' characters')
          e === 'email' && err.push('Please enter a valid email')
          e === 'cellPhone' && err.push('Please enter a valid phone number')
          e === 'dobFormat' && err.push('Please enter a valid date in MM-DD-YYYY format')
          e === 'sameAsPassword' && err.push('Passwords do not match')
        }
      })

      return err
    },
    reset: function () {
      this.$v.form.$reset()
    },
    capitalize: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>
