<style scoped>
  .score {
    background-color: #fffc6a;
  }
  .par {
    background-color: #d3d3d3;
  }
  .hole {
    background-color: #deb887;
  }
  .head th {
    background-color: #DCD3A0;
  }
  .table-lines,.table-lines td,.table-lines th {
    border-collapse: collapse;
    border: 1px solid black;
  }
</style>
<template>
  <div>
    <table class="w-100 text-center table-lines">
      <tr class="head">
        <th>Hole</th>
        <th>Par</th>
        <th>Gross</th>
        <th>To Par</th>
        <th>Net To Par</th>
      </tr>
      <tr v-for="s in scores" :key="s.id">
        <td :colspan="isNaN(s.hole) ? 2 : 1" :class="isNaN(s.hole) ? '' : 'hole'">
          {{ s.hole }}
        </td>
        <td class="par" v-show="!isNaN(s.hole)">
          {{ s.par }}
        </td>
        <td>
          {{ s.grossscore }}
        </td>
        <td class="score">
          {{ toPar(s.grossscore, s.par) }}
        </td>
        <td class="score">
          {{ toPar(s.netscore, s.par) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import TeetimeService from '@/services/TeetimeService'
export default {
  name: 'Leaderboard',
  data () {
    return {
      scores: [],
      loadingCount: 0
    }
  },

  props: {
    g2tId: {
      type: [String, Number],
      default: null
    }
  },

  mounted () {
    this.getScores()
  },

  methods: {
    getScores () {
      this.scores = []
      TeetimeService.getScoreCard(this.g2tId).then(
        (response) => {
          this.scores = response.data.scores
        }
      ).catch(err => {
        this.$notify({
          group: 'errors',
          title: err.response.data.error,
          type: 'error'
        })
        this.$emit('close')
      }).finally(() => { this.updateLoading(-1) })
    },

    updateLoading (val) {
      this.loadingCount += val
      if (this.loadingCount < 0) {
        this.loadingCount = 0
      }
    },

    toPar (score, par) {
      if (score == null) {
        return score
      }

      const val = score - par

      if (val === 0) {
        return 'E'
      } else {
        return val
      }
    }
  },

  computed: {
    loading () {
      return this.loadingCount > 0
    }
  }
}
</script>
