<template>
  <div class="card col-12 col-md-5 mx-auto text-center">
    <h4>Redirecting</h4>
  </div>
</template>

<script>
import UserInfoService from '../services/UserInfoService'
import UserService from '../services/UserService'

export default {
  data () {
    return {
      errors: [],
      user: null
    }
  },
  methods: {
    resetTempPwd: function (data) {
      this.$updateloading(1)
      UserInfoService.resetTempPwd(data)
        .then(res => {
          this.$updateloading(-1)
          localStorage.setItem('desertnomadsuser', JSON.stringify(res.data))
          this.$router.push({ path: '/home' })
        })
        .catch(errors => {
          this.$updateloading(-1)
          this.errors = []
          this.errors.push(errors)
        })
    },
    getUsernameAndHash () {
      let queries = this.$route.query
      return {
        username: queries['username'],
        hash: queries['hash']
      }
    },
    loginByHash () {
      let data = this.getUsernameAndHash()
      this.$updateloading(1)
      UserInfoService.loginByHash(data)
        .then(res => {
          this.$updateloading(-1)
          localStorage.setItem('desertnomadsuser', JSON.stringify(res.data))
          this.$router.push({ path: this.$route.query.page })
        })
        .catch(errors => {
          this.$updateloading(-1)
          UserService.logout()
          this.$router.push({ path: '/home' })
        })
    }
  },
  mounted: function () {
    this.loginByHash()
  }
}
</script>
