<template>
  <b-container>
    <div style="width: 100%; padding-top: 20vh; text-align: center;" v-if="loading">
      <b-spinner variant="success" style="width: 3rem; height: 3rem;"></b-spinner>
    </div>
    <div v-if="errors">
      There was a problem while trying to confirm your email address. Please check to make sure you have the correct link inputted.<br>
      If the problem persists <a href="/contactUs">Contact Us</a>
    </div>
  </b-container>
</template>
<script>
import UserService from '@/services/UserService'
export default {
  data () {
    return {
      loading: true,
      errors: false
    }
  },
  props: {
    hash: {
      type: String,
      default: ''
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init: function () {
      if (this.hash === '') {
        this.errors = true
        this.loading = false
        return
      }

      UserService.confirmEmail(this.hash).then(
        (response) => {
          this.loading = false
          this.$router.push('/login', () => {
            this.$notify({
              group: 'notices',
              title: 'Confirmation Complete',
              text: 'You are now able to login and use the application',
              type: 'alert-success'
            })
          })
        },
        (err) => {
          this.errors = true
          this.loading = false
          console.log(err)
        }
      )
    }
  }
}
</script>
