<template>
  <div id="app">
    <notifications group="errors"></notifications>
    <notifications group="notices"></notifications>
    <loader-overlay></loader-overlay>
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      name: 'hellp',
      loading: 0
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },
  created () {
    // nothing defined here (when this.$route.path is other than "/")
  },
  updated () {
    // something defined here whatever the this.$route.path
  }
}
</script>

<style lang="sass">

@import "./app.scss"
</style>
