<template src='./RecentPostings.html'>

</template>

<style scoped src='./RecentPostings.css'>
</style>

<script>

// import { mapState } from 'vuex'
import Members from '@/views/recentPostings/components/members/Members'
import InfoService from '@/services/InfoService'
import TeetimeService from '@/services/TeetimeService'

export default {
  components: {
    Members
  },
  data: function () {
    return {
      teetime: null,
      members: null
    }
  },
  methods: {
    getTeetimes: function () {
      this.$updateloading(1)
      TeetimeService.getTeetimes()
        .then((response) => {
          this.teetime = response.data.teetime
          this.$updateloading(-1)
        })
        .catch(err => {
          console.log(err)
          this.$updateloading(-1)
        })
    },
    getMembers: function () {
      this.$updateloading(1)

      InfoService.getMembers()
        .then((response) => {
          this.members = response.data.members
          this.$updateloading(-1)
        })
        .catch(err => {
          console.log(err)
          this.$updateloading(-1)
        })
    }
  },
  mounted () {
    this.getTeetimes()
    this.getMembers()
  },
  computed: {
  }
}
</script>
