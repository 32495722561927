import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const UserService = {

  authHeader () {
    // return authorization header with jwt token
    let user = this.getToken()

    if (user) {
      return {
        'Authorization': 'Bearer ' + user
      }
    } else {
      return {}
    }
  },

  getToken () {
    let user = this.getUserObject()
    if (user === null) {
      this.logout()
      return null
    } else {
      return user.token
    }
  },

  baseUrl () {
    // return authorization header with jwt token
    // let user = this.getBaseCompany()
    // if (user && user.url) {
    if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') {
      return 'https://desertnomads-api.dev.aimitservices.com/'
    } else if (typeof process !== 'undefined' && process.env.NODE_ENV === 'staging') {
      return process.env.VUE_APP_STAGING_API_URL
    } else {
      // ensure there's a trailing slash...
      return 'https://api.desertnomads.org/'
    }
  },

  login (username, password) {
    let self = this
    return fetch(this.baseUrl() + 'login', {
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return self.handleResponse(response)
    })
      .then(user => {
        // login successful if there's a jwt token in the response
        if (user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('desertnomadsuser', JSON.stringify(user))
        }
        return user
      })
  },

  updateDOB (data) {
    return fetch(this.baseUrl() + 'user/dob', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          // Update local storage or perform additional actions
          console.log('DOB updated successfully')
        }
      })
      .catch(error => {
        console.error('Error updating DOB:', error)
      })
  },

  logout () {
    // remove user from local storage to log user out
    localStorage.clear()
    // localStorage.removeItem('user');
  },

  loggedIn () {
    let user = JSON.parse(localStorage.getItem('desertnomadsuser'))
    if (user == null) {
      return false
    } else {
      return true // todo verify token and expiration stuff
    }
  },
  isConfirmed () {
    let user = this.getUser()
    if (user == null) {
      this.logout()
      return
    }
    if (+user.isconfirmed === 1) {
      return true
    }
    return false
  },
  isAdmin () {
    let user = this.getUser()
    if (user == null) {
      return false
    }
    if (+user.isadmin) {
      return true
    }
    return false
  },
  getUserObject () {
    let temp = localStorage.getItem('desertnomadsuser')
    if (temp !== null) {
      return JSON.parse(localStorage.getItem('desertnomadsuser'))
    } else {
      return null
    }
  },

  getUser () {
    let temp = this.getUserObject()
    if (temp !== null) {
      return temp.user
    } else {
      return null
    }
  },

  handleResponse (response) {
    let self = this
    return response.text().then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          self.logout()
          // location.reload(true);
        }

        const error = (data && data.error) || response.statusText
        return Promise.reject(error)
      }

      return Promise.resolve(data)
    })
  },

  usernameUnique (username) {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = this.baseUrl()
    return Vue.axios.post(`user/usernameUnique`, { username: username })
  },

  getCard (userid) {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = this.baseUrl()
    return Vue.axios.post(`user/getCard`, { id: userid })
  },

  register (data) {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = this.baseUrl()
    return fetch(this.baseUrl() + 'user/register', {
      method: 'POST',
      body: JSON.stringify({
        data: data
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return this.handleResponse(response)
      })
      .then(user => {
        // login successful if there's a jwt token in the response
        if (user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('desertnomadsuser', JSON.stringify(user))
        }
        return user
      })
  },

  confirmEmail (hash) {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = this.baseUrl()
    return Vue.axios.post(`user/confirmEmail`, { hash: hash })
  }
}

export default UserService
