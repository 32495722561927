<style lang="scss" scoped>
</style>

<template >
  <div class="mt-4 mb-4">
    <FeelerForm
      @sendForm="onGetForm($event)"
      :user="user"
      :regions="regions"
      :feeler="feeler"
      :isEdit="true"
    >
    </FeelerForm>
  </div>
</template>

<script>
import FeelerForm from '@/components/feelerForm/FeelerForm'
import InfoService from '@/services/InfoService'
import UserService from '@/services/UserService'

export default {
  components: {
    FeelerForm
  },
  data () {
    return {
      feeler: {
      },
      regions: [],
      user: null
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    async init () {
      await this.getRegions()
      this.user = UserService.getUser()
      await this.getFeeler()
    },
    async getFeeler () {
      this.$updateloading(1)
      let res = await InfoService.getFeeler(this.$route.params['fId'])
      this.$updateloading(-1)
      this.feeler = res.data.feeler
    },
    async getRegions () {
      this.$updateloading(1)
      let res = await InfoService.getRegions()
      this.$updateloading(-1)
      this.regions = res.data.regions
    },
    onGetForm (data) {
      this.$updateloading(1)
      InfoService.editFeeler(+this.$route.params['fId'], data)
        .then(res => {
          this.$updateloading(-1)
          this.$router.push({ path: '/home' })
        })
        .catch(err => {
          this.$updateloading(-1)
          this.$route.push({ path: '/home' })
          console.log(err)
        })
    }
  }
}
</script>
