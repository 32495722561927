<style lang="scss" scoped>
$distance: 45px;

.page-number {
  z-index: 10;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .page-container {
    bottom: -6px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    width: 92vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
  }
}
</style>

<template>
  <div>
    <!-- For Phone version-->
    <div class="d-md-none page-container card">
      <button
        @click="changePageTo(page - 1)"
        class="btn btn-primary"
        :disabled="page == 1 || isLoading"
      >
        <span>
          PREV
        </span>
      </button>

      <button
        v-if="page"
        class="btn btn-outline-primary"
      >Page : {{page}} / {{totalPages}}
      </button>

      <button
        @click="changePageTo(nextPage)"
        class="btn btn-primary"
        :disabled="!nextPage || isLoading"
      >
        <span>
          NEXT
        </span>
      </button>
    </div>

    <!-- for Desktop version -->
    <div
      class="row p-2 d-none d-md-flex"
      style="position:relative;"
    >
      <div class="col-4 text-left">
        <button
          @click="changePageTo(page - 1)"
          class="btn btn-primary"
          :disabled="page == 1 || isLoading"
        >
          <span>
            PREV
          </span>
        </button>
      </div>

      <div class="col-4 text-center">
        <button
          v-if="page"
          class="btn btn-outline-primary page-number"
          disabled
        >Page : {{page}} / {{totalPages}}
        </button>
      </div>

      <div class="text-right col-4">
        <button
          @click="changePageTo(nextPage)"
          class="btn btn-primary"
          :disabled="!nextPage || isLoading"
        >
          <span>
            NEXT
          </span>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    page: null,
    nextPage: null,
    totalPages: null
  },
  computed: {
    isLoading () {
      return this.$isLoading()
    }
  },
  methods: {
    changePageTo (page) {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
      this.$emit('updatePage', page)
    }
  }
}
</script>
