import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UserService from '@/services/UserService'

const RoundService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = UserService.baseUrl()
  },

  setHeader () {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${UserService.getToken()}`
  },

  getUserRoundsReviews (userId, query) {
    this.setHeader()
    return Vue.axios.get(`round/user/${userId}/reviews?${query}`).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },

  getUserHandicaps (userId) {
    this.setHeader()
    return Vue.axios.get(`round/user/${userId}/handicaps`).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },

  searchRounds (query) {
    this.setHeader()
    return Vue.axios.get(`round/search?${query}`).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },

  addRound (round) {
    this.setHeader()
    return Vue.axios.post(`round/add`, round).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },

  getCourseRecords (courseId) {
    this.setHeader()
    return Vue.axios.post(`round/courseRecords`, courseId).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  }
}

export default RoundService
