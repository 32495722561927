<style lang="scss" scoped>
  .table-lines,.table-lines td,.table-lines th {
    border-collapse: collapse;
    border: 1px solid black;
    padding: 0.5rem 0;
  }

  table {
    width: 100%;
  }
</style>
<template>
  <div style="overflow: auto;">
    <table class="text-center table-lines">
      <tr>
        <th>Player</th>
        <!-- <th>Gross<br>F/B/T</th> -->
        <!-- <th>Net<br>F/B/T</th> -->
        <th>Thru</th>
        <th>Gross</th>
        <th>Net</th>
        <th v-if="+contest_id == 17 || +contest_id == 18">
          <img src="/img/rabbit_free.PNG" v-if="gameRabbit" style="height: 2em; width: auto; object-fit: contain;">
          <span v-else>Rabbit</span>
        </th>
        <th v-if="+contest_id == 14">Sixes</th>
        <th v-if="+contest_id == 16">Stableford</th>
        <th v-if="+contest_id == 16">Pace</th>
      </tr>
      <tr v-for="s in orderedScores" :key="s.key">
        <td>
          {{ s.last }}{{ s.first | initial}}
          <img src="/img/rabbit_captured.PNG" v-if="+contest_id == 17 && s.rabbit && s.rabbit.nine && s.rabbit.nine > 0" style="height: 0.8em; width: auto; object-fit: contain;">
        </td>
        <!-- <td>{{ s.frontgross | withE }}/{{ s.backgross | withE }}/{{ s.totalgross | withE }}</td> -->
        <!-- <td>{{ s.frontnet | withE }}/{{ s.backnet | withE }}/{{ s.totalnet | withE }}</td> -->
        <td>{{ s.thru }}</td>
        <td>{{ s.gross | withE }}</td>
        <td>{{ s.net | withE }}</td>
        <td v-if="+contest_id == 17 || +contest_id == 18">
          <img src="/img/rabbit_captured.PNG" v-if="s.rabbit && s.rabbit.rabbit && s.rabbit.rabbit > 0" style="height: 2em; width: auto; object-fit: contain;">
        </td>
        <td v-if="+contest_id == 14">{{ s.sixes }}</td>
        <td v-if="+contest_id == 16">{{ s.stableford ? s.stableford.points : 'n/a' }}</td>
        <td v-if="+contest_id == 16">{{ s.stableford ? s.stableford.pace : 'n/a' }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    scores: {
      type: Array,
      default: () => { return [] }
    },
    contest_id: {
      type: [String, Number],
      default: 0
    }
  },
  filters: {
    initial (val) {
      return val.substring(0, 1)
    },
    withE (val) {
      if (val == null) {
        return 0
      }
      return val === 0 || val === '0' ? 'E' : val
    }
  },

  computed: {
    orderedScores () {
      const tmp = [...this.scores]
      if (this.contest_id !== 21) {
        tmp.sort((a, b) => {
          if (+this.contest_id === 16) {
            const p1 = a.stableford ? +a.stableford.points : 0
            const p2 = b.stableford ? +b.stableford.points : 0
            return (p1 < p2) ? 1 : (p1 === p2 ? 0 : -1)
          }
          return (+a.net > +b.net) ? 1 : (+a.net === +b.net ? 0 : -1)
        })
      }
      return tmp
    },

    gameRabbit () {
      return !this.scores.some(e => (e.rabbit && e.rabbit.rabbit && e.rabbit.rabbit > 0))
    }
  }
}
</script>
