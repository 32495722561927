<style scoped>
</style>

<template >
  <div class="card">
    <div class="card-header text-center">
      {{title}}
    </div>
    <div class="card-body">
      Players List - {{golferNum}} signed up - {{ Math.abs(availableSpots) + ' ' + (availableSpots >= 0 ? 'spots open' : 'on waitlist') }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PlayerGeneralInfoCard',
  components: {
  },
  props: {
    players: {
      type: Array,
      default: function () {
        return []
      }
    },
    totalSpots: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  computed: {
    title: function () {
      if (this.players.length > 0) {
        const date = moment(this.players[0].date).format('MM/DD dddd')
        const time = this.players[0].time.padEnd(8, '0').substring(0, 5)
        return `${this.players[0].cname} - ${date}  ${time}`
      }
      return 'No Golfers'
    },
    golferNum: function () {
      return this.players.length
    },
    availableSpots: function () {
      if (this.players.length < 1) {
        return +this.totalSpots
      }
      return +this.totalSpots - +this.golferNum
    }
  },
  methods: {
  }
}
</script>
