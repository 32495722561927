<style scoped>
</style>

<template >
  <button
    type="button"
    class="btn btn-primary"
    @click.prevent="onDelete()"
  >
    <span>
      {{finalInfoString}}
    </span>
    <i v-if="!noDelete"
      class="fas fa-times-circle"
    ></i>
  </button>
</template>

<script>

export default {
  name: 'AimBadge',
  props: {
    infos: Array,
    index: Number,
    noDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    finalInfoString: function () {
      if (!this.infos) {
        return ''
      }
      return this.infos.join(' ')
    }
  },
  data: function () {
    return {
      sel: null
    }
  },
  watch: {
    selectedValue: function (val, oldVal) {
      if (!this.sel && val && (val !== oldVal)) {
        this.sel = this.selectedValue
      }
    }
  },
  methods: {
    changeSelect: function (event) {
      this.$emit('updateSelect', event.target.value)
    },
    onDelete: function () {
      if (!this.noDelete) {
        this.$emit('updateDelete', this.index)
      }
    }
  }
}
</script>
