<style scoped src="./Login.css">
</style>
<template src="./Login.html">
</template>
<script>
import _ from 'lodash'
import UserService from '@/services/UserService'
import { userEventBus } from '@/main'
import TeetimeCard from '@/views/recentPostings/components/teetimeCard/TeetimeCard'
import TeetimeService from '@/services/TeetimeService'

export default {
  name: 'LoginForm',
  data: function () {
    return {
      username: '',
      password: '',
      teetime: [],
      user: {
        id: 0,
        isAdmin: 0
      }
    }
  },
  components: {
    TeetimeCard
  },
  watch: {
    username: function (n, o) {
      // console.log(n);
    }
  },
  computed: {

  },
  methods: {
    emitUserEventbus () {
      let user = UserService.getUser()
      userEventBus.$emit('userChanged', user)
    },
    login: async function () {
      let self = this
      // vue included component in main.js
      this.$updateloading(1)
      UserService.login(this.username, this.password).then(
        function (user) {
          self.$updateloading(-1)
          self.emitUserEventbus()
          self.$router.push('/home', function () {
            self.$notify({
              group: 'notices',
              title: 'Login Successful',
              text: 'You are now logged in.',
              type: 'info'
            })
          })
        },
        function (failed) {
          self.$updateloading(-1)
          // https://github.com/euvl/vue-notification
          self.$notify({
            group: 'errors',
            title: 'Login Failed',
            text: failed,
            type: 'warn'
          })
        }
      )
    },
    update: _.debounce(function (e) {
      this.input = e.target.value
    }, 300)
  },
  created: function () {
    // prepopulate company id if exists from previous login
    if (UserService.loggedIn()) {
      this.$router.push({ path: '/home' })
    }
  },
  mounted: function () {
    this.$updateloading(1)
    TeetimeService.getTeetimesLoggedOut().then(data => {
      this.teetime = data.data.teetime
      this.$updateloading(-1)
    })
      .catch(errors => {
        this.$updateloading(-1)
      })
  }
}
</script>
