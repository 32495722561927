<style src="./RoundForm.css" scoped>
</style>

<template src="./RoundForm.html">

</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import Error from '@/components/Error'
import moment from 'moment'
import AimCommonMixin from '@/mixins/aim.common.mixin'
import _ from 'lodash'
import RoundService from '@/services/RoundService'
import UserService from '@/services/UserService'
import UserInfoService from '@/services/UserInfoService'

export default {
  name: 'RoundForm',
  components: {
    Error
  },
  mixins: [AimCommonMixin],
  props: {
    isEdit: {
      type: Boolean,
      default () {
        return false
      }
    },
    isModal: {
      type: Boolean,
      default () {
        return false
      }
    },
    round: {
      type: Object,
      default () {
        return {
        }
      }
    },
    regions: {
      type: Array
    },
    courses: {
      type: Array
    },
    teeboxes: {
      type: Array
    }
  },
  data () {
    return {
      errors: [],
      coursePristine: {},
      courseChanged: false,
      sortedBoxes: null,
      showDOBModal: false,
      newDOB: '',
      formattedDOB: '',
      dobError: '',
      cursorPosition: 0,
      userInfo: null
    }
  },
  created: function () {
    this.scrollToTopMixin()
    this.setDefaultTeebox()
    this.sortTeeBoxes()
    this.round.course.fairwaycondition = this.round.course.greenscondition = null
    this.round.course.greensspeed = this.round.course.value = null
  },
  computed: {
    ...mapGetters({
      options: 'course/options'
    }),
    title: function () {
      return this.isEdit ? 'Edit Round' : 'Step 2 : Set The Round Information'
    },
    tournamentSelection: function () {
      if (this.round.tournament !== null) {
        return true
      }
      return false
    },
    teeBoxSelection: function () {
      console.log(this.round.teebox_id)
      if (this.round.teebox_id !== null) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations([
      'round/setRound'
    ]),
    ...mapActions([
      'round/reset', 'userInfo/reset', 'course/reset'
    ]),
    setDefaultTeebox: function () {
      if (this.$route.params && this.$route.params.tIds && this.$route.params.tIds.length) {
        this.round.teebox_id = +this.$route.params.tIds[this.$route.params.tIds.length - 1]
      }
    },

    checkDOB () {
      let user = UserService.getUserObject()
      if (!user || !user.user.dob) {
        UserInfoService.settingsInit().then((response) => {
          // Directly use response data within the promise
          this.userInfo = response.data.user
          if (!this.userInfo || !this.userInfo.dob) {
            this.showDOBModal = true
          } else {
            const dob = new Date(this.userInfo.dob)
            const today = new Date()
            let age = today.getFullYear() - dob.getFullYear()
            const m = today.getMonth() - dob.getMonth()
            if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
              age--
            }
            // Safely update user's age if the user object exists
            if (user && user.user) {
              user.user.age = age
              user.user.dob = this.userInfo.dob
              localStorage.setItem('desertnomadsuser', JSON.stringify(user))
            }
          }
        }).catch((error) => {
          console.error('Failed to fetch user settings:', error)
          // Optionally handle the error more visibly to the user here
        })
      }
    },
    validateModalDateInput () {
      this.formattedDOB = this.maskDate(this.formattedDOB)
      // Validate the formatted date
      const validFormat = moment(this.formattedDOB, 'MM-DD-YYYY', true).isValid()
      const today = moment().startOf('day')
      const enteredDate = moment(this.formattedDOB, 'MM-DD-YYYY', true)
      if (validFormat && enteredDate.isBefore(today)) {
        this.newDOB = enteredDate.format('YYYY-MM-DD')
        this.dobError = ''
      } else {
        this.newDOB = ''
        this.dobError = 'Invalid date. The date must be less than today and in MM-DD-YYYY format.'
      }
    },
    maskDate (value) {
      value = value.replace(/\D/g, '').slice(0, 8)
      let result = ''
      if (value.length > 0) {
        result = value.slice(0, 2)
      }
      if (value.length > 2) {
        result += '-' + value.slice(2, 4)
      }
      if (value.length > 4) {
        result += '-' + value.slice(4, 8)
      }

      return result
    },
    submitDOB () {
      if (this.newDOB) {
        let user = UserService.getUserObject()
        UserService.updateDOB({ dob: this.newDOB, userId: user.user.id }).then(() => {
          this.showDOBModal = false
          user.user.dob = this.newDOB
          const dob = new Date(this.newDOB)
          const today = new Date()
          let age = today.getFullYear() - dob.getFullYear()
          const m = today.getMonth() - dob.getMonth()
          if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
            age--
          }
          user.user.age = age
          localStorage.setItem('desertnomadsuser', JSON.stringify(user))
        }).catch(error => {
          this.dobError = 'Failed to update DOB. Please try again.'
          console.error('Failed to update DOB:', error)
        })
      } else {
        this.dobError = 'Please enter a valid date in MM-DD-YYYY format.'
      }
    },

    handleCancel () {
      if (!this.newDOB) {
        this.dobError = 'You must select a date for 80+ rule.'
        this.showDOBModal = true
      } else {
        this.showDOBModal = false
        this.dobError = ''
      }
    },

    reloadPage: function (data) {
      window.location.reload()
    },

    onSubmitForm (ifSaveOthers) {
      if (UserService.isAdmin) {
        this.errors = []
        if (!this.round.date) {
          this.errors.push('Date is Required!')
        }
        if (!this.round.teebox_id) {
          this.errors.push('Teebox is Required!')
        }
        if (!this.round.score) {
          this.errors.push('score is Required!')
        }
        if (!this.round.parorbetter) {
          this.errors.push('# of Holes Par or Better is Required!')
        }
        if (this.errors.length === 0) {
          this.saveRoundForm()
          if (ifSaveOthers) {
            this.$router.push({ path: '/postRound/golfers' })
          } else {
            this.onSaveForm()
          }
        }
      } else if (!UserService.isAdmin) {
        this.errors = []
        if (!this.round.date) {
          this.errors.push('Date is Required!')
        }
        if (!this.round.teebox_id) {
          this.errors.push('Teebox is Required!')
        }
        if (!this.round.score) {
          this.errors.push('score is Required!')
        }
        if (!this.round.parorbetter) {
          this.errors.push('# of Holes Par or Better is Required!')
        }
        if (this.errors.length === 0) {
          this.saveRoundForm()
          if (ifSaveOthers) {
            this.$router.push({ path: '/postRound/golfers' })
          } else {
            this.onSaveForm()
          }
        }
      }
    },
    onSaveForm: function () {
      this.$updateloading(1)
      RoundService.addRound(this.round)
        .then(res => {
          this.$updateloading(-1)
          // reset round
          this['round/reset']()
          this['userInfo/reset']()
          this['course/reset']()
          this.$router.push({ path: '/handicapLookup' })
        })
        .catch(errors => {
          this.$updateloading(-1)
          console.log(errors)
        })
    },
    courseConditionsAreModified () {
      this.courseChanged = !_.isEqual(this.round.course, this.coursePristine)
    },
    saveRoundForm (ifSaveOthers) {
      this['round/setRound'](this.round)
    },
    cancelRoundForm () {
      this['round/reset']()
      this['userInfo/reset']()
      this['course/reset']()
      this.$router.push({ path: '/home' })
    },
    onChangeCourse (evt) {
      if (!this.round.course_id) {
        this.round.teebox_id = null
      } else {
        this.$emit('onSearchTeeboxes', this.createSearchFilter())
      }
    },
    createSearchFilter () {
      return {
        course_id: {
          operator: '=',
          value: this.round.course_id
        }
      }
    },
    sortTeeBoxes: function () {
      this.sortedBoxes = _.orderBy(this.teeboxes, 'rating', 'desc')
      /* this.sortedBoxes = this.teeboxes.sort(function (a, b) {
        const valA = a.rating
        const valB = b.rating
        let result = 0
        if (valA > valB) {
          result = -1
        } else if (valB > valA) {
          result = 1
        }
        return result
      }) */
    },
    activeButton: function (item, key) {
      if (item === 'teebox') {
        return this.round.teebox_id === key
      } else if (item === 'tournament') {
        if (this.round.tournament === null) {
          return false
        } else {
          return +this.round.tournament === key
        }
      } else if (item === 'fairway') {
        return this.round.course.fairwaycondition === key
      } else if (item === 'greens') {
        return this.round.course.greenscondition === key
      } else if (item === 'speed') {
        return this.round.course.greensspeed === key
      } else if (item === 'value') {
        return this.round.course.value === key
      }
    },
    setCondition: function (item, key) {
      console.log('Setting condition for:', item, 'to', key)
      if (item === 'teebox') {
        this.round.teebox_id = key
      } else if (item === 'tournament') {
        this.round.tournament = key
        this.courseConditionsAreModified()
      } else if (item === 'fairway') {
        this.round.course.fairwaycondition = key
        this.courseConditionsAreModified()
      } else if (item === 'greens') {
        this.round.course.greenscondition = key
        this.courseConditionsAreModified()
      } else if (item === 'speed') {
        this.round.course.greensspeed = key
        this.courseConditionsAreModified()
      } else if (item === 'value') {
        this.round.course.value = key
        this.courseConditionsAreModified()
      }
      this.$forceUpdate()
    }
  },
  mounted () {
    this.checkDOB()
    if (this.round) {
      if (!this.round.date) {
        this.round.date = moment(new Date()).format('YYYY-MM-DD')
      }
      if (this.round.course) {
        this.coursePristine = _.clone(this.round.course)
      }
    }
  }
}
</script>
