<style scoped>
</style>

<template >
  <div class="row">
    <div class="col-12 mx-auto align-middle">
      <CourseTable
        :filters="filters"
        :courseInfo="courseInfo"
        @updateFilters="onGetFilters($event)"
      >
        <CourseFilter
          :filters="filters"
          @updateFilter="onGetFilters($event)"
        ></CourseFilter>

      </CourseTable>
    </div>
  </div>
</template>

<script>
import CourseService from '@/services/CourseService'
import CourseTable from '@/components/courseTable/CourseTable'
import CourseFilter from '@/components/courseFilter/CourseFilter'
import AimCommonMixin from '@/mixins/aim.common.mixin'

export default {
  name: 'ViewCoures',
  mixins: [AimCommonMixin],
  components: {
    CourseTable,
    CourseFilter
  },
  data: function () {
    return {
      filters: {
        sort: 'recent12mo',
        desc: false,
        size: 10,
        page: 1
      },
      courseInfo: {}
    }
  },
  mounted: function () {
    this.searchCourses()
  },
  methods: {
    searchCourses () {
      this.$updateloading(1)
      let filterStr = this.SerializeFiltersMixin(this.filters)
      CourseService.search(filterStr).then((resp) => {
        this.$updateloading(-1)
        this.courseInfo = resp.data.courseInfo
      }).catch(errors => {
        this.$updateloading(-1)
        console.log(errors)
      })
    },
    onGetFilters (filters) {
      this.filters = filters
      this.searchCourses()
    }
  }
}
</script>
