<template src='./Members.html'>

</template>

<style lang='scss' scoped src="./Members.scss">

</style>

<script>
import MemberCard from '../memberCard/MemberCard'
import ScoreCard from '@/views/leaderboard/ScoreCard'
export default {
  components: {
    MemberCard,
    ScoreCard
  },
  props: {
    'members':
    {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      g2tId: null,
      showCard: false,
      selectedMember: null
    }
  },

  methods: {
    getScoreCard (member) {
      this.g2tId = member.gtid
      this.showCard = true
      this.selectedMember = member
    },

    reset () {
      this.g2tId = null
      this.selectedMember = null
    }
  }
}
</script>
