<style lang="scss" scoped>
</style>

<template>
  <div style="width:100%" class="h-100">
    <!-- title -->
    <div
      class="card table-title border-bottom-0 "
      v-if="teeboxes"
    >
      <div class="card-header text-center">
        <b>Teeboxes</b>
      </div>
    </div>

    <!-- table -->
    <div class="table-responsive mb-0 table-striped border-top-0 table-bordered">
      <table class="table mb-0 table-striped">
        <thead>
          <tr>
            <th class="text-center align-middle">Teebox</th>
            <th class="text-center align-middle">Rating
            </th>
            <th class="text-center align-middle">Slope
            </th>
          </tr>
        </thead>

        <tbody v-if="sortedTeeboxes && sortedTeeboxes.length > 0">
          <tr
            v-for='teebox in sortedTeeboxes'
            :key="teebox.id"
          >
            <td class="text-center">{{teebox.description}}</td>
            <td class="text-center">{{teebox.rating}}</td>
            <td class="text-center">{{teebox.slope}}</td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td
              class="text-text-center"
              colspan=3
            >
              <b>No Teebox Info</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teeboxes: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  computed: {
    sortedTeeboxes () {
      if (this.teeboxes) {
        return Object.values(this.teeboxes).sort((a, b) => b.slope - a.slope)
      }
      return this.teeboxes
    }
  }
}
</script>
