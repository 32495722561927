<style scoped>
</style>

<template >
  <div class="mt-4 mb-4">
    <RoundForm
      :isEdit="isEdit"
      :round="round"
      :regions="regions"
      :teeboxes="teeboxes"
    ></RoundForm>
  </div>
</template>

<script>
import RoundForm from '@/components/roundForm/RoundForm'
import UserService from '@/services/UserService'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'AddRound',
  components: {
    RoundForm
  },
  data: function () {
    return {
      isEdit: false,
      user: null
    }
  },
  created: function () {
    this.init()
  },
  computed: {
    ...mapGetters({
      round: 'round/round',
      regions: 'info/regions',
      teeboxes: 'teebox/teeboxes'
    })
  },
  methods: {
    init () {
      this.user = UserService.getUserObject()
      this.getTeeboxes()
      this.getRegions()
    },
    ...mapActions([
      'teebox/searchTeeboxes',
      'info/getRegions'
    ]),
    ...mapMutations([
      'course/selectedCourses'
    ]),
    async getTeeboxes () {
      this.$updateloading(1)
      let filters = this.createSearchFilter()
      await this['teebox/searchTeeboxes'](filters)
      this.$updateloading(-1)
    },
    async getRegions () {
      this.$updateloading(1)
      await this['info/getRegions']()
      this.$updateloading(-1)
    },
    createSearchFilter () {
      return {
        course_id: {
          operator: '=',
          value: this.round.course.id
        }
      }
    }
  }
}
</script>
