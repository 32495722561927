<template>
  <div>
    <label class="form-label" for="course-select" v-show="!hideLabel">
      <slot name="label">Course:</slot>
    </label>
    <vue-typeahead-bootstrap
      v-show="courseId == null"
      :data="courses"
      v-model="courseSearch"
      :serializer="(s) => s.name"
      @hit="selectCourse($event)"
      :inputClass="!isValid ? 'form-control is-invalid' : 'form-control'"
      inputName="course-select"
    ></vue-typeahead-bootstrap>
    <div class="input-group" v-if="courseInfo">
      <span class="form-control alert-info" style="overflow: hidden;">
        {{ courseInfo.name }}
      </span>
      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="clearCourse()"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <input type="hidden" v-model="courseId" :required="required" />
  </div>
</template>
<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
export default {
  components: {
    VueTypeaheadBootstrap
  },
  // this makes vetur pick up the @ auto-complete for this, but breaks the template interoplation here which annoys me more.
  // emits: ['select-course'],
  props: {
    selectedCourseId: {
      type: [Number, String],
      default () {
        return null
      }
    },
    hideLabel: {
      type: Boolean,
      default () {
        return false
      }
    },
    isValid: {
      type: Boolean,
      default () {
        return true
      }
    },
    required: {
      type: Boolean,
      default () {
        return false
      }
    },
    courses: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      courseId: this.selectedCourseId,
      courseSearch: ''
    }
  },
  computed: {
    /**
     * @returns {Object}
     */
    courseInfo: function () {
      return this.courses.find(x => (+x.id) === (+this.courseId))
    }
  },
  methods: {
    /**
     * @returns {Void}
     */
    selectCourse (course) {
      this.courseId = course.id
      this.courseSearch = ''
      this.$emit('select-course', course)
    },
    clearCourse () {
      this.courseId = null
      this.$emit('select-course', null)
    }
  },
  watch: {
    /**
     * If the bound selectedCourseId is changed
     * from the parent component, update our
     * internal selection
     */
    selectedCourseId (n, o) {
      this.courseId = n
    }
  }
}
</script>
