<template>
  <div class="card col-12 col-md-5 mx-auto">
    <div class="card-body">
      <h4 class="card-title">Reset Your Password</h4>
      <form @submit.prevent="handleSubmit()">
        <div class="form-group">
          <label for="username">Enter Username</label>
          <input
            type="text"
            class="form-control"
            v-model="userForm.username"
            id="username"
            placeholder="username"
            required
          >
        </div>
        <div class="form-group">
          <label for="password">Enter Current Password</label>
          <input
            type="text"
            class="form-control"
            v-model="userForm.password"
            id="password"
            placeholder="password"
            required
          >
        </div>
        <div class="form-group">
          <label for="username">Enter new Password</label>
          <input
            type="text"
            class="form-control"
            v-model="userForm.newpassword"
            id="newpassword"
            placeholder="New Password"
            required
          >
        </div>
        <br>
        <button
          type="submit"
          class="btn btn-primary float-right"
        >Continue</button>
      </form>
      <p v-if="errors.length">
        <b>Please correct the following error(s):</b>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >{{ error }}</li>
        </ul>
      </p>
    </div>
  </div>
</template>

<script>
import UserService from '../services/UserService'
import UserInfoService from '../services/UserInfoService'
export default {
  data () {
    return {
      errors: [],
      userForm: {
        username: null,
        password: null,
        newpassword: null
      },
      user: null
    }
  },
  methods: {
    handleSubmit: function () {
      this.errors = []
      if (!this.userForm.username) {
        this.errors.push('Username is required')
      }
      if (!this.userForm.password) {
        this.errors.push('Password is required')
      }
      if (!this.userForm.newpassword) {
        this.errors.push('New password is required')
      }
      if (!this.errors.length) {
        this.resetTempPwd(this.userForm)
      }
    },
    resetTempPwd: function (data) {
      this.$updateloading(1)
      UserInfoService.resetTempPwd(data)
        .then(res => {
          this.$updateloading(-1)
          localStorage.setItem('desertnomadsuser', JSON.stringify(res.data))
          this.$router.push({ path: '/home' })
        })
        .catch(errors => {
          this.$updateloading(-1)
          this.errors = []
          this.errors.push(errors)
        })
    },
    setUsername () {
      if (this.user) {
        this.userForm.username = this.user.username
      }
    },
    getUser: function () {
      this.user = UserService.getUser()
      this.setUsername()
    }
  },
  created: function () {
    this.getUser()
  }
}
</script>
