<style scoped>
</style>

<template>
  <div>
    <div
      class="card mt-3"
      v-for="(group, gidx) in roster"
      :key="gidx"
    >
      <div class="card-header">
        <div class="row">
          <div class="col-sm-12 col-md-auto">
            <h5><span v-show="group.name != null">Group {{ group.name }}</span><span v-show="group.name == null">No Group Assigned</span></h5>
          </div>
          <div class="col-sm-12 col-md-auto">
            <h5><span v-show="group.name != null">Starting Hole: {{ group.startinghole }} @ {{ group.teetime }}</span></h5>
          </div>
        </div>
      </div>
      <div class="card-body m-0 p-0 py-2 table-responsive">
        <table class="card-body table table-bordered table-striped table-sm">
          <colgroup>
            <col width="40%" />
            <col />
            <col />
            <col />
            <col />
            <col
              v-for="(teebox, i) in teeboxes"
              :key="i"
            />
          </colgroup>
          <thead>
            <tr>
              <th align="left">Name</th>
              <th align="left">Opponent/Partner</th>
              <th align="right">Index</th>
              <th align="right">Strokes</th>
              <th align="left">Flight</th>
              <th
                v-for="(teebox, i) in teeboxes"
                :key="i"
              >{{teebox.description}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(player, pidx) in group.players"
              :key="pidx"
            >
              <td>{{ player.name }}</td>
              <td>{{ player.partner }}</td>
              <td align="right">{{ player.handicapindex }}</td>
              <td align="right">{{ player.coursehandicap }}</td>
              <td>{{ player.flight }}</td>
              <td
                v-for="(teebox, i) in player.teeboxes"
                :key="i"
              >
                {{ teebox.calculated }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PlayersTable',
  props: {
    tournament: {
      type: Object,
      default: function () {
        return {}
      }
    },
    players: {
      type: Array,
      default: function () {
        return []
      }
    },
    teeboxes: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: function () {
    return {
      roster: []
    }
  },
  methods: {
    buildRoster () {
      if (!this.players || this.players.length === 0) {
        return false
      }
      this.roster = []
      this.players.forEach((p) => {
        var player = { ...p }
        // course specific handicap calculations
        player.teeboxes = []
        for (var tee in this.teeboxes) {
          var calculation = 0
          var handicapindex = parseFloat(player.handicapindex)
          var handicap = parseFloat(player.handicap)
          var slope = parseFloat(this.teeboxes[tee].slope)
          var rating = parseFloat(this.teeboxes[tee].rating)
          var par = parseInt(this.tournament.course.par)
          if (handicapindex > 0) {
            if (handicapindex * slope !== 0) {
              calculation = Math.round((handicapindex * slope) / 113)
            } else {
              calculation = Math.round(((handicap * slope) / 113) + (rating - par))
            }
          }
          player.teeboxes.push({
            id: this.teeboxes[tee].id,
            description: this.teeboxes[tee].description,
            calculated: calculation
          })
        }
        var groupIdx = this.roster.findIndex(group => group.name === p.code)
        if (groupIdx < 0) {
          // not found create it
          this.roster.push({
            name: p.code,
            teetime: p.teetime,
            startinghole: p.startinghole,
            players: []
          })
          groupIdx = this.roster.length - 1
        }
        this.roster[groupIdx].players.push(player)
      })
    }
  },
  watch: {
    players: function () {
      this.buildRoster()
    }
  }
}
</script>
