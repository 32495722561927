// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import './services/something'
import Vue from 'vue'
import App from './App'
import router from './router/index'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import BootstrapVue from 'bootstrap-vue'
import Default from './layouts/Default'
import WithSideBar from './layouts/WithSideBar'
import Notifications from 'vue-notification'
import Loader from './services/LoaderOverlay'
import './registerServiceWorker'
import './common/formatNumber.filter'
import './directives/aimSort.directive'
import './directives/aimCollapse.directive'
import VueMoment from 'vue-moment'
import store from './store'
import InfoService from '@/services/InfoService'
import RoundService from '@/services/RoundService'
import CourseService from '@/services/CourseService'
import ContestService from '@/services/ContestService'
import TeeboxService from '@/services/TeeboxService'
import UserInfoService from '@/services/UserInfoService'
import GolferService from '@/services/GolferService'
import TeetimeService from '@/services/TeetimeService'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'
import moment from 'moment'

import './app.scss'
// import jquery
import JQuery from 'jquery'
window.$ = JQuery

export const breadcrumbEventBus = new Vue()
export const spinnerEventBus = new Vue()
export const userEventBus = new Vue()
export const outingEventBus = new Vue()

Vue.filter('momentformat', function (val, format) {
  return moment(val).format(format)
})
Vue.filter('removeseconds', function (val) {
  return val.padEnd(8, '0').substring(0, 5)
})

Vue.use(BootstrapVue)
Vue.use(Notifications)
Vue.use(Loader)
Vue.use(VueMoment)
Vue.use(Vuelidate)
Vue.use(VTooltip)
VTooltip.options.defaultTemplate = '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
VTooltip.options.defaultArrowSelector = '.tooltip-arrow, .tooltip__arrow'
VTooltip.options.defaultInnerSelector = '.tooltip-inner, .tooltip__inner'

// register global component
Vue.component('default-layout', Default)
Vue.component('with-sidebar-layout', WithSideBar)
Vue.config.productionTip = false
InfoService.init()
GolferService.init()
RoundService.init()
CourseService.init()
ContestService.init()
TeeboxService.init()
UserInfoService.init()
TeetimeService.init()

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
