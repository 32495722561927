<template>
  <div>
    <div @click="printCard">
      <slot></slot>
    </div>

    <b-modal v-model="showCard" ok-only>
      <img :src="cardSrc" style="width:100%; height: auto">
    </b-modal>
  </div>
</template>
<script>
import UserService from '@/services/UserService'

export default {
  props: {
    userid: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      cardSrc: null,
      showCard: false
    }
  },
  methods: {
    printCard () {
      UserService.getCard(this.userid).then(
        (response) => {
          this.cardSrc = 'data:image/png;base64,' + response.data[0]
          this.showCard = true
        }
      )
    }
  }
}
</script>
