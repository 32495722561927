<style src="./TeeboxForm.css" scoped>
</style>

<template src="./TeeboxForm.html">

</template>

<script>
import Error from '@/components/Error'
import AimStatesMixin from '@/mixins/aim.states.mixin'
import AimActionMixin from '@/mixins/aim.action.mixin'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TeeboxForm',
  components: {
    Error
  },
  mixins: [AimStatesMixin, AimActionMixin],
  props: {
    isEdit: {
      type: Boolean,
      default () {
        return false
      }
    },
    course: {
      type: Object,
      default () {
        return {
        }
      }
    }
  },
  data () {
    return {
      errors: []
    }
  },
  computed: {
    ...mapGetters({ regions: 'info/regions' }),
    title: function () {
      return this.isEdit ? 'Edit Course Teeboxes' : 'Add Course'
    },
    states: function () {
      return this.getStatesMixin()
    }
  },
  mounted: function () {
    // get regions
    this['info/getRegions']()
  },
  methods: {
    ...mapActions([
      'info/getRegions'
    ]),
    addTeebox: function () {
      this.course.teeboxes.push({
        'description': null,
        'rating': null,
        'slope': null
      })
      this.$forceUpdate()
    },
    onSubmitForm () {
      this.errors = []
      this.validateTeeboxes()
      if (this.errors.length !== 0) {
        return
      }
      this.$emit('sendForm', this.course)
    },
    onDelete (index) {
      this.course.teeboxes.splice(index, 1)
      this.$forceUpdate()
    },
    validateTeeboxes: function () {
      if (!this.course.teeboxes || !this.course.teeboxes.length) {
        return
      }
      let requires = ['description', 'rating', 'slope']
      for (let i = 0; i < this.course.teeboxes.length; i++) {
        let teebox = this.course.teeboxes[i]
        for (let j = 0; j < requires.length; j++) {
          if (!teebox[requires[j]]) {
            this.errors.push(`Teebox ${(i + 1)} ${requires[j]} is Required!`)
          }
        }
      }
    }
  }
}
</script>
