<style lang="scss" scoped>
  .table-lines,.table-lines td,.table-lines th {
    border-collapse: collapse;
    border: 1px solid black;
    padding: 0.5rem 0;
  }
  .active {
    background-color: gold;
  }
  table {
    width: 100%;
  }
</style>
<template>
  <div style="overflow: auto;">
    <table class="text-center table-lines">
      <tr>
        <th>Hole</th>
        <th>Par</th>
        <th>Handicap</th>
        <th>Score</th>
        <th>Golfer(s)</th>
      </tr>
      <tr v-for="s in scores" :key="s.key" :class="{active: s.individual == 1}">
        <td>{{ s.hole }}</td>
        <td>{{ s.par }}</td>
        <td>{{ s.handicap }}</td>
        <td>{{ s.score }}</td>
        <td>{{ s.name }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    scores: {
      type: Array,
      default: () => { return [] }
    }
  },
  filters: {
    initial (val) {
      return val.substring(0, 1)
    },
    withE (val) {
      if (val == null) {
        return 0
      }
      return val === 0 || val === '0' ? 'E' : val
    }
  },

  computed: {
  }
}
</script>
