<template>
  <div>
    <div class="d-block text-left">
      <i class="fa fa-info-circle" v-b-modal.scoringHelp></i>
    </div>
    <router-view></router-view>
    <b-modal id="scoringHelp" size="xl" hide-footer scrollable>
      <div>
        <b>Get Started</b><br>
        <span v-if="loggedIn">
          To get started, just click on your ongoing outing that you are trying to score.
          The button should be green and read &ldquo;Score Outing&rdquo;
        </span>
        <span v-else>
          To get started, check your email for a code or link that was provided. If you were
          not immediately taken to your scoring page then in the box type your provided code
          and click the &ldquo;Score outing&rdquo; button to begin
        </span>
        <br><br>
        <b>Scoring a hole</b><br>
        <ul>
          <li>
            First, using the left and right arrows at the top, navigate to what
            hole you are inputing the score for.
          </li>
          <li>
            Next, use the + and - buttons to set each golfers score for the hole.
          </li>
          <li>
            Once the scores are set, click the &ldquo;Record&rdquo; or &ldquo;Change&rdquo; Score button. Don't worry if you get it wrong.
            You can always go back and fix it before posting the final results.
          </li>
        </ul>
        <br>
        <b>Viewing Score Card</b><br>
        When on the mobile version of the application, click on the name of the golfer to view their score card.
        <br><br>
        <b>Why is a golfer's name colored?</b><br>
        <b>Red</b> Highlighted name is getting <b>1</b> stroke due to course handicap<br>
        <b>Blue</b> Highlighted player is getting <b>2</b> strokes due to course handicap
        <br><br>
        <b>Posting Final Scores</b><br>
        The final scores need to be posted by the organizer of the outing. Whoever made the outing should be able to post the final scores in
        the same place that they created the outing.<br>
        As the event organizer, from the &ldquo;Score Round&rdquo; page you should see a button that says &ldquo;Post Scores&rdquo;.
        Make sure all scores are entered before continuing. Once you post the scores you will not be able to go back and change them.
      </div>
    </b-modal>
  </div>
</template>
<script>
import UserService from '@/services/UserService'
export default {
  data () { return { user: null } },
  mounted () {
    this.user = UserService.getUser()
  },
  computed: {
    loggedIn () {
      return this.user && this.user.id && this.user.id > 0
    }
  }
}
</script>
