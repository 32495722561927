<style scoped>
</style>

<template >
  <div class="row mt-4">
    <div class="col-12 mx-auto align-middle">
      <CourseForm
        :course="course"
        :isEdit="isEdit"
        @sendForm="onGetForm($event)"
      ></CourseForm>
    </div>
  </div>
</template>

<script>
import CourseForm from '@/components/courseForm/CourseForm'
import CourseService from '@/services/CourseService'

export default {
  name: 'Directory',
  components: {
    CourseForm
  },
  data: function () {
    return {
      isEdit: true,
      course: {
        teeboxes: []
      },
      returnTo: ''
    }
  },
  mounted () {
    this.getCourse()

    if (this.$route.query && this.$route.query.return && this.$route.query.return !== '') {
      this.returnTo = this.$route.query.return

      if (this.returnTo === 'tournament') {
        this.$nextTick(() => {
          setTimeout(() => {
            window.scroll({
              left: 0,
              top: document.body.scrollHeight,
              behavior: 'smooth'
            })
          }, 500)
        })
      }
    }
  },
  methods: {
    getCourse: function () {
      CourseService.getCourseInfo(+this.$route.params['cId'])
        .then(res => {
          // set course
          this.course = res.data.course
          // format phone number
          this.course.phonenum = this.course.phonenum.replace(/\D+/g, '')
          // set teeboxes, because teeboxes get from baseTable, so it's nested object
          // instead of array of object
          let teeboxes = Object.keys(res.data.teeboxes).map(function (key) {
            return res.data.teeboxes[key]
          })
          this.course.teeboxes = teeboxes
          this.course.holes = res.data.holes
        })
        .catch(errors => {
          console.log(errors)
        })
    },
    onGetForm: function (data) {
      this.$updateloading(1)
      CourseService.editCourse(data)
        .then(res => {
          this.$updateloading(-1)
          this.getCourse()
          if (this.returnTo && this.returnTo !== '') {
            let params = ''
            if (this.returnTo === 'tournament' && data.holes.length > 8) {
              params = '?course_id=' + data.id
            }
            this.$router.push('/' + this.returnTo + params, () => {
              this.$notify({
                group: 'notices',
                title: 'Course Updated',
                text: 'Course has successfully been updated',
                type: 'info'
              })
            })
          } else {
            this.$notify({
              group: 'notices',
              title: 'Course Updated',
              text: 'Course has successfully been updated',
              type: 'info'
            })
          }
        })
        .catch(errors => {
          this.$updateloading(-1)
          console.log(console.errors)
        })
    }
  }
}
</script>
