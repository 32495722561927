<template>
  <b-container fluid>
    <h3 class="text-center">
      Group Scoring
    </h3>
    <div class="row">
      <div class="col-12 col-md-8 mx-auto mt-2 align-middle" v-if="step == 1">
        <!-- <b-button @click="newOuting()" class="mb-4" v-if="existingTeetime">Start a new outing</b-button> -->
        <!-- :isEdit="isEdit" -->
        <!-- <TeetimeForm
          :teetime="teetime"
          :courses="courses"
          :contests="contests"
          :user="user"
          :teeboxes="teeboxes"
          @onSearchTeeboxes="onGetSearchTeeboxesFilter($event)"
          hide-submit
          multi-scoring
        ></TeetimeForm> -->
        <b-card>
          <div class="form-group row">
            <label for="course" class="col-4 col-md-3 col-form-label">Course</label>
            <div class="col-8 col-md-9 pl-0">

              <course-type-ahead
                hide-label
                :courses="courses"
                :selectedCourseId="teetime.course_id"
                @select-course="onGetSearchTeeboxesFilter"
              ></course-type-ahead>
            </div>
          </div>
          <div class="form-group row" v-if="teetime.course_id">
            <label for="course" class="col-4 col-md-3 col-form-label">Teebox</label>
            <div class="col-8 col-md-9 pl-0">
              <select v-model="teetime.teebox_id" class="custom-select mb-2 mr-sm-2 mb-sm-0" id="teebox">
                <option :value="null" disabled>N/A</option>
                <option v-for="teebox in teeboxes" :value="teebox.id" :key="teebox.id">{{teebox.description}} ({{teebox.slope}}/{{teebox.rating}})
                </option>
              </select>
            </div>
          </div>
          <b-row>
            <b-col cols="12" class="text-center">
              <b-button @click="step = 2" :disabled="!teetime.course_id || !teetime.teebox_id">Continue</b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-row v-if="existingTeetime" class="mt-4">
          <b-col cols="6">
            <b-button block @click="cancel">Cancel</b-button>
          </b-col>
        </b-row>
      </div>

      <div class="col-12 col-md-8 mx-auto mt-2 align-middle" v-if="step == 2">
        <b-card>
          <div class="row mb-4 mt-1 ml-n4">
            <div class="col h4">
              <i class="fa fa-arrow-circle-left back-icon" @click="step = 1"></i>
            </div>
          </div>

          <div class="card mb-1 stick-box">
            <div
              v-if="golfers && golfers.length"
              class="card-header"
            >
              <div class="row">
                <div class="col-12">
                  <b>Selected Golfers &nbsp;</b>
                  <b>({{golfers.length}})</b>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-12">
                  <AimBadge
                    class="mr-2 mb-1"
                    v-for='(g, index) in golfers'
                    :key='index'
                    :infos='[g.firstname, g.lastname]'
                    @updateDelete="deselectUser(g)"
                    :noDelete="+g.user_id == +user.id"
                  ></AimBadge>
                  <div class="float-right">
                    <button
                      class="btn btn-info"
                      @click="step = 3"
                    >Continue</button><br>
                    <small>*Add visitor on next screen</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <GolferFilter
            :filters="filters"
            @updateFilter="onGetFilters($event)"
          ></GolferFilter>

          <div class="card">
            <div class="table-responsive card-header p-0">
              <table class="table mb-0 table-bordered">
                <!-- table header -->
                <thead>
                  <tr>
                    <th class="text-center align-middle">Name</th>
                  </tr>
                </thead>
                <!-- if has data show it -->
                <tbody v-if='usersInfo.results.length'>
                  <tr v-for='user in golferList' :key="user.id">
                    <td class="d-none d-sm-table-cell">
                      {{ user.firstname + ' '+  user.lastname }}
                      <!-- <button class="btn btn-danger float-right" v-if="golferSelected(user.id)"
                        @click.prevent="deselectUser(genUser(user))"
                      >
                        Remove Golfer
                      </button> -->
                      <button class="btn btn-secondary float-right"
                        @click.prevent="selectUser(user)">Click To Select</button>
                    </td>
                    <td class="d-table-cell d-sm-none">
                      <button class="btn btn-secondary btn-block"
                        @click.prevent="selectUser(user)">{{ user.firstname + ' '+  user.lastname }}</button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="text-text-center" colspan=8>
                            <b>No Golfers</b>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-card>
        <b-row v-if="existingTeetime" class="mt-4">
          <b-col cols="6">
            <b-button block @click="cancel">Cancel</b-button>
          </b-col>
        </b-row>
      </div>

      <div class="col-12 col-md-8 mx-auto mt-2 align-middle" v-if="step == 3">
        <b-card>
          <div class="row mb-4 mt-1 ml-n4">
            <div class="col h4">
              <i class="fa fa-arrow-circle-left back-icon" @click="step = 2"></i>
            </div>
          </div>

          <div class="text-center mb-4 h3">
            Guests
          </div>
          <b-row v-if="golfers.filter(i => i.user_id == 0).length > 0">
            <b-col cols="12">
              <!-- desktop -->
              <table class="table mb-0 table-bordered d-none d-md-table">
                <thead>
                  <tr>
                    <th class="text-center align-middle">Info</th>
                    <th class="text-center align-middle">Index</th>
                    <th class="text-center align-middle"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for='(u, index) in golfers.filter(i => i.user_id == 0)' :key='index'>
                    <td class="text-center align-middle">
                      {{ u.firstname }} {{ u.lastname }}<br>{{ u.email }}
                    </td>
                    <td class="text-center align-middle">
                      {{ u.handicapindex }}
                    </td>
                    <td class="text-center align-middle">
                      <b-button class="float-right" variant="danger" @click="deselectUser(u)">
                        <i class="fa fa-times-circle"></i>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- mobile -->
              <table class="table mb-0 table-bordered d-table d-md-none">
                <tbody>
                  <tr v-for='(u, index) in golfers.filter(i => i.user_id == 0)' :key='index'>
                    <td class="text-center align-middle">
                      {{ u.firstname }} {{ u.lastname }}<br>
                      {{ u.email }}<br>
                      {{ u.handicapindex }}<br>
                      <b-button class="float-right" variant="danger" @click="deselectUser(u)" block>
                        <i class="fa fa-times-circle"></i>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="12" class="text-center">
              <b-button v-b-modal.guestadd variant="success" block class="mb-2">Add guest</b-button>
              <b-button @click="afterGuest()" block>Continue</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-row v-if="existingTeetime" class="mt-4">
          <b-col cols="6">
            <b-button block @click="cancel">Cancel</b-button>
          </b-col>
        </b-row>

        <b-modal
          id="guestadd"
          @hidden="resetGuestAdd"
          ok-title="Add"
          @ok="addGuest"
          :ok-disabled="(!guestUser.firstname || guestUser.firstname == '' || !guestUser.lastname || guestUser.lastname == '' ||
            !guestUser.email || guestUser.email == '' || guestUser.handicapindex == null || guestUser.handicapindex == '')"
        >
          <b-form-group label="Firstname">
            <b-input v-model="guestUser.firstname"></b-input>
          </b-form-group>
          <b-form-group label="Lastname">
            <b-input v-model="guestUser.lastname"></b-input>
          </b-form-group>
          <b-form-group label="E-mail">
            <b-input v-model="guestUser.email"></b-input>
          </b-form-group>
          <b-form-group label="Handicap">
            <b-input v-model="guestUser.handicapindex" @keypress="numberInput($event, 2)"></b-input>
          </b-form-group>
        </b-modal>
      </div>

      <div class="col-12 col-md-8 mx-auto mt-2 align-middle" v-if="step == 4">
        <b-card body-class="p-0">
          <div class="row mb-4 mt-4 ml-n1">
            <div class="col h4">
              <i class="fa fa-arrow-circle-left back-icon" @click="step = 3"></i>
            </div>
          </div>
          <div class="text-center font-weight-bold my-4 h3">
            Set Game Format
          </div>
          <div class="container">
            <b-form-group>
              <b-form-radio v-model="teetime.contest_id" :value="0">Normal (Score Only)</b-form-radio>
              <b-form-radio v-model="teetime.contest_id" :value="21" v-if="golfers.length == 4">
                Wolf
                <ContestInfo contest="21" class="ml-2">Info</ContestInfo>
              </b-form-radio>
              <b-form-radio v-model="teetime.contest_id" :value="14" v-if="golfers.length == 4">
                Sixes
                <ContestInfo contest="14" class="ml-2">Info</ContestInfo>
              </b-form-radio>
              <b-form-radio v-model="teetime.contest_id" :value="16" v-if="golfers.length > 1">
                Stableford
                <ContestInfo contest="16" class="ml-2">Info</ContestInfo>
              </b-form-radio>
              <b-form-radio v-if="golfers.length > 1 && teetime.contest_id == 17" v-model="teetime.contest_id" :value="17">
                Rabbit
                <ContestInfo contest="18" class="ml-2">Info</ContestInfo>
              </b-form-radio>
              <b-form-radio v-if="golfers.length > 1 && teetime.contest_id != 17" v-model="teetime.contest_id" :value="18">
                Rabbit
                <ContestInfo contest="18" class="ml-2">Info</ContestInfo>
              </b-form-radio>
              <div class="pl-4">
                <b-form-group v-if="teetime.contest_id == 17 || teetime.contest_id == 18">
                  <b-form-radio  v-model="teetime.contest_id" :value="17">
                    1 Rabbit each 9 holes
                  </b-form-radio>
                  <b-form-radio v-model="teetime.contest_id" :value="18">
                    1 Rabbit for 18 holes
                  </b-form-radio>
                </b-form-group>
              </div>
            </b-form-group>
            <b-form-group>
              <div class="form-check float-left">
                <label class="form-check-label">
                    <input :true-value="1" :false-value="0" v-model="skins" class="form-check-input"
                        type="checkbox"/>
                        Skins
                        <ContestInfo contest="skin" class="ml-2">Info</ContestInfo>
                </label>
              </div><br>
              <div class="form-group row pl-4" v-if="skins == 1">
                <div class="col-4 col-md-3">
                    <div class="form-check float-left">
                        <label class="form-check-label">
                            <input :true-value="1" :false-value="0" v-model="teetime.net_skins" class="form-check-input"
                                type="checkbox"/>
                              Net
                        </label>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                  <div class="form-check float-left">
                    <label class="form-check-label">
                      <input class="form-check-input" :true-value="1" :false-value="0" v-model="teetime.gross_skins"
                                type="checkbox" /> Gross
                    </label>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
        </b-card>
        <b-row class="mt-4">
          <b-col cols="6">
            <b-button block @click="cancel">Cancel</b-button>
          </b-col>
          <b-col cols="6">
            <b-button block variant="success" @click="goToGroupSelect()">Set groups</b-button>
          </b-col>
        </b-row>
      </div>

      <div class="col-12 col-md-8 mx-auto mt-2 align-middle" v-if="step == 5">
        <b-card body-class="p-0">
          <div class="row mb-4 mt-4 ml-n1">
            <div class="col h4">
              <i class="fa fa-arrow-circle-left back-icon" @click="backToFormat()"></i>
            </div>
          </div>
          <div class="text-center font-weight-bold my-4 h3">
            Set Teebox / Group
          </div>
          <div class="px-4">
            Default: Net is scored off full course handicap for each player<br><br>
            Choose to spin off low handicap:<br>
            <b-form-group>
              <b-form-radio-group
                v-model="spinType"
                :options="spinOptions"
                stacked
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <!-- desktop view GOLFER LIST -->
          <div class="d-none d-md-flex" v-if="golfers && golfers.length > 0">
            <table class="table mb-0 table-bordered" v-if="renderList">
              <thead class="">
                <tr>
                  <th class="text-center align-middle">Info</th>
                  <th class="text-center align-middle" v-if="golfers.length > 4">Group</th>
                  <th class="text-center align-middle">Handicap Index</th>
                  <th class="text-center align-middle">Course Index</th>
                  <th class="text-center align-middle">Teebox</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for='(u, index) in golfers' :key='index'>
                  <td class="text-center align-middle">
                    {{ u.firstname }} {{ u.lastname }}
                  </td>
                  <td class="text-center align-middle" v-if="golfers.length > 4">
                    <b-form-group label="Group">
                      <b-form-checkbox
                        @input="groupsChanged($event)"
                        v-for="g in (Math.ceil(golfers.length / 4))" :key="g"
                        :value="g" :checked="[u.tgroup_id]" :unchecked-value="null"
                        :disabled="!groupAvailable(u.user_id, g)"
                        v-model="u.tgroup_id"
                      >{{ g }}</b-form-checkbox>
                    </b-form-group>
                  </td>
                  <td class="text-center align-middle">
                    <div class="input-group form-inline space-x-4">
                        <button class="btn btn-info btn-sm input-group-btn mr-2" @click.prevent="decreaseHandicap(u)"><i class="fas fa-minus"></i></button>
                        <input type="number" maxlength="2" step="0.1" size="3" v-model="u.handicapindex" required class="form-control input-sm no-spinner mr-2" id="handicap">
                        <button class="btn btn-info btn-sm input-group-btn" @click.prevent="increaseHandicap(u)"><i class="fas fa-plus"></i></button>
                    </div>
                    <!-- {{ u.handicapindex }} -->
                  </td>
                  <td class="text-center align-middle">
                    {{ u.coursehandicap }}
                  </td>
                  <td class="text-center align-middle">
                    <select v-model="u.teebox_id" class="custom-select mb-2 mr-sm-2 mb-sm-0">
                      <option :value="null" disabled>-- SELECT ONE --</option>
                      <option v-for="teebox in teeboxes" :value="teebox.id" :key="teebox.id">{{teebox.description}} ({{teebox.slope}}/{{teebox.rating}})
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- mobile view GOLFER LIST -->
          <div class="d-md-none" v-if="golfers && golfers.length > 0">
            <table class="table mb-0 table-bordered" v-if="renderList">
              <tr v-for='(u, index) in golfers' :key='index'>
                <td class="text-center align-middle" v-if="collapsed[u.user_id + '']" @click="collapseUser(u.user_id)"
                  :class="isNaN(u.tgroup_id) || +u.tgroup_id === 0 ? 'border border-danger' : ''"
                >
                  <div class="float-right">
                    <i class="fa fa-caret-up"></i>
                  </div>
                  <div class="text-left">
                    {{ u.lastname }}{{ u.firstname.substr(0, 1) }} - {{ getTeebox(u.teebox_id, false) }} - Hdcp {{ u.coursehandicap }} <span v-if="golfers.length > 4">{{ isNaN(u.tgroup_id) || +u.tgroup_id === 0 ? '' : '- Group ' + u.tgroup_id }}</span>
                  </div>
                </td>
                <td class="text-center align-middle" v-else>
                  <div class="float-right" @click="collapseUser(u.user_id)">
                    <i class="fa fa-caret-down"></i>
                  </div>
                  <div>
                    <b>{{ u.firstname }} {{ u.lastname }}</b><br>
                    Handicap Index: <br>
                    <div class="input-group form-inline space-x-4">
                        <button class="btn btn-info btn-sm input-group-btn px-4 mr-2" @click.prevent="decreaseHandicap(u)"><i class="fas fa-minus"></i></button>
                        <input type="number" maxlength="2" step="0.1" size="1" v-model="u.handicapindex" required class="form-control input-sm no-spinner w-12 mr-2" id="handicap">
                        <button class="btn btn-info btn-sm input-group-btn px-4" @click.prevent="increaseHandicap(u)"><i class="fas fa-plus"></i></button>
                    </div>
                    <br>
                    Course Index: {{ u.coursehandicap }}
                  </div>
                  <b-form-group label="Teebox" label-align="left">
                    <select v-model="u.teebox_id" class="custom-select mb-2 mr-sm-2 mb-sm-0">
                      <option :value="null" disabled>-- SELECT ONE --</option>
                      <option v-for="teebox in teeboxes" :value="teebox.id" :key="teebox.id">{{teebox.description}} ({{teebox.slope}}/{{teebox.rating}})
                      </option>
                    </select>
                  </b-form-group>
                  <b-form-group label="Group" label-align="left" class="text-left" v-if="golfers.length > 4">
                    <b-form-checkbox
                      @input="groupsChanged($event, u.user_id)"
                      v-for="g in (Math.ceil(golfers.length / 4))" :key="g"
                      :value="g" :checked="[u.tgroup_id]" :unchecked-value="null"
                      :disabled="!groupAvailable(u.user_id, g)"
                      v-model="u.tgroup_id"
                    >{{ g }}</b-form-checkbox>
                  </b-form-group>
                </td>
              </tr>
            </table>
          </div>
        </b-card>

        <b-row class="mt-4">
          <b-col cols="6">
            <b-button block @click="cancel">Cancel</b-button>
          </b-col>
          <b-col cols="6">
            <b-button block variant="success" :disabled="!validForm" @click="submit">
              {{ existingTeetime ? 'Update outing' : 'Start outing' }}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div class="col-12 col-md-8 mx-auto mt-2 align-middle" v-if="step == 6">
        <b-card>
          <div class="row mb-4 mt-1 ml-n4">
            <div class="col h4">
              <i class="fa fa-arrow-circle-left back-icon" @click="goToGroupSelect()"></i>
            </div>
          </div>
          <div v-if="teetime.contest_id != 0">
            Game type: {{ gameName }}
          </div>
          <div v-if="!postReady">
            <!-- desktop -->
            <div class="d-none d-md-flex">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th class="text-center align-middle">Info</th>
                    <th class="text-center align-middle">Course Index</th>
                    <th class="text-center align-middle">Teebox</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for='(u, index) in golfers' :key='index'>
                    <td class="text-center align-middle">
                      {{ u.firstname }} {{ u.lastname }}<br>
                      {{ u.accesscode }}
                    </td>
                    <td class="text-center align-middle">
                      {{ u.coursehandicap }}
                    </td>
                    <td class="text-center align-middle">
                      {{ getTeebox(u.teebox_id) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- mobile -->
            <div class="d-flex d-md-none">
              <table class="table mb-0 table-bordered">
                <tbody>
                  <tr v-for='(u, index) in golfers' :key='index'>
                    <td class="text-center align-middle">
                      {{ u.firstname }} {{ u.lastname }}: <u>{{ u.accesscode }}</u><br>
                      Course Index: {{ u.coursehandicap }}<br>
                      {{ getTeebox(u.teebox_id) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button class="mt-4" variant="danger" @click="cancelOuting()">Cancel Outing</b-button><br>
            <small>
              * A new outing cannot be created until you post the scores or cancel the current outing
            </small>
          </div>
          <div v-if="postReady">
            <!-- desktop -->
            <div class="d-none d-md-flex">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th class="text-center align-middle">Name</th>
                    <th class="text-center align-middle">Gross Score</th>
                    <th class="text-center align-middle">Pars Or Better</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for='(u, index) in scores' :key='index'>
                    <td class="text-center align-middle">
                      {{ u.name && u.name != '' ? u.name : u.first + ' ' + u.last }}
                    </td>
                    <td class="text-center align-middle">
                      {{ u.totalgross }}
                    </td>
                    <td class="text-center align-middle">
                      {{ u.parorbetter }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- mobile -->
            <div class="d-flex d-md-none">
              <table class="table mb-0 table-bordered">
                <tbody>
                  <tr v-for='(u, index) in scores' :key='index'>
                    <td class="text-center align-middle">
                      {{ u.name && u.name != '' ? u.name : u.first + ' ' + u.last }}<br>
                      Gross Score: {{ u.totalgross }}<br>
                      Pars or Better: {{ u.parorbetter }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button block class="mt-4" variant="info" @click="postScores()">Post scores</b-button>
            <small>
              * A new outing cannot be created until you post the scores for the current outing
            </small>
          </div>
        </b-card>
      </div>
    </div>
  </b-container>
</template>
<script>
import CourseService from '@/services/CourseService'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import AimCommonMixin from '@/mixins/aim.common.mixin'
import AimActionMixin from '@/mixins/aim.action.mixin'
import UserService from '@/services/UserService'
// import TeetimeForm from '@/components/teetimeForm/TeetimeForm.vue'
import TeeboxService from '@/services/TeeboxService'
import ContestService from '@/services/ContestService'
import TeetimeService from '@/services/TeetimeService'
import RoundService from '@/services/RoundService'
import AimBadge from '@/components/Aim/AimBadge'
import GolferFilter from '@/components/golferFilter/GolferFilter'
import CourseTypeAhead from '@/components/CourseTypeAhead'
import ContestInfo from '@/components/ContestInfo'
import { outingEventBus } from '@/main'

function initialstate () {
  return {
    teetime: {
      id: 0,
      ampm: 'am',
      date: null,
      day: null,
      hour: null,
      min: null,
      num_wanted: null,
      course_id: null,
      rate: null,
      tournament: 0,
      prepay: 0,
      contest_id: 0,
      contact_info: null,
      gross_skins: 0,
      net_skins: 0,
      inprogress: 1,
      send_email: null,
      teebox_id: null
    },

    skins: 0,

    spinType: 0,

    courses: [],
    contests: [],
    user: null,
    teeboxes: [],

    collapsed: [],
    renderList: true,

    filters: {
      sort: 'name',
      currentuser_id: 0,
      desc: false,
      size: 0,
      page: 1
    },
    golferList: [],
    golfers: [],
    removedGolfers: [],
    existingTeetime: false,
    step: 1,
    guestUser: {
      id: 0,
      firstname: '',
      lastname: '',
      email: '',
      handicapindex: 0
    },
    postReady: false,
    scores: []
  }
}

export default {
  components: {
    AimBadge,
    GolferFilter,
    CourseTypeAhead,
    ContestInfo
    // TeetimeForm
  },
  data () {
    return initialstate()
  },

  mixins: [AimCommonMixin, AimActionMixin],
  computed: {
    // use getter to get => user module's => coursesInfo getter
    ...mapGetters({
      round: 'round/round',
      usersInfo: 'userInfo/usersInfo',
      selectedUsers: 'userInfo/selectedUsers'
    }),

    validForm () {
      const golfersValid = !this.golfers.some(e => {
        return (!e.firstname || e.firstname === '' || !e.lastname || e.lastname === '' ||
          !e.email || e.email === '' || e.handicapindex == null || !e.teebox_id || e.teebox_id === 0)
      })

      const groupsSet = !this.golfers.some(e => {
        return !e.tgroup_id || +e.tgroup_id === 0
      })

      return this.teetime.course_id && this.teetime.course_id > 0 &&
        this.teetime.teebox_id && this.teetime.teebox_id > 0 &&
        this.golfers && this.golfers.length > 1 && golfersValid && groupsSet
    },

    spinOptions () {
      const opts = [
        { text: 'By Group', value: 1 }
      ]

      if (this.golfers.length > 4) {
        opts.push({ text: 'By Field', value: 2 })
      }

      opts.push({ text: 'Keep Default', value: 0 })

      return opts
    },

    gameName () {
      if (+this.teetime.contest_id === 0) {
        return ''
      } else if (+this.teetime.contest_id === 14) {
        return 'Sixes'
      } else if (+this.teetime.contest_id === 16) {
        return 'Stableford'
      } else {
        return ''
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    //  get actions
    ...mapActions([
      'userInfo/searchUser'
    ]),

    async searchUsers () {
      this.$updateloading(1)
      let filterStr = this.SerializeFiltersMixin(this.filters)
      await this['userInfo/searchUser'](filterStr)

      this.golferList = []
      this.usersInfo.results.forEach(e => {
        if (+e.id !== +this.filters.currentuser_id && this.golfers.filter(i => +i.user_id === +e.id).length === 0) {
          this.golferList.push(e)
        }
      })
      this.$updateloading(-1)
    },

    onGetFilters (filters) {
      this.filters = filters
      this.searchUsers()
    },

    init () {
      Object.assign(this.$data, initialstate())
      this.$updateloading(1)
      this.getCourses()
      this.getContests()
      this.getUser()

      this.filters.currentuser_id = this.user.id
      this.searchUsers()

      TeetimeService.initGroupOuting().then(
        (response) => {
          if (response.data.teetime && response.data.golfers) {
            this.onGetSearchTeeboxesFilter({ id: response.data.teetime.course_id })
            this.teetime = response.data.teetime
            this.golfers = response.data.golfers
            this.scores = response.data.scores
            this.postReady = (+response.data.postReady === 1)
            this.existingTeetime = true
            this.step = 6
          } else {
            this.initCreate()
          }
        }
      ).finally(() => {
        if (this.$route.query && this.$route.query.course_id && +this.$route.query.course_id > 0) {
          this.teetime.course_id = this.$route.query.course_id
          this.onGetSearchTeeboxesFilter({ id: this.teetime.course_id })
        }
        this.$updateloading(-1)
      })
    },

    initCreate () {
      this.reset()
      let handicap = 0
      RoundService.getUserRoundsReviews(this.user.id, { sort: 'date', desc: true, size: 8 }).then((resp) => {
        handicap = resp.data.rounds_reviews.handicap

        this.golfers.push({
          id: 0,
          user_id: +this.user.id,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          handicapindex: handicap,
          coursehandicap: null,
          email: this.user.email,
          teebox_id: null,
          accesscode: null,
          score: null,
          tgroup_id: 0
        })
        this.$updateloading(-1)
      }).catch(errors => {
        this.$updateloading(-1)
      })
    },

    getTeebox (id, slope = true) {
      let teebox = ''

      this.teeboxes.some(e => {
        if (e.id === id) {
          teebox = e.description
          if (slope) {
            teebox += ' (' + e.slope + '/' + e.rating + ')'
          }
          return true
        } else {
          return false
        }
      })

      return teebox
    },

    decreaseHandicap (golfer) {
      let golferIndex = this.golfers.findIndex(g => g.user_id === golfer.user_id)
      if (golferIndex !== -1) {
        // Decrease handicapindex
        let currentHandicapIndex = Number(this.golfers[golferIndex].handicapindex)
        if (currentHandicapIndex > 0) {
          this.golfers[golferIndex].handicapindex = parseFloat((currentHandicapIndex - 0.1).toFixed(1))
        }
        let idx = this.getTeeboxHandicap(this.golfers[golferIndex].teebox_id, this.golfers[golferIndex].handicapindex)
        this.golfers[golferIndex].coursehandicap = idx
      }
    },
    increaseHandicap (golfer) {
      let golferIndex = this.golfers.findIndex(g => g.user_id === golfer.user_id)
      if (golferIndex !== -1) {
        // Increase handicapindex
        let currentHandicapIndex = Number(this.golfers[golferIndex].handicapindex)
        if (currentHandicapIndex > 0) {
          this.golfers[golferIndex].handicapindex = parseFloat((currentHandicapIndex + 0.1).toFixed(1))
        }
        let idx = this.getTeeboxHandicap(this.golfers[golferIndex].teebox_id, this.golfers[golferIndex].handicapindex)
        this.golfers[golferIndex].coursehandicap = idx
      }
    },

    getTeeboxHandicap (teeboxId, index) {
      if (this.teeboxes.length === 0 || this.courses.length === 0) {
        return null
      }

      let teebox = this.teeboxes.filter(i => +i.id === +teeboxId)[0]
      let course = this.courses.filter(i => +i.id === +this.teetime.course_id)[0]

      let a = +index
      let b = +teebox.slope
      let c = 113
      let d = +teebox.rating
      let e = +course.par
      const idx = (a * b / c) + (d - e)

      return Math.round(idx)
    },

    cancel () {
      Object.assign(this.$data, initialstate())
      this.init()
    },

    setDate: function () {
      const today = moment()
      this.teetime['date'] = today.format('YYYY-MM-DD')
      this.teetime['hour'] = +today.format('h')
      let min = +today.format('m')
      min = parseInt(min / 10)
      min += (min % 10 === 0) ? 0 : 1
      if (min === 6) {
        this.teetime.hour++
        min = 0
      }

      this.teetime['hour'] = ('' + this.teetime['hour'])
      this.teetime['min'] = (min + '0')

      this.teetime['ampm'] = today.format('a')
      this.teetime['day'] = today.format('dddd')
    },
    getCourses: function () {
      this.$updateloading(1)
      CourseService.getCourses().then(res => {
        this.$updateloading(-1)
        this.courses = res.data.courses
      }).catch(error => {
        console.log(error)
        this.$updateloading(-1)
      })
    },
    getContests: function () {
      ContestService.getContests().then(res => {
        this.$updateloading(-1)
        this.contests = res.data.contests
      }).catch(error => {
        console.log(error)
        this.$updateloading(-1)
      })
    },
    getUser: function () {
      this.user = UserService.getUser()
    },
    searchSelectCourseTeebox: function (filters) {
      this.$updateloading(1)
      TeeboxService.searchTeebox(filters).then(res => {
        this.$updateloading(-1)
        this.teeboxes = res.data.teeboxes

        if (this.teeboxes.length === 1) {
          this.teetime.teebox_id = this.teeboxes[0].id
        }
      }).catch(errors => {
        this.$updateloading(-1)
        console.log(errors)
      })
    },
    onGetSearchTeeboxesFilter: function (val) {
      let courseId = this.teetime.course_id

      this.golfers.forEach(e => {
        e.teebox_id = null
      })

      this.teetime.teebox_id = null

      if (val && val.id) {
        courseId = +val.id
        this.teetime.course_id = +val.id
      }

      if (val == null) {
        this.teetime.course_id = null
        return
      }

      CourseService.getCourseInfo(+courseId).then(
        res => {
          const holes = res.data.holes

          if (holes.length !== 9 && holes.length !== 18) {
            this.$bvModal.msgBoxOk('The selected course does not have its holes set. Please set these now on the following page then save it.', {
              title: 'Holes not set'
            }).then(
              () => {
                this.teetime.course_id = null
                this.$router.push('/editCourse/' + courseId + '?return=tournament')
              }
            )
          }

          if (holes.length === 9) {
            this.$notify({
              group: 'notices',
              title: 'Nine holes found',
              text: 'If playing 9 hole course twice, start another round after 9 holes',
              type: 'info'
            })
          }
        }
      ).catch(
        errors => {
          console.log(errors)
        }
      )

      const filter = {
        course_id: {
          operator: '=',
          value: courseId
        }
      }

      this.searchSelectCourseTeebox(filter)
    },

    selectUser (user) {
      let g2tId = 0
      let keptUser = null
      this.removedGolfers.forEach(e => {
        if (e.user_id === user.id) {
          g2tId = e.id
          keptUser = e
        }
      })

      if (keptUser) {
        const idx = this.removedGolfers.indexOf(keptUser)
        this.removedGolfers.splice(idx, 1)
      }

      const courseIdx = this.getTeeboxHandicap(this.teetime.teebox_id, user.handicapindex)

      var item = {
        id: g2tId,
        user_id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        handicapindex: user.handicapindex,
        coursehandicap: courseIdx !== null ? courseIdx : 0,
        teebox_id: this.teetime.teebox_id,
        tgroup_id: 0,
        accesscode: null,
        score: null
      }
      this.golfers.push(item)
      this.teetime.contest_id = 0
      this.searchUsers()
    },

    addGuest (bvModalEvt) {
      bvModalEvt.preventDefault()

      this.selectUser(this.guestUser)
      this.resetGuestAdd()
      this.$nextTick(() => {
        this.$bvModal.hide('guestadd')
      })
    },

    resetGuestAdd () {
      this.guestUser = {
        id: 0,
        firstname: '',
        lastname: '',
        email: '',
        handicapindex: 0
      }
    },

    genUser (user) {
      const u = this.golfers.filter(i => +i.user_id === +user.id)

      let tmp = {
        id: 0,
        user_id: user.user_id,
        accesscode: null,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        handicapindex: user.handicapindex,
        teebox_id: null
      }

      if (u && u.length > 0) {
        tmp = {
          id: u[0].id,
          user_id: u[0].user_id,
          accesscode: u[0].accesscode,
          firstname: u[0].firstname,
          lastname: u[0].lastname,
          email: u[0].email,
          handicapindex: u[0].handicapindex,
          teebox_id: u[0].teebox_id
        }
      }

      return tmp
    },

    deselectUser (user) {
      this.teetime.contest_id = 0
      if (user.id > 0) {
        this.removeOneMixin(this.golfers, user)
        if (this.existingTeetime) {
          this.removedGolfers.push({ id: user.id, user_id: user.user_id })
        }
        this.searchUsers()
      } else if (user.user_id > 0) {
        const idx = this.golfers.findIndex((obj) => {
          return obj.user_id === user.user_id
        })
        if (idx !== -1) {
          this.golfers.splice(idx, 1)
        }
      } else if (user.accesscode && user.accesscode !== '') {
        const idx = this.golfers.findIndex((obj) => {
          return obj.accesscode === user.accesscode
        })
        if (idx !== -1) {
          this.golfers.splice(idx, 1)
        }
      } else {
        const idx = this.golfers.findIndex((obj) => {
          return obj.firstname === user.firstname && obj.lastname === user.lastname &&
            obj.email === user.email && obj.handicapindex === user.handicapindex &&
            obj.teebox_id === user.teebox_id
        })
        if (idx !== -1) {
          this.golfers.splice(idx, 1)
        }
      }

      this.searchUsers()
    },

    goToGroupSelect () {
      let nextAvailable = 1
      this.golfers.forEach(e => {
        if (!e.tgroup_id) {
          while (!this.groupAvailable(e.user_id, nextAvailable)) {
            nextAvailable++
          }
          e.tgroup_id = nextAvailable
        }
      })

      this.step = 5
    },

    afterGuest () {
      if (this.golfers.length > 1) {
        this.step = 4
      } else {
        this.goToGroupSelect()
      }
    },

    backToFormat () {
      if (this.golfers.length > 1) {
        this.step = 4
      } else {
        this.step = 3
      }
    },

    groupsChanged (event, id = null) {
      if (!isNaN(event) && +event > 0) {
        this.$updateloading(1)
        this.golfers.sort((a, b) => a.tgroup_id > b.tgroup_id ? 1 : (a.tgroup_id === b.tgroup_id ? 0 : -1))
        this.renderList = false

        if (id && +id > 0) {
          this.collapsed[id + ''] = true
        }

        this.$nextTick(() => {
          this.renderList = true
        })
        setTimeout(() => {
          this.$updateloading(-1)
        }, 250)
      }
    },

    collapseUser (id) {
      this.$updateloading(1)
      if (this.collapsed[id + '']) {
        this.collapsed[id + ''] = !this.collapsed[id + '']
      } else {
        this.collapsed[id + ''] = true
      }

      if (this.collapsed[id + '']) {
        this.golfers.sort((a, b) => a.tgroup_id > b.tgroup_id ? 1 : (a.tgroup_id === b.tgroup_id ? 0 : -1))
      }
      this.renderList = false
      this.$nextTick(() => {
        this.renderList = true
      })
      setTimeout(() => {
        this.$updateloading(-1)
      }, 250)
    },

    groupAvailable (userId, groupnum) {
      let count = 0
      let gGroup = 0
      this.golfers.forEach(e => {
        if (+e.tgroup_id === +groupnum) {
          count++
        }
        if (+e.user_id === +userId) {
          gGroup = e.tgroup_id
        }
      })

      return count < 4 || +gGroup === +groupnum
    },

    newOuting () {
      this.$bvModal.msgBoxConfirm('If you start a new outing you will not be able to edit or post the scores for the current one anymore').then(
        (res) => {
          if (res) {
            this.existingTeetime = false
            this.initCreate()
          } else {
            return false
          }
        }
      )
    },

    postScores () {
      this.$bvModal.msgBoxConfirm('Please double check that scores for all holes have been entered and the scoreboard is accureate.' +
        ' Once you post the scores, they cannot be changed').then(
        (res) => {
          if (res && this.teetime && this.teetime.id && this.teetime.id > 0) {
            this.$updateloading(1)
            TeetimeService.postScores(this.teetime.id).then(
              (response) => {
                this.init()
                this.$notify({
                  group: 'notices',
                  title: 'Round posted',
                  text: 'The scores for all the golfers has been posted',
                  type: 'info'
                })
              }
            ).catch(errors => {
              this.$notify({
                group: 'errors',
                title: 'Unable to post scores',
                text: errors.response.data.error,
                type: 'error'
              })
            }).finally(() => { this.$updateloading(-1) })
            outingEventBus.$emit('eventChanged')
          } else {
            return false
          }
        }
      )
    },

    cancelOuting () {
      this.$bvModal.msgBoxConfirm('Are you sure you want to cancel this outing?').then(
        (res) => {
          if (res && this.teetime && this.teetime.id && this.teetime.id > 0) {
            this.$updateloading(1)
            TeetimeService.cancelOuting(this.teetime.id).then(
              (response) => {
                this.init()
                this.$notify({
                  group: 'notices',
                  title: 'Outing Canceled',
                  text: 'The outing has been canceled',
                  type: 'info'
                })
              }
            ).catch(errors => {
              this.$notify({
                group: 'errors',
                title: 'Unable to cancel outing',
                text: errors.response.data.error,
                type: 'error'
              })
            }).finally(() => { this.$updateloading(-1) })
          } else {
            return false
          }
        }
      )
    },

    reset () {
      this.teetime = {
        id: 0,
        ampm: 'am',
        date: null,
        day: null,
        hour: null,
        min: null,
        num_wanted: null,
        course_id: null,
        rate: null,
        tournament: 0,
        prepay: 0,
        contest_id: 0,
        contact_info: null,
        gross_skins: 0,
        net_skins: 0,
        send_email: null,
        teebox_id: null,
        inprogress: 1
      }

      this.setDate()

      this.golfers = []
    },

    submit () {
      this.$updateloading(1)
      // this.golfers.forEach(e => {
      //   const idx = this.getTeeboxHandicap(e.teebox_id, e.handicapindex)
      //   e.coursehandicap = idx !== null ? idx : null
      // })
      const data = {
        teetime: this.teetime,
        golfers: this.golfers,
        removedGolfers: this.removedGolfers
      }
      TeetimeService.updateGroupOuting(data, this.teetime.id).then(
        (response) => {
          if (response.data.code && response.data.code !== '') {
            this.$router.push('/leaderboard/' + response.data.code)
          } else {
            this.cancel()
          }
        }
      ).catch(errors => {
        this.$notify({
          group: 'errors',
          title: 'Unable to Add Course',
          text: errors.response.data.error,
          type: 'error'
        })
      }).finally(() => { this.$updateloading(-1) })
    },

    numberInput (e, decimals = 0) {
      var test = new RegExp('^\\d*(\\.\\d{0,' + (decimals - 1) + '})?$')
      if (!/\d/.test(e.key) && e.key !== '.') {
        e.preventDefault()
      } else if (decimals === 0 && e.key === '.') {
        e.preventDefault()
      } else if (!test.test(e.target.value)) {
        e.preventDefault()
      } else if (e.key === '.' && e.target.value.indexOf('.') >= 0) {
        e.preventDefault()
      }
    }
    // golferSelected (id) {
    //   return this.golfers.filter(i => +i.user_id === +id).length > 0
    // }
  },

  watch: {
    'teetime.teebox_id' (n, o) {
      if (n !== o) {
        this.golfers.forEach(e => {
          if (!n) {
            e.teebox_id = null
          }
          if (!e.teebox_id) {
            e.teebox_id = n
          }
        })
      }
    },

    skins (n, o) {
      if (n !== o) {
        if (n === 1) {
          this.teetime.net_skins = 1
        } else {
          this.teetime.net_skins = 0
          this.teetime.gross_skins = 0
        }
      }
    },

    step (n, o) {
      if (n !== o && this.golfers && this.golfers.length > 1) {
        this.collapsed = []
        this.golfers.forEach(e => {
          this.collapsed[e.user_id + ''] = true
        })
        this.groupsChanged(1)
      }
    },

    spinType () {
      // just need to change something to trigger the real logic
      if (this.golfers.length > 0) {
        this.golfers[0].coursehandicap = -100
      }
    },

    golfers: {
      handler: function (n) {
        let lowestGroup = []
        let lowestField = null
        n.forEach(e => {
          let idx = this.getTeeboxHandicap(e.teebox_id, e.handicapindex)
          if (idx == null) {
            idx = 0
          }
          if (!lowestGroup[e.tgroup_id + '']) {
            lowestGroup[e.tgroup_id + ''] = +idx
          } else {
            lowestGroup[e.tgroup_id + ''] = (+idx < lowestGroup[e.tgroup_id + '']) ? +idx : lowestGroup[e.tgroup_id + '']
          }
          if (lowestField === null) {
            lowestField = +idx
          } else {
            lowestField = (+idx < lowestField) ? +idx : lowestField
          }
        })
        n.forEach(e => {
          let idx = this.getTeeboxHandicap(e.teebox_id, e.handicapindex)
          if (idx == null) {
            idx = 0
          }
          if (this.spinType === 2) {
            e.coursehandicap = Math.floor((+idx * 100) - (lowestField * 100)) / 100
          } else if (this.spinType === 1) {
            e.coursehandicap = Math.floor((+idx * 100) - (lowestGroup[e.tgroup_id + ''] * 100)) / 100
          } else {
            e.coursehandicap = +idx
          }
        })
      },
      deep: true
    }
  }
}
</script>
<style scoped>
  .stick-box {
    position: -webkit-sticky;
    position: sticky;
    top: 62px;
    z-index: 10;
  }
</style>
