
<style scoped lang="scss" src="./CourseCard.scss"></style>

<template src='./CourseCard.html'></template>

<script>
import CollapseBtn from '@/components/CollapseBtn'
import AimPhoneIcon from '@/components/Aim/icons/AimPhoneIcon'
import AimEmailIcon from '@/components/Aim/icons/AimEmailIcon'

export default {
  components: {
    CollapseBtn,
    AimPhoneIcon,
    AimEmailIcon
  },
  props: {
    course: null
  },
  data: function () {
    return {
      isCollapsed: true,
      GreenConditionLevels: ['N/A', 'Bad', 'Fair', 'Good', 'Immaculate'],
      GreenSpeedLevels: ['N/A', 'Slow', 'Medium', 'Quick', 'Lightning'],
      FairwayConditionLevels: ['N/A', 'Bad', 'Fair', 'Good', 'Immaculate'],
      ValueLevels: ['N/A', 'Ripoff', 'Fair', 'Good', 'Smmmokin!']
    }
  },
  methods: {
    onGetCollapse: function (collapse) {
      this.isCollapsed = collapse
    },
    changeSortTo: function (sort) {
      this.$emit('updateSort', sort)
    }
  }
}
</script>
