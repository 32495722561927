<style scoped>
</style>

<template >
  <div>
    <CourseHandicapTable
      :filters="filters"
      :userInfo="userInfo"
      :teeboxes="teeboxes"
      :course="course"
      @updateFilters="onGetFilters($event)"
    >
    </CourseHandicapTable>
  </div>
</template>

<script>
import UserInfoService from '@/services/UserInfoService'
import UserService from '@/services/UserService'
import CourseHandicapTable from '@/components/courseHandicapTable/CourseHandicapTable'
import AimCommonMixin from '@/mixins/aim.common.mixin'

export default {
  name: 'ViewCourseHandicaps',
  mixins: [AimCommonMixin],
  components: {
    CourseHandicapTable
  },
  props: {
    teeboxes: null,
    course: null
  },
  data: function () {
    return {
      filters: {
        sort: 'name',
        desc: false,
        size: 0,
        page: 1,
        hidepag: true
      },
      userInfo: null
    }
  },
  mounted: function () {
    this.filters['course_id'] = +this.$route.params.cId
    this.searchUser()
  },
  methods: {
    searchUser () {
      this.$updateloading(1)
      UserInfoService.search(this.SerializeFiltersMixin(this.filters))
        .then(res => {
          this.$updateloading(-1)
          this.userInfo = res.data.userInfo
          var user = UserService.getUserObject()
          if (user) {
            this.userInfo.results.forEach(e => {
              if (e.id === +user.user.id) {
                var topel = e
                this.userInfo.results = this.userInfo.results.filter(item => item !== e)
                this.userInfo.results.unshift(topel)
              }
            })
          }
        }).catch(errors => {
          this.$updateloading(-1)
          console.log(errors)
        })
    },
    onGetFilters (filters) {
      this.filters = filters
      this.searchUser()
    }
  }
}
</script>
