<template>
  <div class="nav-location">
    <b-navbar class="bg-light" toggleable="lg" type="light">
      <b-navbar-brand to="/home">
        <img
          :src="'/img/desertnomadstitle.png'"
          class="img-responsive"
          height="35px;"
          style="margin: 0 auto;"
        />
      </b-navbar-brand>
      <!-- Score Button -->
      <b-button
        v-if="showScoreButton && showBackToScoring && !$route.path.match(/^\/leaderboard\/.*/) && $route.path !== '/login' && outingInProgress"
        class="green-button back-to-scoring"
        @click="goToCurrentOuting"
      >Score
      </b-button>
      <b-navbar-toggle target="nav-collapse" @click="toggleDropdown"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav @show="onDropdownOpen" @hide="onDropdownClose">
        <b-navbar-nav>
          <b-nav-item
            class="text-center navItem"
            v-if="ifShowNavElement('/postRound')"
            :active="curtRouteTitle == 'Post Round'"
            to="/postRound"
          >Post Round</b-nav-item>
          <b-nav-item
            class="text-center navItem"
            v-if="ifShowNavElement('/currentOutings')"
            :active="curtRouteTitle == 'Current Outings'"
            to="/currentOutings"
          >Current Outings</b-nav-item>
          <b-nav-item
            class="text-center navItem"
            v-if="ifShowNavElement('/handicapLookup')"
            :active="curtRouteTitle == 'Handicap Lookup'"
            to="/handicapLookup"
          >Handicap Lookup</b-nav-item>
          <b-nav-item
            class="text-center navItem"
            v-if="ifShowNavElement('/visitors')"
            :active="curtRouteTitle == 'Visitors'"
            to="/visitors"
          >Visitors</b-nav-item>
          <b-nav-item
            class="text-center navItem"
            v-if="ifShowNavElement('/directory')"
            :active="curtRouteTitle == 'Directory'"
            to="/directory"
          >Directory</b-nav-item>
          <b-nav-item
            class="text-center navItem"
            v-if="username"
            :active="curtRouteTitle == 'View Courses'"
            to="/viewCourses"
          >Courses</b-nav-item>
          <b-nav-item
            class="text-center navItem"
            v-if="ifShowNavElement('/contactUs')"
            :active="curtRouteTitle == 'Contact US'"
            to="/contactUs"
          >Contact US</b-nav-item>
          <b-nav-item
            class="text-center navItem"
            href="https://www.slydercup.com/"
          >Slyder Cup</b-nav-item>
          <b-nav-item
            class="text-center navItem"
            v-if="ifShowNavElement('/leaderboard')"
            :active="curtRouteTitle == 'Leaderboard'"
            to="/leaderboard"
          >Leaderboard</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto align-items-center">
          <!-- Back to Scoring Button -->
          <b-button
            v-if="showScoreButton && !showBackToScoring && !$route.path.match(/^\/leaderboard\/.*/) && $route.path !== '/login' && outingInProgress"
            class="green-button"
            @click="goToCurrentOuting"
          >Back to Scoring
          </b-button>
          <b-nav-item v-if="!username" :active="curtRouteTitle == 'Login'" to="/login">Login</b-nav-item>
          <b-nav-item-dropdown v-else class="text-center" right>
            <template v-slot:button-content>
              <em>{{ username }}</em>
            </template>
            <b-dropdown-item to="settings">My Account</b-dropdown-item>
            <PrintCard>
              <template>
                <b-dropdown-item>Print Card</b-dropdown-item>
              </template>
            </PrintCard>
            <b-dropdown-item to="#" @click.prevent="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import TeetimeService from '@/services/TeetimeService'
import { applicationServerPublicKey } from '@/services/ApplicationKeys'
import { userEventBus, outingEventBus } from '@/main'
import PrintCard from '@/components/PrintCard'

export default {
  data () {
    return {
      username: null,
      showBackToScoring: false, // State to manage button visibility
      outingInProgress: false, // State to track outing progress
      storedUserId: null,
      showScoreButton: true,
      statusCheckInterval: null // Property to store the interval ID
    }
  },
  components: {
    PrintCard
  },
  computed: {
    curtRouteTitle () {
      return this.$route.meta.title
    }
  },
  methods: {
    async getOutingInProgress () {
      let userId = this.storedUserId
      if (userId) {
        try {
          let response = await TeetimeService.outingInProgress(userId)
          if (response.status === 200) {
            console.log('Progress', response.data.inprogress)
            return response.data.inprogress
          } else {
            return false
          }
        } catch (error) {
          console.error('Failed to get inProgress status:', error)
          if (error.response && error.response.status === 401) {
            // Handle unauthorized access
            return false
          }
        }
      } else {
        console.log('UserId is undefined')
      }
    },
    async updateOutingInProgress () {
      console.log('Outing InProgress has started')
      this.outingInProgress = await this.getOutingInProgress()
    },
    getUsername () {
      let user = UserService.getUser()
      if (user) {
        this.username = user.firstname + ' ' + user.lastname
        this.admin = user.isadmin
        this.storedUserId = user.id
        console.log('User Info', user)
        this.updateOutingInProgress()
        this.startStatusCheckInterval() // Start the interval when the user logs in
      } else {
        this.username = null
        this.admin = false
        this.storedUserId = null
        this.stopStatusCheckInterval() // Stop the interval when the user logs out
      }
    },
    logout () {
      let self = this
      UserService.logout()
      this.getUsername()
      self.$router.push('/login', function () {
        self.$notify({
          group: 'notices',
          title: 'Logout Complete',
          text: 'You are now logged out.',
          type: 'alert-success'
        })
      })
    },
    checkSubscription () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          if (!registration || !registration.pushManager) {
            return
          }
          registration.pushManager.getSubscription().then(subscription => {
            this.isSubscribed = subscription !== null
          })
        })
      }
    },
    subscribe () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerPublicKey
          }).then(subscription => {
            fetch(UserService.baseUrl() + 'api/v1/user/subscribe', {
              method: 'post',
              body: JSON.stringify(subscription),
              headers: UserService.authHeader()
            })
            this.isSubscribed = true
          })
        })
      }
    },
    unsubscribe () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration.pushManager.getSubscription().then(subscription => {
            subscription.unsubscribe().then(() => {
              fetch(UserService.baseUrl() + 'api/v1/user/subscribe', {
                method: 'post',
                body: JSON.stringify(subscription),
                headers: UserService.authHeader()
              })
              this.isSubscribed = false
            })
          })
        })
      }
    },
    onGetUserEventbus () {
      userEventBus.$on('userChanged', () => {
        this.getUsername()
      })
    },
    onOutingEventChanged () {
      outingEventBus.$on('eventChanged', () => {
        this.showBackToScoring = false // Hide the button on event change
      })
    },
    ifShowNavElement (url) {
      if (this.username) {
        return true
      }
      const publicPages = ['/login', '/register', '/visitors', '/contactUs', '/reset', '/handicapLookup', '/leaderboard']
      return publicPages.includes(url)
    },
    updateBackToScoringVisibility () {
      this.showBackToScoring = window.innerWidth < 992
    },
    toggleDropdown () {
      this.showScoreButton = !this.showScoreButton
    },
    onDropdownOpen () {
      this.showScoreButton = false
    },
    onDropdownClose () {
      this.showScoreButton = true
    },
    async goToCurrentOuting () {
      let code = await this.getLink()
      if (code) {
        this.$router.push('/leaderboard/' + code)
      } else {
        console.log('Unable to open leaderboard. The link is ' + code)
      }
    },
    async getLink () {
      let user = UserService.getUser()
      if (user) {
        let response = await TeetimeService.getAccessCode(user.id)
        if (response.status === 200) {
          return response.data.accesscode
        }
      }
      return null
    },
    async handleOutingInProgressChange (newVal, oldVal) {
      // Do something when outingInProgress changes
      console.log('Outing in progress changed from', oldVal, 'to', newVal)
      // You can add more logic here based on the new value of outingInProgress
    },
    startStatusCheckInterval () {
      if (!this.statusCheckInterval) {
        this.statusCheckInterval = setInterval(this.updateOutingInProgress, 20000)
        console.log('Outing InProgress has started')
      }
    },
    stopStatusCheckInterval () {
      if (this.statusCheckInterval) {
        clearInterval(this.statusCheckInterval)
        this.statusCheckInterval = null
        console.log('Outing InProgress has stopped')
      }
    }
  },
  watch: {
    outingInProgress: 'handleOutingInProgressChange'
  },
  mounted () {
    this.getUsername()
    this.onGetUserEventbus()
    this.onOutingEventChanged()

    // Add resize event listener
    window.addEventListener('resize', this.updateBackToScoringVisibility)

    // Initial check for mobile view
    this.updateBackToScoringVisibility()
  },
  beforeDestroy () {
    // Remove resize event listener
    window.removeEventListener('resize', this.updateBackToScoringVisibility)
    // Clear interval when component is about to be destroyed
    this.stopStatusCheckInterval()
  }
}
</script>

<style scoped>
.nav-location {
  position: fixed;
  z-index: 100;
  top: 0px;
  width: 100%;
}

.green-button {
  background-color: #19a020;
  color: white;
  border: none;
  margin: 5px;
  font-size: 12px;
  z-index: 10;
}

.back-to-scoring {
  margin-right: 5px;
  position: absolute;
  right: 80px;
}

.b-navbar-nav {
  z-index: 20;
}

.active {
  border-bottom: 1px solid grey;
  font-weight: bold;
}

.navItem {
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid transparent;
}

.navItem:hover {
  border-bottom: 1px solid black;
}

@media (max-width: 992px) {
  .ml-auto .navItem:first-child {
    order: 1;
  }

  .ml-auto .navItem:last-child {
    order: 2;
  }
}
</style>
