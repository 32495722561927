<style>
.btnWidth {
  width: 150px;
}
</style>

<template>
  <div class="card col-12 col-md-5 mx-auto full-height">
    <div class="card-body">
      <h5 class="card-title text-center">Your Email is :</h5>
      <p class="card-text text-center"> {{user.email}}</p>
      <br>
      <div class="text-center">
        <button
          class="btn btn-primary btnWidth"
          @click="toGeneratePwd()"
        >Send Password</button>
      </div>
    </div>
    <hr>
    <div class="card-body">
      <form @submit.prevent="handleSubmit()">
        <h5 class="card-title">New Email:</h5>
        <p class="card-text">
          <input
            type="email"
            class="form-control"
            v-model="email"
            id="email"
            placeholder="Email Addr"
          >
        </p>
        <br>
        <div class="text-center">
          <button
            type="submit"
            class="btn btn-primary btnWidth"
          >Change Email Addr and Send Password</button>
        </div>
      </form>
      <br>
      <p
        class="alert alert-danger"
        role="alert"
        v-if="errors.length"
      >
        <b>Please correct the following error(s):</b>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >{{ error }}</li>
        </ul>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChooseResetType',
  data: function () {
    return {
      errors: [],
      email: null
    }
  },
  props: {
    user: {

    }
  },
  methods: {
    handleSubmit: function () {
      this.errors = []
      if (!this.email) {
        this.errors.push('Email Required.')
      }
      // if (!this.validEmail(this.email)) {
      //   this.errors.push('Valid email required.')
      // }
      if (!this.errors.length) {
        this.$emit('sendNewEmail', {
          email: this.email,
          username: this.user.username
        })
      }
    },
    validEmail: function (email) {
      // var re = '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'
      // return re.test(email)
    },
    toGeneratePwd () {
      this.$emit('sendPwd', {
        username: this.user.username
      })
    }
  }
}
</script>
