import CourseService from '@/services/CourseService'

const getDefaultState = () => {
  return {
    // for search results, get course, pages,
    courseInfo: {},
    // selected courses
    selectedCourses: [],
    options: {
      greenConditions: ['N/A', 'Bad', 'Fair', 'Good', 'Immaculate'],
      greenSpeeds: ['N/A', 'Slow', 'Medium', 'Quick', 'Lightening'],
      fairwayConditions: ['N/A', 'Bad', 'Fair', 'Good', 'Immaculate'],
      valueRatings: ['N/A', 'Ripoff', 'Fair', 'Good', 'Smmmokin!']
    }
  }
}

const state = getDefaultState()

// notice : actions handle asynchronous data
const actions = {
  async searchCourse (context, filters) {
    const response = await CourseService.search(filters)
    context.commit('setCourses', response)
    return response
  },

  reset ({ commit }) {
    commit('resetState')
  }
}
// notice : mutation only handle synchronous data
const mutations = {
  setCourses (state, response) {
    state.courseInfo = response.data.courseInfo
  },
  setSelectedCourses (state, courses) {
    state.selectedCourses = courses
  },
  resetState: (state) => {
    Object.assign(state, getDefaultState())
  }
}
const getters = {
  coursesInfo: (state) => {
    return state.courseInfo
  },
  selectedCourses: (state) => {
    return state.selectedCourses
  },
  options: (state) => {
    return state.options
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
