import { render, staticRenderFns } from "./GolferList.html?vue&type=template&id=38ceb2e8&scoped=true&external"
import script from "./GolferList.vue?vue&type=script&lang=js"
export * from "./GolferList.vue?vue&type=script&lang=js"
import style0 from "./GolferList.scss?vue&type=style&index=0&id=38ceb2e8&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38ceb2e8",
  null
  
)

export default component.exports