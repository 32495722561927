<style scoped>
</style>

<template >
  <div class="row mt-4">
    <div class="col-12 mx-auto align-middle">
      <CourseForm
        :course="course"
        @sendForm="onGetForm($event)"
      ></CourseForm>
    </div>
  </div>
</template>

<script>
import CourseForm from '@/components/courseForm/CourseForm'
import CourseService from '@/services/CourseService'

export default {
  name: 'Directory',
  components: {
    CourseForm
  },
  data: function () {
    return {
      prevRoute: null,
      course: {
        teeboxes: [],
        holes: []
      }
    }
  },
  methods: {
    onGetForm: function (data) {
      this.$updateloading(1)

      CourseService.addCourse(data)
        .then(res => {
          this.$updateloading(-1)
          this.$router.push({ name: this.$route.params['prevRoute'] }, () => {
            this.$notify({
              group: 'notices',
              title: 'Successfully Added Course',
              text: '',
              type: 'info'
            })
          })
        })

        .catch(errors => {
          this.$updateloading(-1)
          this.$notify({
            group: 'errors',
            title: 'Unable to Add Course',
            text: errors,
            type: 'error'
          })
        })
    }
  }
}
</script>
