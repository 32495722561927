
<style lang="scss" src='./HandicapDetail.scss' scoped>
</style>

<template src='./HandicapDetail.html'>
</template>

<script>
import GolferService from '@/services/GolferService'
import GolferSelector from '@/views/handicapLookup/components/GolferSelector'
import RoundService from '@/services/RoundService'
import RoundsTable from '@/views/handicapLookup/components/roundsTable/RoundsTable'
import moment from 'moment'
import DetailFilter from '@/components/detailFilter/DetailFilter'
import AimCommonMixin from '@/mixins/aim.common.mixin'
import RoundsSummaryTable from '@/views/handicapLookup/components/roundsSummaryTable/RoundsSummaryTable'

export default {
  name: 'HandicapDetail',
  mixins: [AimCommonMixin],
  components: {
    RoundsTable,
    DetailFilter,
    GolferSelector,
    RoundsSummaryTable
  },
  data: function () {
    return {
      golfers: [],
      roundReviews: null,
      roundsInfo: {},
      ifShowFilter: false,
      filters: {
        user_id: null,
        sort: 'handicap',
        desc: false,
        size: 10,
        detailType: null
      },
      roundReviewFilters: {
        sort: 'date',
        desc: true,
        size: 8
      },

      render: true
    }
  },
  mounted () {
    this.getGolfers()
    this.setinitRouteParams()
    this.showFilterBasingRoute()
    this.searchRounds()
    this.getRoundReviews()
  },
  methods: {
    getRoundsTableTitle () {
      switch (this.filters.detailType) {
        case 'usga':
          return 'Best 8 rounds with lowest differential of last 20 rounds'
        case 'tournament-handicap':
          return 'Best 8 tournament rounds with lowest differential of last 12 months'
        case 'total-rounds-posted':
          return this.getSearchTitle()
        case 'anti-handicap':
          return 'Worst 8 rounds with highest differential of last 20 rounds'
        case 'round-posted-past-year':
          return this.getSearchTitle()
        case 'avg':
          return 'Last 20 rounds'
        default:
          return null
      }
    },
    getSearchTitle: function () {
      let lastMonth = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD')
      let lastYear = moment(new Date()).subtract(12, 'months').format('YYYY-MM-DD')
      if (this.filters.start_date === lastMonth) {
        return 'Total Rounds Posted: Last Month'
      } else if (this.filters.start_date === lastYear) {
        return 'Total Rounds Posted: Last Year'
      } else {
        let from = this.filters.start_date ? ' From ' + this.filters.start_date : ''
        let to = this.filters.end_date ? ' To ' + this.filters.end_date : ''
        return 'Search Rounds :' + (from + to ? from + to : ' All')
      }
    },
    setinitRouteParams: function () {
      this.filters.user_id = +this.$route.params['uId']
      this.filters.detailType = this.$route.params['type']
      if (this.filters.detailType === 'round-posted-past-year') {
        let today = new Date()
        let lastYear = moment(today).subtract(12, 'months').format('YYYY-MM-DD')
        this.filters.start_date = lastYear
        this.filters.end_date = moment(today).format('YYYY-MM-DD')
        // if want to view last year rounds, sort by date DESC first
        this.filters.sort = 'date'
        this.filters.desc = true
      }
      if (this.filters.detailType === 'avg') {
        this.filters.size = 20
        this.filters.sort = 'date'
        this.filters.desc = true
        this.filters.hidepag = true
      }
    },
    showFilterBasingRoute: function () {
      let routesDontShowFilter = ['usga', 'tournament-handicap', 'anti-handicap', 'avg']
      if (routesDontShowFilter.includes(this.filters.detailType)) {
        this.ifShowFilter = false
      } else {
        this.ifShowFilter = true
      }
    },
    getGolfers: function () {
      this.$updateloading(1)
      GolferService.getGolfers().then(response => {
        this.golfers = response.data.golfers
        this.$updateloading(-1)
      }).catch(errors => {
        this.$updateloading(-1)
      })
    },
    searchRounds: function () {
      this.$updateloading(1)
      setTimeout(() => {
        document.activeElement.blur()
      }, 1000)
      RoundService.searchRounds(this.SerializeFiltersMixin(this.filters)).then((resp) => {
        this.roundsInfo = resp.data.rounds
        this.$updateloading(-1)
      }).catch(errors => {
        console.log(errors)
        this.$updateloading(-1)
      })
    },
    onGetGolfer: function (golferId) {
      this.$router.push({ name: 'Handicap Detail', params: { uId: +golferId } })
    },
    onGetFilters: function (filters) {
      this.filters = filters
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i] == null) {
          delete this.filters[i]
        }
      }
      this.searchRounds()
    },
    onGetNavTo: function (type) {
      this.$router.push({ path: `/handicapDetail/${+this.filters.user_id}/${type}` })
    },
    getRoundReviews () {
      RoundService.getUserRoundsReviews(this.filters.user_id, this.SerializeFiltersMixin(this.roundReviewFilters)).then((resp) => {
        this.roundReviews = resp.data.rounds_reviews
      })
    }
  },
  watch: {
    // react to route changes...
    $route (to, from) {
      this.setinitRouteParams()
      this.showFilterBasingRoute()
      this.searchRounds()
      this.getRoundReviews()
    }
  }
}
</script>
