<template>
  <div class="card mb-1 alert-danger">
    <div class="card-body">
      <h5 class="card-title">{{title}}</h5>
      <p class="card-text">
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >{{ error }}</li>
        </ul>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default () {
        return 'There is an Error!'
      }
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped>
</style>
