import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import info from './info.module'
import course from './course.module'
import round from './round.module'
import teebox from './teebox.module'
import userInfo from './userInfo.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    info,
    course,
    round,
    teebox,
    userInfo
  },
  plugins: [createPersistedState()]
})
