<style lang="scss" scoped>
</style>

<template>
  <AimCenterModal>
    <template v-slot:header>
      <h5
        class="modal-title"
        id="modalLongTitle"
      >Do you want to select course? </h5>
    </template>
    <template v-slot:body>
      Name : <span v-if="course && course.name">{{course.name}}</span>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
      >No</button>
      <button
        type="button"
        class="btn btn-primary"
        data-dismiss="modal"
        @click.prevent="confirm()"
      >Yes</button>
    </template>
  </AimCenterModal>
</template>

<script>
import AimCenterModal from '@/components/Aim/AimCenterModal'
export default {
  name: 'SelectCourseModal',
  components: {
    AimCenterModal
  },
  props: {
    course: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  methods: {
    confirm () {
      this.$emit('updateConfirm', true)
    }
  }
}
</script>
