<style scoped>
</style>

<template >
  <div class="row mt-4">
    <div class="col-12 mx-auto align-middle">
      <form
        class="form"
        name="signupForm"
        @submit.prevent="register"
        v-if="!submitted"
      >
        <div class="card mb-3 text-center">
          <div class="row card-body">
            <div class="col">
              <h2>{{ tournament.date | momentformat('MM/DD dddd') }} {{ tournament.time | removeseconds }}</h2>
              <h3>{{ tournament.contactinfo }}</h3>
              <p>We are always striving to make the tournaments as fair and competitive as possible. Please assist us with this goal by
                taking a minute to fill out the following form to the best of your knowledge. Thank You!</p>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-body">
            <div class="row form-group" v-if="admin && selectedGolferId && golfers">
              <label
                class="control-label col-sm-3 text-sm-right"
                for="name"
              >Golfer:</label>
              <div class="col col-md-3">
                <GolferSelector
                  :selectedGolfer="selectedGolferId"
                  :golfers="golfers"
                  @updateGolfer="getGolferInfo($event)"
                ></GolferSelector>
              </div>
            </div>
            <div class="row form-group">
              <label
                class="control-label col-sm-3 text-sm-right"
                for="name"
              >Name:</label>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  v-model="signup.name"
                  required
                >
              </div>
            </div>
            <div class="row form-group">
              <label
                class="control-label col-sm-3 text-sm-right"
                for="email"
              >Email:</label>
              <div class="col w-100">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="signup.email"
                  required
                >
                <span class="ml-3">
                  <input
                    type="checkbox"
                    v-model="signup.notify"
                  > Send Email Notification?
                </span>
              </div>
            </div>
            <div class="row form-group">
              <label
                class="control-label col-sm-3 text-sm-right"
                for="phone"
              >Phone:</label>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  name="phone"
                  v-model="signup.phone"
                >
              </div>
            </div>
            <div class="row form-group">
              <label
                class="control-label col-sm-3 text-sm-right"
                for="handicap"
              >USGA Index or Estimated Handicap:</label>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  name="handicap"
                  v-model="signup.handicap"
                  required
                >
              </div>
            </div>
            <div class="row form-group">
              <label
                class="control-label col-sm-3 text-sm-right"
                for="membershipid"
              >GHIN (if known):</label>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  name="membershipid"
                  v-model="signup.membership_id"
                >
              </div>
            </div>
            <div class="row form-group w-100">
              <div class="col text-center"><strong>Tournament Fee: ${{ tournament.rate }}</strong></div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-header">
            <h4 class="text-center">Last Five Rounds</h4>
          </div>
          <table class="card-body table table-sm table-bordered table-striped w-auto mx-auto">
            <thead>
              <tr>
                <th>Course</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(round, ridx) in signup.lastrounds"
                :key="ridx"
              >
                <td><input
                    type="text"
                    class="form-control"
                    v-model="round.course"
                  ></td>
                <td><input
                    type="text"
                    class="form-control"
                    v-model="round.score"
                    size="3"
                  ></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card mb-3">
          <div class="card-body row">
            <div class="col">
              <p class="text-center">What was your best score ever?</p>
              <div class="form-group row">
                <label class="control-label col-4 col-lg-2 text-right"><i>Course:</i></label>
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="signup.course_best"
                  >
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label col-4 col-lg-2 text-right"><i>Score:</i></label>
                <div class="col">
                  <input
                    type="text"
                    v-model="signup.score_best"
                    class="form-control"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-body row">
            <div class="col">
              <p class="text-center">If you crushed one off the tee under normal conditions, how many yards would it go?</p>
              <div class="form-group row">
                <label class="control-label col-4 col-lg-2 text-right"><i>Score:</i></label>
                <div class="col">
                  <input
                    type="text"
                    v-model="signup.crunch"
                    class="form-control"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-body row">
            <div class="col">
              <p class="text-center">Please provide the names and/or email addresses of your intended playing partners or any special requests you may have:</p>
              <textarea
                v-model="signup.foursome"
                class="form-control"
              ></textarea>
              <p class="text-danger text-center">Although you may have listed your partners, please register any partner or each golfer in your group separately.</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col text-center">
            <button
              class="btn btn-primary"
              type="submit"
            >Sign Up</button>
          </div>
        </div>
      </form>

      <div v-if="submitted">
        <div class="card mb-3">
          <div class="card-header">
            <h4 class="text-center">Signup Confirmation</h4>
          </div>
          <div class="card-body row px-0">
            <div class="col text-center">
              <p><strong>{{ tournament.tournamenttitle }}</strong></p>
              <p><strong>{{ tournament.date | momentformat('MM/DD dddd') }}</strong> at <strong>{{ tournament.time | removeseconds }}</strong></p>
              <p><span v-show="signup.notify && signup.email">A confirmation email has been sent to <b>{{ signup.email }}</b>. </span>
                No further confirmation is necessary and you are expected to show at least twenty minutes prior to the starting time.
                If we are not using a shotgun start and if you are in a later starting time, we will make every effort possible to contact
                you as a courtesy. If you have to cancel, please contact as soon as possible. No shows can negatively affect future fees
                and participation.</p>
              <div v-show="tournament.prepay">
                <p v-show="tournament.deadline == '0000-00-00'">You're welcome to take care of greens fees at the course or you can prepay in one of the following ways:</p>
                <p v-show="tournament.deadline != '0000-00-00'">This event has a payment deadline of {{ tournament.deadline }}. If payment is not received by {{ tournament.deadline }}, your entry will be cancelled.</p>
                <ul class="mx-0 text-left">
                  <li class="mb-3">Check or money order made payable to:<br />
                    <br />
                    Desert Nomads Golf Club<br>
                    c/o AIM IT Services<br />
                    4720 E COTTON GIN LOOP STE 135<br />
                    Phoenix, AZ 85040
                  </li>
                  <li class="mb-3">Paypal (or credit card) to <a :href="'https://www.paypal.me/nomads/' + tournament.rate">paypal.me/nomads</a></li>
                  <li>You can use Venmo as well, Sending money to: @Mark-Slovensky-1</li>
                </ul>
              </div>
              <p>Thank you for your participation. If you have any questions, please email:<br>
                <a href="mailto:outings@desertnomads.org">outings@desertnomads.org</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeetimeService from '@/services/TeetimeService'
import UserService from '@/services/UserService'
import GolferService from '@/services/GolferService'
import GolferSelector from '@/views/handicapLookup/components/GolferSelector'

export default {
  name: 'TourneySignup',
  components: {
    GolferSelector
  },
  data: function () {
    return {
      admin: false,
      golfers: [],
      selectedGolferId: null,
      submitted: false,
      tournament: {},
      golfer: {},
      signup: {
        user_id: 0,
        name: null,
        email: null,
        phone: null,
        notify: true,
        handicap: 0.0,
        membership_id: null,
        lastrounds: [
          { course: null, score: null },
          { course: null, score: null },
          { course: null, score: null },
          { course: null, score: null },
          { course: null, score: null }
        ],
        course_best: null,
        score_best: null,
        crunch: null,
        foursome: ''
      }
    }
  },
  props: {
    tId: {
      type: [String, Number],
      default: 0
    },
    anonymous: {
      type: Boolean,
      default: false
    }
  },
  created () {
    var user = UserService.getUserObject()
    if (user && user.user && user.user.id) {
      this.admin = +user.user.isadmin === 1
      if (this.admin) {
        this.selectedGolferId = +user.user.id
        this.getGolfers()
      }
      if (!this.anonymous) {
        this.getGolferInfo(user.user.id)
      }
    }
    this.getTeetimeTourneyInfos()
  },
  methods: {
    getGolfers: function () {
      this.$updateloading(1)
      GolferService.getGolfers().then(response => {
        this.golfers = response.data.golfers
        this.$updateloading(-1)
      }).catch(errors => {
        this.$updateloading(-1)
      })
    },
    getGolferInfo: function (userId) {
      this.$updateloading(1)
      GolferService.getGolfer(+userId)
        .then(res => {
          this.$updateloading(-1)
          this.golfer = res.data.golfer
          this.initializeSignupForm()
        })
        .catch(errors => {
          this.$updateloading(-1)
          console.log(errors)
        })
    },
    getTeetimeTourneyInfos: function () {
      this.$updateloading(1)
      TeetimeService.getTeetimeTourneyPlayers(+this.tId)
        .then(res => {
          this.$updateloading(-1)
          this.tournament = res.data.teetime
        })
        .catch(errors => {
          this.$updateloading(-1)
          console.log(errors)
        })
    },
    initializeSignupForm: function () {
      if (this.golfer && this.golfer.id) {
        this.signup.name = this.golfer.firstname + ' ' + this.golfer.lastname
        this.signup.email = this.golfer.email
        this.signup.phone = this.golfer.phonenum
        this.signup.handicap = this.golfer.handicapindex
        this.signup.user_id = this.golfer.id
        this.signup.membership_id = this.golfer.aga
        if (this.golfer.lastrounds && this.golfer.lastrounds.length > 0) {
          this.golfer.lastrounds.forEach((round, idx) => {
            this.signup.lastrounds[idx].course = round.name
            this.signup.lastrounds[idx].score = round.score
          })
        }
        if (this.golfer.bestscore) {
          this.signup.course_best = this.golfer.bestscore.name
          this.signup.score_best = this.golfer.bestscore.score
        }
      }
    },
    register () {
      this.$updateloading(1)
      if (!this.signup.phone || this.signup.phone === null) {
        this.signup.phone = ''
      }
      TeetimeService.signup(+this.tId, this.signup)
        .then(res => {
          this.$updateloading(-1)
          if (res && res.data && res.data.result === 'Success') {
            this.submitted = true
            this.$emit('submitted')
          } else {
            alert('We failed to save your registration. Please try again.')
            this.submitted = false
          }
          console.dir(res.data)
        })
        .catch(errors => {
          this.$updateloading(-1)
          alert(errors.message)
          console.dir(errors)
        })
    }
  }
}
</script>
