<style lang='scss' src='./GolferFilter.scss' scoped>
</style>

<template src='./GolferFilter.html'>

</template>

<script>
import CollapseBtn from '@/components/CollapseBtn'
import AimCommonMixin from '@/mixins/aim.common.mixin'

export default {
  components: {
    CollapseBtn
  },
  mixins: [AimCommonMixin],
  props: {
    title: {
      type: String,
      default () {
        return 'Find Golfer'
      }
    },
    filters: {
      type: Object,
      default: function () {
        return {
          firstname: null
        }
      }
    }
  },
  data: function () {
    return {
      isCollapsed: false
    }
  },
  computed: {
    isLoading () {
      return this.$isLoading()
    },
    firstnameLengthLargeThree: function () {
      if (!this.filters.firstname) {
        return true
      }
      if (this.filters.firstname.length < 3) {
        return true
      }
      return false
    }
  },
  watch: {
    'filters.firstname': function (newVal) {
      if (newVal && newVal.length > 2) {
        this.onClickApply()
      }
    }
  },
  methods: {
    onClear () {
      this.filters.firstname = null
      this.onClickApply()
    },
    onClickApply () {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
      // hide the phone keyboard
      setTimeout(() => {
        document.activeElement.blur()
      }, 1000)
      let copyFilterObj = JSON.parse(JSON.stringify(this.filters))
      let filterWithoutNullValue = this.getObjWithoutNullValueMixin(copyFilterObj)
      this.$emit('updateFilter', filterWithoutNullValue)
    },
    onGetCollapse: function (collapse) {
      this.isCollapsed = collapse
    }
  }
}
</script>
