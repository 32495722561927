import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UserService from '@/services/UserService'

const CourseService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = UserService.baseUrl()
    // this.setHeader()
  },

  setHeader () {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${UserService.getToken()}`
  },

  getCourses () {
    this.setHeader()
    return Vue.axios.get(`course/all`).catch(error => {
      throw new Error(`[RWV] CourseService ${error}`)
    })
  },

  getCourseInfo (courseId) {
    this.setHeader()
    return Vue.axios.get(`course/${courseId}`).catch(error => {
      throw new Error(`[RWV] CourseService ${error}`)
    })
  },

  search (query) {
    this.setHeader()
    return Vue.axios.get(`course/search?${query}`).catch(error => {
      throw new Error(`[RWV] CourseService ${error}`)
    })
  },

  addCourse (data) {
    this.setHeader()
    return Vue.axios.post(`course/add`, data).catch(error => {
      throw new Error(`${error.response.data.error}`)
    })
  },

  editCourse (data) {
    this.setHeader()
    return Vue.axios.post(`course/edit`, data).catch(error => {
      throw new Error(`[RWV] CourseService ${error}`)
    })
  },

  editCourseTeebox (data) {
    this.setHeader()
    return Vue.axios.patch(`course/editTeebox`, data).catch(error => {
      throw new Error(error.response.data.error)
    })
  },

  getCourseCities (query) {
    this.setHeader()
    return Vue.axios.get(`course/city`).catch(error => {
      throw new Error(`[RWV] CourseService ${error}`)
    })
  }
}

export default CourseService
