
<style scoped src="./MemberCard.css"></style>

<template src='./MemberCard.html'></template>

<script>
import CollapseBtn from '@/components/CollapseBtn'
export default {
  components: {
    CollapseBtn
  },
  props: {
    member: null
  },
  data: function () {
    return {
      isCollapsed: true
    }
  },
  methods: {
    onGetCollapse: function (collapse) {
      this.isCollapsed = collapse
    }
  }
}
</script>
