<template>
  <div>
    <nav-bar></nav-bar>

    <div class='container mt-4 mb-4'>
      <div class='row'>
        <div class='col-8'>
          <slot />
        </div>

        <div class='col-4'>
          <side-bar></side-bar>
        </div>
      </div>
      <slot />
    </div>

    <footer-bar></footer-bar>
  </div>
</template>

<script>
// '@' sign means src
import NavBar from '@/components/NavBar'
import SideBar from '@/components/SideBar'
import FooterBar from '@/components/FooterBar'

export default {
  components: {
    NavBar,
    SideBar,
    FooterBar
  }
}
</script>

<style scoped>

</style>
