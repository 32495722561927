import { render, staticRenderFns } from "./SelectCourseModal.vue?vue&type=template&id=01717585&scoped=true"
import script from "./SelectCourseModal.vue?vue&type=script&lang=js"
export * from "./SelectCourseModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01717585",
  null
  
)

export default component.exports