<template>
  <div>
    <h1>Page Not Found</h1>
    <p>The page you are looking for does not exist. Please go back, or select an item from the menu.</p>
  </div>
</template>

<script>
export default {

}
</script>
