import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UserService from '@/services/UserService'

const TeeboxService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = UserService.baseUrl()
    // this.setHeader()
  },

  setHeader () {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${UserService.getToken()}`
  },

  searchTeebox (data) {
    return Vue.axios.post(`teebox/search`, data).catch(error => {
      throw new Error(`[RWV] TeeboxService ${error}`)
    })
  }

}

export default TeeboxService
