<style lang="scss" scoped>
.wrapper {
  display: inline-block;
}
div i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>

<template>
  <div class="wrapper align-top">
    <!-- Button trigger modal -->
    <i
      class="badge badge-primary  "
      data-toggle="modal"
      :data-target="`#email${emailId}`"
    >
      <span
        v-if="email"
        class="far fa-envelope"
      ></span>
    </i>

    <!-- Modal -->
    <div
      class="modal fade"
      :id="`email${emailId}`"
      tabindex="-1"
      role="dialog"
      aria-labelledby="email"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <!-- modal header -->
          <div class="modal-header">
            <h5
              class="modal-title"
              id="email"
            >{{title}}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- modal body -->
          <div class="modal-body">
            Email : <b>{{email}}</b>
          </div>

          <!-- modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >Close</button>
            <button
              @click="confirm()"
              type="button"
              data-dismiss="modal"
              class="btn btn-primary"
            >Send Email</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: String,
    subject: String,
    title: {
      type: String,
      default: function () {
        return 'Do you want to send Email ?'
      }
    }
  },
  computed: {
    emailId: function () {
      return '_' + Math.random().toString(36).substr(2, 9)
    }
  },
  methods: {
    confirm: function () {
      window.location = 'mailto:' + this.email + this.getEmailSubject()
    },
    getEmailSubject () {
      if (!this.subject) {
        return ''
      } else {
        return ' ?subject=' + this.subject
      }
    }
  }
}
</script>
