<style scoped>
</style>

<template >
  <div class="row">
    <div class="col-12 mx-auto align-middle">
      <!-- title -->
      <div class="card mb-1 table-title">
        <div class="card-header text-center">
          <i
            class="fa fa-arrow-circle-left back-icon"
            @click="$router.go(-1)"
          ></i>
          <b>Final Step : Round Review</b>
        </div>
      </div>
    </div>
    <!-- for mobile -->
    <div class="col-12 p-3">
      <div class="card">
        <div class="card-header">
          Round Information
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4 col-md-2">
              Course:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.course && round.course.name ? round.course.name : '' }}
              </b>
            </div>
            <div class="col-4 col-md-2">
              Region:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ selectedRegion && selectedRegion.region ? selectedRegion.region : '' }}
              </b>
            </div>
          </div>

          <div class="row">
            <div class="col-4 col-md-2">
              Date:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.date ? round.date : '' }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
    class="col-12 p-3"
    v-if="round"
    >
      <div
        class="card"
      >
        <div class="card-header">
          {{ (user && user.firstname ? user.firstname : '') + ' ' + (user && user.lastname ? user.lastname : '') }}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4 col-md-2">
              Adj Score:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.score ? round.score : ''}} {{round && round.tournament && round.tournament == 1 ? 'T' : '' }}
              </b>
            </div>
            <div class="col-4 col-md-2">
              Tee:
            </div>
            <div class="col-8 col-md-2 text-right "
              v-if="selectedTeebox !== undefined"
            >
              <b>
                {{ selectedTeebox.description ? selectedTeebox.description : '' }}
              </b>
            </div>
            <div class="col-4 col-md-2">
              Holes Par
              or Better:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.parorbetter ? round.parorbetter : '' }}
              </b>
            </div>
            <div class="col-4 col-md-2">
              Alcohol:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.alcohol ? getAlcoholConsumption(round.alcohol) : 'None' }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-12 p-3"
      v-if="round && round.golfers && round.golfers.length"
    >
      <div class='card'>
        <div class="card-header">
          Other Golfers Information
        </div>
      </div>
      <div
        class="card"
        v-for="(user ,index) in round.golfers"
        :key='index'
      >
        <div class="card-header">
          {{ (user.firstname && user.firstname ? user.firstname : '') + ' ' + (user.lastname && user.lastname ? user.lastname : '') }}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4 col-md-2">
              Adj Score:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ user && user.score ? user.score : '' }} {{ user && user.tournament && user.tournament == 1 ? 'T' : '' }}
              </b>
            </div>
            <div class="col-4 col-md-2">
              Tee:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ user ? getTeebox(user) : '' }}
              </b>
            </div>
            <div class="col-4 col-md-2">
              Holes Par
              or Better:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ user && user.parorbetter ? user.parorbetter : '' }}
              </b>
            </div>
            <div class="col-4 col-md-2">
              Alcohol:
            </div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ user && user.alcohol ? getAlcoholConsumption(user.alcohol) : 'None' }}
              </b>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-12 p-3">
      <div class="card">
        <div class="card-header">Course Conditions</div>
        <div class="card-body">
          <div class="row">
            <div class="col-4 col-md-2">Date:</div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.date ? round.date : '' }}
              </b>
            </div>
          </div>
          <div
            class="row"
            v-if="round && round.course && round.course.coursecom && round.course.coursecom != ''"
          >
            <div class="col-4 col-md-2">Comment:</div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round.course.coursecom }}
              </b>
            </div>
          </div>
          <div class="row">
            <div class="col-4 col-md-2">Fairways:</div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.course && round.course.fairwaycondition ? getOptionName('fairwayConditions', round.course.fairwaycondition) : '' }}
              </b>
            </div>
          </div>
          <div class="row">
            <div class="col-4 col-md-2">Greens:</div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.course && round.course.greenscondition ? getOptionName('greenConditions', round.course.greenscondition) : '' }}
              </b>
            </div>
          </div>
          <div class="row">
            <div class="col-4 col-md-2">Green Speed:</div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.course && round.course.greensspeed ? getOptionName('greenSpeeds', round.course.greensspeed) : '' }}
              </b>
            </div>
          </div>
          <div class="row">
            <div class="col-4 col-md-2">Value:</div>
            <div class="col-8 col-md-2 text-right ">
              <b>
                {{ round && round.course && round.course.value ? getOptionName('valueRatings', round.course.value) : '' }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group col-12">
      <div class="row">
        <div class="col-6">
          <button
            type="button"
            style="width:100%"
            class="btn btn-primary"
            @click="onSubmitForm()"
          >Save Scores</button>
        </div>
        <div class="col-6">
          <button
            type="button"
            style="width:100%"
            class="btn btn-info"
            @click="cancelPostRound()"
          >Cancel</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AimCommonMixin from '@/mixins/aim.common.mixin'
import AimActionMixin from '@/mixins/aim.action.mixin'
import RoundService from '@/services/RoundService'
import UserService from '@/services/UserService'

export default {
  name: 'PostRoundReview',
  mixins: [AimCommonMixin, AimActionMixin],
  data () {
    return {
      errors: [],
      user: null
    }
  },
  computed: {
    // use getter to get => user module's => coursesInfo getter
    ...mapGetters({
      round: 'round/round',
      selectedUsers: 'userInfo/selectedUsers',
      selectedRegion: 'round/roundRegion',
      selectedTeebox: 'round/roundTeebox',
      teeboxes: 'teebox/teeboxes',
      options: 'course/options'
    })
  },
  created () {
    this.user = UserService.getUser()
  },
  methods: {
    ...mapActions([
      'round/reset', 'userInfo/reset', 'course/reset', 'teebox/reset'
    ]),
    getTeebox: function (user) {
      let teeboxname = ''
      for (let i = 0; i < this.teeboxes.length; i++) {
        if (user.teebox_id === this.teeboxes[i].id) {
          teeboxname = this.teeboxes[i].description
        }
      }
      return teeboxname
    },
    onSubmitForm: function () {
      this.$updateloading(1)
      RoundService.addRound(this.round)
        .then(res => {
          this.$updateloading(-1)
          // reset round
          this['round/reset']()
          this['userInfo/reset']()
          this['course/reset']()
          this['teebox/reset']()
          this.$router.push({ path: '/handicapLookup' })
        })
        .catch(errors => {
          this.$updateloading(-1)
          console.log(errors)
        })
    },
    cancelPostRound: function () {
      // reset round
      this['round/reset']()
      this['userInfo/reset']()
      this['course/reset']()
      this['teebox/reset']()
      this.$router.push({ path: '/home' })
    },
    getOptionName: function (optionSet, value) {
      var name = null
      if (this.options.hasOwnProperty(optionSet)) {
        this.options[optionSet].some(function (o, idx) {
          if (idx === +value) {
            name = o
            return true
          }
        })
      }
      return name
    },
    getAlcoholConsumption: function (val) {
      val += ''
      if (val === '1') {
        return 'Moderate'
      } else if (val === '2') {
        return 'Excessive'
      } else return 'None'
    }
  }
}
</script>
