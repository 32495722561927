<template>
  <div class="d-inline">
    <a href="javascript:void(0)" @click="showModal = true"><slot>Wolf Info</slot></a>
    <b-modal v-model="showModal" size="xl" hide-footer scrollable>
      <table class="text-center table-lines">
        <tr>
          <th>Player</th>
          <th>Wolf Score</th>
        </tr>
        <tr>
          <td>{{ holeScore.name }}</td>
          <td>{{ holeScore.wscore }}</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showModal: false
    }
  },

  computed: {
    holeScore () {
      const hole = parseInt(this.hole) - 1
      const tmp = this.scores['wolf_scores'][hole]
      return tmp
    }
  },
  props: {
    scores: Object,
    hole: {
      type: [String],
      default: '0'
    }
  }
}
</script>
<style lang="scss" scoped>
  .table-lines,.table-lines td,.table-lines th {
    border-collapse: collapse;
    border: 1px solid black;
    padding: 0.5rem 0;
  }

  table {
    width: 100%;
  }
</style>
