<style scoped>
.pagination {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.body {
  min-height: 75vh;
  margin-top: 77.5px;
}
@media screen and (max-width: 600px) {
  .body {
    min-height: 70vh;
    margin-top:77.5px;
  }
}
</style>

<template>
  <div style="min-height:100%;">
    <nav-bar></nav-bar>

    <div class="container-fluid body">
      <slot />
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
// '@' sign means src
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'

export default {
  components: {
    NavBar,
    FooterBar
  }
}
</script>
