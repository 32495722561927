<template>
  <div class="d-inline">
    <a href="javascript:void(0)" @click="showModal = true"><slot>Game Info</slot></a>

    <b-modal v-model="showModal" size="xl" hide-footer scrollable>
      <div v-if="+contest == 14">
        <b>Sixes</b><br>
        <span>
          A fourball game where 2 on 2 teams change partners every 6 holes for 3 matches of match play.
          In the event a match finishes early, those extra holes will be added onto the next match.
          The application will randomly pick partners for you.
        </span>
      </div>
      <div v-if="+contest == 16">
        <b>Stableford</b><br>
        <p>2 or more players</p>
        <p>You receive a number of points awarded on each hole based on your net strokes to par.</p>
        <p>Your goal to to score the most points based on scoring system below. 36 points would indicate you shot a net par.</p>
        <ul>
          <li>6 points &ndash; Four strokes under</li>
          <li>5 points &ndash; Three strokes under</li>
          <li>4 points &ndash; Two strokes under</li>
          <li>3 points &ndash; One stroke under</li>
          <li>2 points &ndash; Level par</li>
          <li>1 point<div style="display: inline-block; width: 7px;"></div> &ndash; One Stroke over</li>
          <li>0 points &ndash; Two strokes or more over</li>
        </ul>
      </div>
      <div v-if="+contest == 17 || +contest == 18">
        <b>Rabbit</b><br>
        <p>
          Hunt the rabbit. Only one player can catch it at a time or it gets loose.<br>
          Set your $prize between each other as to what the bounty is to be payed to the player who has the rabbit at the end of the round.<br>
          The first player who shoots the lowest net score on a hole with no ties (out right winner) catches the rabbit.<br>
          The other players are trying to release the rabbit from the owner. Shoot the lowest net score on a hole with no ties, and the rabbit is then loose.<br>
          Once the rabbit is loose again all players are able to catch the rabbit. Makes for some interesting closing holes.<br>
          If playing game every 9 holes, rabbit is released at end of 9 and is available to catch starting at 10. Otherwise 1 rabbit per 18 holes.
        </p>
      </div>
      <div v-if="contest == 'skin'">
        <b>Skins</b><br>
        <p>
          Skins are for a pre-determined about of on Money that make up the pot.<br>
          Single Lowest net or gross scores will win.<br>
          Skins won will be displayed -<br>
          Tied skins will be shown as to lowest ties
        </p>
      </div>
      <div v-if="+contest == 21">
        <p>
          Wolf:
          4 person game only<br>
          Be the player with the most points at the end of the round<br>
          Players rotate on every hole of who tees off first, second and third last player teeing off being the 'Wolf'<br>
          You will be the wolf every fourth hole.<br>

          Scoring:<br>
          Once each player in the group hits his or her tee shot, the Wolf decides whether or not to take any of the players on thier team for the hole.<br>
          If not, the Wolf plays the hole as the 'Lone Wolf' -- in which case the objective is to beat the three other players with the lowest net score on the hole.<br>
          Every hole is played as a net best ball with only the best score of each team being used.<br>
            - If the Wolf chose a partner and they win the hole, they each receive two points<br>
            - If the non-Wolf partners win the hole, they get three points apiece<br>
            - If the Lone Wolf beats all the other players, they receives four points<br>
            - If the Lone Wolf gets beat by any player in the group, everyone in the group except the Lone Wolf receives one point.<br>

          If wolf goes "Blind Wolf" declaring before the hole that you're going to play the hole alone without a partner before the tee shots are even hit.<br>
          If the Blind Wolf beats all the other players, they receives six points<br>
          If the Blind Wolf gets beat by any player in the group, everyone in the group except the Lone Wolf receives two point.<br>
        </p>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showModal: false
    }
  },

  props: {
    contest: {
      type: [String, Number],
      default: 0
    }
  }
}
</script>
