import numeral from 'numeral'
import Vue from 'vue'

// it can format number to
// 1. Currency
// 2. Other numbers format
// 3. Percentages
// 4. Time
// 5. Exponential
// 6. Bytes
// To SEE how to use:  Document -> http://numeraljs.com/
Vue.filter('formatNumber', function (value, format) {
  if (!format) {
    format = '0,0'
  }
  return numeral(value).format(format) // displaying other groupings/separators is possible, look at the docs
})
