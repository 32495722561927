<style>
.full-height {
  min-height: 60vh;
}
</style>

<template>
  <div class="card col-12 col-md-5 mx-auto full-height">
    <div style="width:100%" class="card-body">
      <form @submit.prevent="handleSubmit()">
        <div class="form-group">
          <label for="username">Enter Username</label>
          <input
            type="text"
            class="form-control"
            v-model="username"
            id="username"
            placeholder="username"
            required
          >
        </div>
        <vue-recaptcha
          sitekey="6LdLahwUAAAAAAn5KfN0KKVBWSv2VwA_voBGLfh9"
          @verify="getVerify($event)"
        ></vue-recaptcha>
        <br>
        <div style="width:100%">
          <button
            type="submit"
            class="btn btn-primary"
          >Continue</button>
        </div>
      </form>
      <br>
      <p class="alert alert-danger"
          role="alert" v-if="errors.length">
        <b>Please correct the following error(s):</b>
        <ul
        >
          <li
            v-for="(error, index) in errors"
            :key="index"
          >{{ error }}</li>
        </ul>
      </p>
    </div>
  </div>

</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
export default {
  name: 'CheckRobot',
  components: {
    VueRecaptcha
  },
  data: function () {
    return {
      errors: [],
      username: '',
      isRobot: true
    }
  },
  methods: {
    getVerify: function (res) {
      if (res) {
        this.isRobot = false
      }
    },
    handleSubmit: function () {
      if (this.username && !this.isRobot) {
        this.$emit('sendUsername', this.username)
        return true
      }
      this.errors = []
      if (!this.username) {
        this.errors.push('please enter username')
      }
      if (this.isRobot) {
        this.errors.push('plesase validate to pass robot check.')
      }
    }
  }
}
</script>
