<style src="./TeetimeForm.css" scoped>
</style>

<template src="./TeetimeForm.html">

</template>

<script>
import moment from 'moment'

export default {
  name: 'TeetimeForm',
  props: {
    isEdit: {
      type: Boolean,
      default () {
        return false
      }
    },
    teetime: {
      type: Object,
      default () {
        return {
        }
      }
    },
    courses: {
      type: Array
    },
    contests: {
      type: Array
    },
    user: {
      type: Object
    },
    teeboxes: {
      type: Array
    },
    hideSubmit: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      timeRange: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  },
  computed: {
    title: function () {
      return this.isEdit ? 'Edit Tee Time' : 'Add Tee Time'
    }
  },
  methods: {
    onSubmitForm () {
      this.setFormDay()
      if (this.isEdit) {
        this.$emit('updateTeetime', this.teetime)
      } else {
        this.$emit('addTeetime', this.teetime)
      }
    },
    onChangeCourse (evt) {
      if (!this.teetime.course_id) {
        this.teetime.teebox_id = null
      } else {
        this.$emit('onSearchTeeboxes', this.createSearchFilter())
      }
    },
    createSearchFilter () {
      return {
        course_id: {
          operator: '=',
          value: this.teetime.course_id
        }
      }
    },
    setFormDay () {
      this.teetime.day = moment(this.teetime.date).format('dddd')
    },
    numberInput (e, decimals = 0) {
      var test = new RegExp('^\\d*(\\.\\d{0,' + (decimals - 1) + '})?$')
      if (!/\d/.test(e.key) && e.key !== '.') {
        e.preventDefault()
      } else if (decimals === 0 && e.key === '.') {
        e.preventDefault()
      } else if (!test.test(e.target.value)) {
        e.preventDefault()
      } else if (e.key === '.' && e.target.value.indexOf('.') >= 0) {
        e.preventDefault()
      }
    }
  },
  mounted: function () {
  }
}
</script>
