<style>
</style>

<template>
  <div class="card col-12 col-md-5 mx-auto">
    <div class="card-body text-center">
      <p class="card-text">
        <b>New password has been sent. You can login in now from here.</b>
      </p>
      <router-link
        :to="`login`"
        class="btn btn-primary"
      >Try Login</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinishReset'
}
</script>
