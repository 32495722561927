<style scoped>
</style>

<template >
  <div class="row mt-4">
    <div class="col-12 mx-auto align-middle">
      <PlayersGeneralInfoCard
        :players="players"
        :totalSpots="totalSpots"
      ></PlayersGeneralInfoCard>
      <b-row>
        <b-col
          cols="6" offset="3"
          xl="4" offset-xl="4"
          class="text-center">
          <div class="my-4" v-if="isAdmin">
            <b-button block @click="addGolfer = true">Add Golfer</b-button>
          </div>
        </b-col>
      </b-row>

      <div>
        <PlayersTable
          :teeboxes="teeboxes"
          :players="players"
          :tournament="tournament"
        ></PlayersTable>
      </div>
    </div>
    <b-modal
      v-model="addGolfer"
      scrollable
      hide-footer
    >
      <TourneySignup v-if="addGolfer" :tId="+tId" anonymous @submitted="refresh()"></TourneySignup>
    </b-modal>
  </div>
</template>

<script>
import PlayersTable from '@/views/tourneyPlayers/components/PlayersTable'
import TeetimeService from '@/services/TeetimeService'
import PlayersGeneralInfoCard from '@/views/tourneyPlayers/components/PlayerGeneralInfoCard'
import TourneySignup from '@/views/tourneyPlayers/TourneySignup'
import UserService from '@/services/UserService'

export default {
  name: 'TourneyPlayers',
  components: {
    PlayersTable,
    PlayersGeneralInfoCard,
    TourneySignup
  },
  data: function () {
    return {
      players: [],
      teeboxes: {},
      tournament: {},
      totalSpots: 0,
      isAdmin: false,
      addGolfer: false
    }
  },
  props: {
    tId: {
      type: [String, Number],
      default: 0
    }
  },
  mounted () {
    this.getTeetimeTourneyInfos()
    const user = UserService.getUser()
    if (user && user.id && user.isadmin && +user.isadmin === 1) {
      this.isAdmin = true
    }
  },
  methods: {
    getTeetimeTourneyInfos: function () {
      this.$updateloading(1)
      TeetimeService.getTeetimeTourneyPlayers(+this.tId)
        .then(res => {
          this.$updateloading(-1)
          this.players = res.data.players
          this.teeboxes = res.data.teeboxes
          this.tournament = res.data.teetime
          this.totalSpots = +res.data.teetime.numwanted
        })
        .catch(errors => {
          this.$updateloading(-1)
          console.log(errors)
        })
    },

    refresh () {
      this.$notify({
        group: 'notices',
        title: 'Golfer Added',
        text: 'Successfully added golfer to the outing',
        type: 'info'
      })
      this.addGolfer = false
      this.getTeetimeTourneyInfos()
    }
  }
}
</script>
