import Vue from 'vue'

Vue.directive('aimCollapse', {
  // bind() is init the directive
  bind (el, binding, vnode) {
    if (binding.value.isInitCollapse) {
      el.classList.remove('fa-angle-up')
      el.classList.add('fa-angle-down')
    } else {
      el.classList.remove('fa-angle-down')
      el.classList.add('fa-angle-up')
    }
    el.addEventListener('click', (event) => {
      let classNames = Object.values(el.classList)
      let isCollapse = classNames.includes('fa-angle-down')

      if (isCollapse) {
        el.classList.remove('fa-angle-down')
        el.classList.add('fa-angle-up')
      } else {
        el.classList.remove('fa-angle-up')
        el.classList.add('fa-angle-down')
      }

      vnode.context.$emit('onClickCollapse',
        {
          index: binding.value.index,
          collapse: isCollapse
        })
    })
  }
})
