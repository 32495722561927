
<style lang='scss' scoped src='./CourseList.scss'>
</style>

<template src='./CourseList.html'>
</template>

<script>
import Page from '@/components/Page'
import CourseCard from '@/components/courseCard/CourseCard'
import AimCard from '@/components/Aim/AimCard'
import AimSelectedIcon from '@/components/Aim/icons/AimSelectedIcon'
import AimActionMixin from '@/mixins/aim.action.mixin'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AimCenterModal from '@/components/Aim/AimCenterModal'

export default {
  components: {
    Page,
    CourseCard,
    AimCard,
    AimSelectedIcon,
    AimCenterModal

  },
  mixins: [AimActionMixin],
  props: {
    courseInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return 'Courses'
      }
    },
    filters: {
      type: Object
    },
    postingRound: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      GreenConditionLevels: ['N/A', 'Bad', 'Fair', 'Good', 'Immaculate'],
      GreenSpeedLevels: ['N/A', 'Slow', 'Medium', 'Quick', 'Lightning'],
      FairwayConditionLevels: ['N/A', 'Bad', 'Fair', 'Good', 'Immaculate'],
      ValueLevels: ['N/A', 'Ripoff', 'Fair', 'Good', 'Smmmokin!']
    }
  },
  computed: {
    ...mapGetters({ selectedCourses: 'course/selectedCourses', teeboxes: 'teebox/teeboxes' })
  },
  methods: {
    ...mapMutations([
      'round/setRoundCourse'
    ]),
    ...mapActions([
      'teebox/searchTeeboxes'
    ]),
    changeSortTo: function (sort) {
      if (this.filters['sort'] === sort) {
        this.filters['desc'] = !this.filters['desc']
      } else {
        this.filters['sort'] = sort
        this.filters['desc'] = true
      }
      // reset page to 1 when sorting
      this.filters['page'] = 1
      this.sentFilters()
    },
    sentFilters: function () {
      this.$emit('updateFilters', this.filters)
    },
    onChangePageTo: function (page) {
      this.filters['page'] = page
      this.sentFilters()
    },
    onGetSortFromCard (sort) {
      this.changeSortTo(sort)
    },
    async selectCourse (course) {
      var filters = { course_id: { operator: '=', value: course.id } }
      await this['teebox/searchTeeboxes'](filters)
      this['round/setRoundCourse'](course)
      this.$router.push({ path: '/postRound/round' })
    }
  }
}
</script>
