<style src="./CourseForm.css" scoped>
</style>

<template src="./CourseForm.html">

</template>

<script>
import Error from '@/components/Error'
import AimStatesMixin from '@/mixins/aim.states.mixin'
import AimActionMixin from '@/mixins/aim.action.mixin'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CourseForm',
  components: {
    Error
  },
  mixins: [AimStatesMixin, AimActionMixin],
  props: {
    isEdit: {
      type: Boolean,
      default () {
        return false
      }
    },
    course: {
      type: Object,
      default () {
        return {
        }
      }
    }
  },
  data () {
    return {
      errors: []
    }
  },
  computed: {
    ...mapGetters({ regions: 'info/regions' }),
    title: function () {
      return this.isEdit ? 'Edit Course' : 'Add Course'
    },
    states: function () {
      return this.getStatesMixin()
    },
    isLoading () {
      return this.$isLoading()
    }
  },
  mounted: function () {
    // get regions
    this['info/getRegions']()
  },
  methods: {
    ...mapActions([
      'info/getRegions'
    ]),
    addTeebox: function () {
      if (this.course.teeboxes.length === 0) {
        this.course.teeboxes.push({
          'description': null,
          'rating': null,
          'slope': null
        })
        this.$forceUpdate()
      } else {
        this.errors = []
        this.validateTeeboxes()
        if (this.errors.length === 0) {
          this.course.teeboxes.push({
            'description': null,
            'rating': null,
            'slope': null
          })
          this.$forceUpdate()
        } else {
          alert('Resolve existing errors before adding a new teebox.')
        }
      }
    },
    addHole () {
      this.course.holes.push({
        'courseid': this.course.id,
        'hole': this.getNextHoleNumber(),
        'par': 4,
        'handicap': 9
      })
      this.$forceUpdate()
    },
    getNextHoleNumber () {
      return (this.course.holes && this.course.holes.length > 0) ? this.course.holes.length + 1 : 1
    },
    getPar (holes, idxstart, idxstop) {
      var par = 0
      var start = parseInt(idxstart) || 0
      var stop = parseInt(idxstop) || holes.length - 1
      for (var i = start; i <= stop; i++) {
        par += parseInt(holes[i].par) || 0
      }
      return par
    },
    decreasePar (hole) {
      if (hole.par > 3) {
        hole.par--
        this.$forceUpdate()
      }
    },
    increasePar (hole) {
      if (hole.par < 5) {
        hole.par++
        this.$forceUpdate()
      }
    },
    decreaseHandicap (hole) {
      hole.handicap--
      this.$forceUpdate()
    },
    increaseHandicap (hole) {
      hole.handicap++
      this.$forceUpdate()
    },
    onSubmitForm () {
      this.errors = []
      this.validateCourse()
      this.validateTeeboxes()
      this.validateHoles()
      if (this.errors.length !== 0) {
        return
      }
      this.$emit('sendForm', this.course)
    },
    validateCourse () {
      let requires = [
        'name', 'state', 'teeboxes'
      ]
      for (let i = 0; i < requires.length; i++) {
        if (!this.course[requires[i]]) {
          this.errors.push(requires[i] + ' is Required!')
        }
      }
      if (this.course.teeboxes && this.course.teeboxes.length < 1) {
        this.errors.push('At least one teebox specified.')
      }
    },
    onlyForNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // // only allow number
      if ((keyCode < 48 || keyCode > 57)) {
        this.errors = []
        this.errors.push('Phone number should only contains Number!')
        $event.preventDefault()
      } else {
        this.errors = []
      }
    },
    onDelete (index) {
      this.course.teeboxes.splice(index, 1)
      this.$forceUpdate()
    },
    validateTeeboxes: function () {
      if (this.course.teeboxes.length === 0) {
        this.errors.push(`At least one teebox is required!`)
        return
      }

      let requires = ['description', 'rating', 'slope']

      for (let i = 0; i < this.course.teeboxes.length; i++) {
        let teebox = this.course.teeboxes[i]
        for (let j = 0; j < requires.length; j++) {
          // check empty teebox
          if (!teebox[requires[j]]) {
            this.errors.push(`Teebox ${(i + 1)} ${requires[j]} is Required!`)
          }
          // check if rating and slope is positive
          if ((j === 1 || j === 2) && teebox[requires[j]] < 0) {
            this.errors.push(`Teebox ${(i + 1)} ${requires[j]} should be positive!`)
          }
        }
        // Validate rating as two numbers, a decimal, and one number (e.g., 79.8)
        if (teebox.rating && !teebox.rating.toString().match(/^\d{2,3}\.\d$/)) {
          this.errors.push(`Teebox ${i + 1}: Rating must be in the format XX.X or XXX.X`)
        }

        // Validate slope between 55 and 155
        if (teebox.slope && (teebox.slope < 55 || teebox.slope > 155)) {
          this.errors.push(`Teebox ${i + 1}: Slope must be between 55 and 155`)
        }
      }
      // check teeboxes description is uniquee
      this.validateTeeboxDescription()
    },
    validateTeeboxDescription () {
      // check null
      if (!this.course || !this.course.teeboxes || !this.course.teeboxes.length) {
        return
      }
      let descriptions = this.course.teeboxes.map(teebox => {
        return teebox.description
      })
      for (let i = 0; i < descriptions.length; i++) {
        let curtDes = descriptions[i]
        if (descriptions.filter(desc => (desc.toLowerCase().trim() === curtDes.toLowerCase().trim())).length > 1) {
          this.errors.push(`Teebox Name should be unique!`)
          return
        }
      }
    },
    validateHoles () {
      if (!this.course || !this.course.holes || !this.course.holes.length) {
        return false
      }
      let handicaps = this.course.holes.map(hole => {
        return hole.handicap
      })
      for (let i = 0; i < handicaps.length; i++) {
        if (handicaps.filter(handicap => (handicap === handicaps[i])).length > 1) {
          this.errors.push(`Hole handicaps should be unique!`)
          return
        }
      }
      var newpar = this.getPar(this.course.holes)
      if (newpar > 73) {
        this.errors.push(`Course Par should not exceed 72!`)
        return
      }
      if (newpar !== this.course.par) {
        this.course.newpar = newpar
      }
    },

    handleTabPress (e) {
      let holeIndex = e + 1
      let totalHoles = this.course.holes.length

      if (holeIndex === totalHoles) {
        if (totalHoles !== 18) {
          this.addHole()
          totalHoles++
        }
      }
    }
  }
}
</script>
