<template>
  <div
    v-if="course"
    class="card mb-1 h-100"
  >
    <div class="card-header">
      <div class="row">
          <div class="col-12 col-md-4 offset-md-4 text-center">
            <b>Conditions</b>
          </div>
          <div class="col-12 col-md-4 text-center">
            <div v-if="course.coursecomdate != ''">
              <u>{{course.coursecomdate}}</u>
            </div>
          </div>
      </div>
    </div>

    <div class="card-body">

      <div class="row">
        <div class="col-12 col-lg-6 col-xl-3" v-if="course.greenscondition">
          <div class="row">
            <div class="col-8"><b>Greens Condition</b></div>
            <div class="col-4 text-right text-lg-left">{{options.greenConditions[course.greenscondition]}}</div>
          </div>
        </div>

        <div class="col-12 col-lg-6 col-xl-3" v-if="course.fairwaycondition">
          <div class="row">
            <div class="col-8"><b>Fairway Condition</b></div>
            <div class="col-4 text-right text-lg-left">{{options.fairwayConditions[course.fairwaycondition]}}</div>
          </div>
        </div>

        <div class="col-12 col-lg-6 col-xl-3" v-if="course.greensspeed">
          <div class="row">
            <div class="col-8"><b>Greens Speed</b></div>
            <div class="col-4 text-right text-lg-left">{{options.greenSpeeds[course.greensspeed]}}</div>
          </div>
        </div>

        <div class="col-12 col-lg-6 col-xl-3" v-if="course.value">
          <div class="row">
            <div class="col-8"><b>Value</b></div>
            <div class="col-4 text-right text-lg-left">{{options.valueRatings[course.value]}}</div>
          </div>
        </div>
      </div>

      <div class="row pt-3 pt-xl-5" v-if="course.coursecom != ''">
        <div class="col-12 col-lg-2">
          <b>Comments:</b>
        </div>
        <div class="col-12 col-lg-10">
          {{course.coursecom}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    course: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  computed: {
    ...mapGetters({
      options: 'course/options'
    })
  }
}
</script>

<style scoped>
</style>
