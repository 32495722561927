import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UserService from '@/services/UserService'

const TeetimeService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = UserService.baseUrl()
    // this.setHeader()
  },

  setHeader () {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${UserService.getToken()}`
  },

  getTeetimes () {
    this.setHeader()
    return Vue.axios.get(`teetime/all`).catch(error => {
      throw new Error(`[RWV] TeetimeService ${error}`)
    })
  },
  getTeetimesLoggedOut () {
    return Vue.axios.get(`teetime/loggedOut`).catch(error => {
      throw new Error(`[RWV] TeetimeService ${error}`)
    })
  },
  getTeetime (teetimeId) {
    this.setHeader()
    return Vue.axios.get(`teetime/${teetimeId}`).catch(error => {
      throw new Error(`[RWV] TeetimeService ${error}`)
    })
  },
  getTeetimeTourneyPlayers (teetimeId) {
    this.setHeader()
    return Vue.axios.get(`teetime/${teetimeId}/tourney/players`).catch(error => {
      throw new Error(`[RWV] TeetimeService ${error}`)
    })
  },
  addTeetime (data) {
    return Vue.axios.post(`teetime`, data)
  },
  editTeetime (teetimeId, data) {
    return Vue.axios.patch(`teetime/${teetimeId}`, data).catch(error => {
      throw new Error(`[RWV] TeetimeService ${error}`)
    })
  },
  cancelTeetime (teetimeId) {
    return Vue.axios.post(`teetime/cancel/${teetimeId}`).catch(error => {
      throw new Error(`[RWV] TeetimeService ${error}`)
    })
  },
  signup (teetimeId, data) {
    return Vue.axios.post(`teetime/${teetimeId}/signup`, data).catch(error => {
      throw new Error(error.response.data.error)
    })
  },
  updateGroupOuting (data, id = 0) {
    this.setHeader()
    return Vue.axios.post(`teetime/updateGroupOuting/${id}`, data)
  },
  initGroupOuting () {
    this.setHeader()
    return Vue.axios.get(`teetime/initGroupOuting`)
  },

  getOutings () {
    this.setHeader()
    return Vue.axios.get(`teetime/groupOutingList`)
  },

  getCurrentOutings () {
    this.setHeader()
    return Vue.axios.get(`teetime/groupCurrentOutingList`)
  },

  groupOutingByCode (code, group = 0) {
    return Vue.axios.get(`teetime/groupOutingByCode/${code}/${group}`)
  },

  getHole (outing, group, hole) {
    return Vue.axios.get(`teetime/hole/${outing}/${group}/${hole}`)
  },

  getScoreCard (id) {
    return Vue.axios.get(`teetime/card/${id}`)
  },

  getScoreBoardByCode (code, hole) {
    return Vue.axios.get(`teetime/scoreboard/${code}/${hole}`)
  },

  saveHole (outing, hole, golfers) {
    return Vue.axios.post(`teetime/updateScore/${outing}/${hole}`, { golfers: golfers })
  },

  saveWolf (wolfGame, wolfPartner, hole) {
    return Vue.axios.post(`teetime/updateWolf`, { wolfGame: wolfGame, hole: hole, wolfPartner: wolfPartner })
  },

  postScores (id) {
    return Vue.axios.get(`teetime/postscores/${id}`)
  },

  cancelOuting (id) {
    return Vue.axios.get(`teetime/cancelouting/${id}`)
  },

  outingInProgress (id) {
    return Vue.axios.get(`teetime/outingInProgress/${id}`)
  },

  getAccessCode (id) {
    return Vue.axios.get(`teetime/getAccessCode/${id}`)
  }
}

export default TeetimeService
