<style scoped>
</style>

<template >
  <div
    class="mb-2"
    style="width:100%"
  >
    <div
      style="width:100%"
      class="input-group"
      v-if="golfers && golfers.length"
    >
      <div class="input-group-prepend">
        <label
          style="width:100%"
          class="input-group-text"
          for="golferSelector"
        >Golfers</label>
      </div>
      <select
        class="custom-select"
        id="golferSelector"
        v-model="sel"
        @change="changeGolfer"
      >
        <option
          v-for='(golfer,index) in golfers'
          :value='golfer.id'
          :key='index'
        >{{golfer.lastname}} , {{golfer.firstname}}</option>
      </select>
      <div
        class="input-group-append"
        v-if="sel"
      >
        <button
          class="btn btn-primary"
          type="button"
          @click.prevent="copyUrl"
        >Share</button>
      </div>
    </div>

    <div
      v-if="!selectedGolfer"
      class="mt-2"
    >
      <div class="alert alert-primary">Please Select a Golfer.</div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'GolferSelector',
  props: {
    selectedGolfer: {
      type: Number
    },
    'golfers':
    {
      type: Array,
      default () {
        return []
      }
    }
  },
  data: function () {
    return {
      sel: null
    }
  },
  watch: {
    selectedGolfer: function (val, oldVal) {
      console.log(val)
      if (val && (val !== oldVal)) {
        this.sel = +this.selectedGolfer
      }
    }
  },
  methods: {
    changeGolfer: function (event) {
      this.$emit('updateGolfer', event.target.value)
    },

    copyUrl () {
      const el = document.createElement('textarea')
      el.value = 'https://app.desertnomads.org/handicapLookup?id=' + this.sel
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = (document.getSelection().rangeCount > 0) ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
      this.$notify({
        group: 'notices',
        title: 'URL Copied',
        text: 'The URL has been copied to the clipboard!',
        type: 'info',
        timeout: 3000
      })
    }
  }
}
</script>
