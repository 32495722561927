<style scoped>
  .gainstroke {
    background-color: rgba(196, 0, 0, 0.8);
    color: white;
  }
</style>
<template>
  <b-container fluid>
    <div class="d-block text-center">
      <a href="https://desertnomads.org/leaderboard/" style="float-center">Link to tournament leaderboard</a>
    </div>
    <b-container>
      <b-row>
        <b-col cols="12" class="h2 text-center">
          Nomad Scoring
        </b-col>
      </b-row>
      <b-card v-if="loggedIn">
        <b-row>
          <b-col cols="12" v-if="myoutings.length > 0">
            <b-row>
              <b-col cols="12" md="6" lg="4" class="mt-4" v-for="o in myoutings" :key="o.id">
                <OutingCard :outing="o" @outingCancelled="handleOutingCancelled"></OutingCard>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" v-else>
            You currently have no rounds being scored. Create one <a href="/tournament">here</a>
          </b-col>
        </b-row>

        <b-row class="mt-4" v-if="otheroutings.length > 0">
          <b-col cols="12" class="h2 text-center">
            Other Leaderboards
          </b-col>
          <b-col cols="12" v-if="otheroutings.length > 0">
            <b-row>
              <b-col cols="12" md="6" lg="4" class="mt-4" v-for="o in otheroutings" :key="o.id">
                <OutingCard :outing="o" :flagUser="user_id"></OutingCard>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" v-else>
            There are currently no other outings happening that can be viewed.
          </b-col>
        </b-row>
      </b-card>
      <b-card v-else>
        <b-row>
          <b-col cols="12" md="4">
            Outing code
          </b-col>
          <b-col cols="12" md="8">
            <form @submit.prevent="setCode()">
              <b-input v-model="code"></b-input>
            </form>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="12">
            <b-button size="lg" block @click="setCode()">Score outing</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </b-container>
</template>

<script>
import TeetimeService from '@/services/TeetimeService'
import UserService from '@/services/UserService'

import OutingCard from '@/views/leaderboard/OutingCard'

export default {
  name: 'Leaderboard',
  data () {
    return {
      code: null,
      myoutings: [],
      otheroutings: [],
      loggedIn: false,
      user_id: null
    }
  },

  components: {
    OutingCard
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      const user = UserService.getUserObject()

      if (user && user.token) {
        this.user_id = user.id
        TeetimeService.getOutings().then(
          (response) => {
            this.myoutings = response.data.myoutings
            this.otheroutings = response.data.otheroutings
            this.loggedIn = true
          }
        )
      }
    },
    handleOutingCancelled (teetimeId) {
      this.myoutings = this.myoutings.filter(outing => outing.id !== teetimeId)
    },

    setCode () {
      this.$router.push('/leaderboard/' + this.code)
    }
  }
}
</script>
