<style scoped>
  .gainstroke-1 {
    background-color: rgba(196, 0, 0, 0.8);
    color: white;
  }
  .gainstroke-2 {
    background-color: rgb(0, 0, 255, 0.8);
    color: white;
  }
  .toPar {
    position: absolute;
    font-size: 0.8rem;
    transform: translateY(1.2rem);
  }

  @media all and (min-width: 768px) {
    .toPar {
      position: absolute;
      font-size: 1rem;
      transform: translateY(1rem) translateX(0.5rem);
    }
  }
</style>
<template>
  <div>
    <div>
      <b-overlay :show="loading">
        <b-card>
          <b-skeleton-img v-if="loading"></b-skeleton-img>
          <div v-if="!loading">
            <b-row>
              <b-col cols="12" class="h3 text-center" v-if="outing">
                {{ outing.course }} - {{ outing.date | momentformat('MM/DD') }}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" md="3" order="1" class="text-right h1">
                <b-button size="lg" block @click="moveHole(-1)">
                  <i class="fas fa-angle-double-left"></i>
                </b-button>
              </b-col>
              <b-col cols="12" md="6" order="3" order-md="2" class="text-center h1 m-0" v-if="hole && hole.hole">
                  Hole {{ hole.hole }}
                  <div class="h5">Par {{ hole.par }} - Handicap #{{ hole.handicap }}</div>
              </b-col>
              <b-col cols="12" order="4" class="text-center p-0" v-if="outing && outing.contest_id && outing.contest_id > 0">
                <ContestInfo :contest="outing.contest_id"></ContestInfo>
              </b-col>
              <b-col cols="12" order="4" class="text-center p-0" v-if="outing && outing.contest_id && outing.contest_id == 21 && wolf_scores && hasScore">
                <WolfScoreByHole :hole="hole.hole" :scores="wolf_scores"></WolfScoreByHole>
              </b-col>
              <b-col cols="12" order="4" class="text-center p-0" v-if="outing && outing.contest_id && outing.contest_id == 21 && !hasScore">
                <a href="javascript:void(0)" @click="showPopUp()">Wolf Options</a>
              </b-col>
              <b-col cols="12" order="4" class="text-center p-0" v-if="outing && (+outing.netskins == 1 || +outing.grossskins == 1)">
                <ContestInfo contest="skin"></ContestInfo>
              </b-col>
              <b-col cols="6" md="3" order="2" order-md="3" class="text-left h1">
                <b-button size="lg" block @click="moveHole(1)">
                  <i class="fas fa-angle-double-right"></i>
                </b-button>
              </b-col>
            </b-row>
            <b-row class="text-center d-none d-md-flex">
              <b-col cols="2">
                Card
              </b-col>
              <b-col cols="5">
                Player
              </b-col>
              <b-col cols="5">
                Score
              </b-col>
            </b-row>
            <b-row class="text-center mt-4" v-for="g in golfers" :key="g.id">
              <b-col cols="2" class="d-none d-md-flex">
                <b-button @click="openCard(g)" class="mx-auto"><i class="fas fa-edit"></i></b-button>
              </b-col>
              <b-col cols="5" class="d-none d-md-flex flex-column" :class="g.stroke > 0 ? 'gainstroke-' + g.stroke : ''">
                <div class="my-auto mx-auto">{{ g.last }}{{ g.first | initial }} {{ +g.coursehandicap != 0 ? '(' + g.coursehandicap + ')' : ''}}</div>
                {{ g.teebox }}
              </b-col>
              <b-col cols="6" class="d-flex d-md-none flex-column" :class="g.stroke > 0 ? 'gainstroke-' + g.stroke : ''" @click="openCard(g)">
                <div class="my-auto mx-auto"><u>{{ g.last }}{{ g.first | initial }} {{ +g.coursehandicap != 0 ? '(' + g.coursehandicap + ')' : ''}}</u></div>
                {{ g.teebox }}
              </b-col>
              <b-col cols="6" md="5" class="d-flex">
                <b-row style="margin-left: 15px; width: 100%; height: 100%;">
                  <b-col cols="12" class="px-0 d-flex no-gutters">
                    <div class="col">
                      <b-button variant="dark"
                        v-if="!readOnly"
                        @click="changeScore(g, -1)"
                        class="w-100 mx-auto my-auto"
                      ><i class="fas fa-minus"></i></b-button>
                    </div>
                    <div class="col">
                      <div class="mx-auto my-auto h2">
                        {{ readOnly && !hasScore ? '-' : g.holescore}} <span class="toPar" v-if="hasScore">{{ (g.holescore - hole.par) == 0 ? 'E' : (g.holescore - hole.par) }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <b-button variant="dark"
                        v-if="!readOnly"
                        @click="changeScore(g, 1)"
                        class="w-100 mx-auto my-auto"
                      ><i class="fas fa-plus"></i></b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-4" v-if="!readOnly">
              <b-col cols="12">
                <b-button block variant="success" size="lg" @click="saveHole()">
                  {{ hasScore ? 'Change' : 'Record' }} Score
                </b-button>
              </b-col>
            </b-row>
            <b-modal id="wolfOptions" v-if="outing && outing.contest_id && outing.contest_id == 21 && !hasScore" v-model="showModal" size="xl" hide-footer scrollable>
              <div class="container" align="center">
                <strong>Hole: </strong>{{hole.hole}} <br>
                <strong>Wolf: </strong>{{new_wolf}}
                <b-form-group>
                  <b-form-radio v-model="wolf.wolf_game" :value="0">Play Blind</b-form-radio>
                  <b-form-radio v-model="wolf.wolf_game" :value="1">Play Lone</b-form-radio>
                  <b-form-radio v-model="wolf.wolf_game" :value="2">Choose Partner</b-form-radio>
                </b-form-group>
                <div>
                  <b-form-group v-if="wolf.wolf_game == 2">
                    <b-form-radio v-for="g in removeWolf()" :key="g.id" v-model="wolf.wolf_partners" :value="g.id">{{g.name}}</b-form-radio>
                  </b-form-group>
                </div>
                <div>
                  <b-button block variant="success" size="lg" @click="saveWolf()">
                    Select options
                  </b-button>
                </div>
              </div>
            </b-modal>
          </div>
        </b-card>
      </b-overlay>
    <div v-if="postReady && ownOuting" class="mt-4">
      <b-button @click="$router.push('/tournament')" block size="xl">Go to post scores</b-button>
    </div>
    </div>
    <b-modal
      v-model="showCard"
      size="xl"
      hide-footer
      @hidden="g2tId = null"
      modal-class="pa-0"
    >
      <div v-if="g2tId">
        <ScoreCard :g2tId="g2tId" @close="showCard = false"></ScoreCard>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ContestInfo from '@/components/ContestInfo'
import TeetimeService from '@/services/TeetimeService'
import ScoreCard from '@/views/leaderboard/ScoreCard'
import WolfScoreByHole from '@/components/WolfScoreByHole'
export default {
  name: 'Leaderboard',
  components: {
    ScoreCard,
    ContestInfo,
    WolfScoreByHole
  },
  data () {
    return {
      loadingCount: 0,

      outing: null,
      golfers: [],
      course: null,
      hole: null,
      hasScore: false,
      showCard: false,
      g2tId: null,
      readOnly: false,
      postReady: false,
      ownOuting: false,
      showModal: false,
      wolf: {
        wolf_game: {
          type: Number,
          default: 0
        },
        wolf_partners: '0'
      }
    }
  },

  props: {
    code: {
      type: [String],
      default: null
    },
    group: {
      type: [String],
      default: '0'
    },
    wolf_scores: Object,
    new_wolf: {
      type: String,
      default: ''
    }
  },

  watch: {
    showModal (n, o) {
      if (n !== o) {
        if (!n) {
          this.wolf.wolf_game = -1
          this.wolf.wolf_partners = '0'
        }
      }
    }
  },

  mounted () {
    this.getOuting()
    this.showPopUp()
  },

  methods: {
    getOuting () {
      this.updateLoading(1)
      TeetimeService.groupOutingByCode(this.code, this.group).then(
        (response) => {
          this.outing = response.data.outing
          console.log(this.outing)
          this.golfers = response.data.golfers
          this.hole = response.data.hole
          this.hasScore = (+response.data.hasScore === 1)
          this.postReady = (+response.data.postReady === 1)
          this.ownOuting = (+response.data.ownOuting === 1)
          this.readOnly = response.data.readOnly
          if (!this.hasScore) {
            this.golfers.forEach(e => {
              if (e.holescore === 0) {
                e.holescore = this.hole.par
              }
            })
          }

          this.$emit('update', { full: false, hole: this.hole })
        }
      ).catch(err => {
        this.$router.push('/leaderboard', this.$notify({
          group: 'errors',
          title: err.response.data.error,
          type: 'error'
        }))
      }).finally(() => { this.updateLoading(-1) })
    },
    // removing the wolf from the list of golfers for the partner choice
    removeWolf () {
      let tmp = [...this.golfers]
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].name === this.new_wolf) {
          tmp.splice(i, 1)
        }
      }
      return tmp
    },

    saveWolf () {
      let hole = 1
      if (this.hole && this.hole.hole) {
        hole = +this.hole.hole
      }
      let wolfGame = -1
      let wolfPartner = ''
      if (this.wolf.wolf_game > -1) {
        wolfGame = +this.wolf.wolf_game
        wolfPartner = +this.wolf.wolf_partners
        TeetimeService.saveWolf(wolfGame, wolfPartner, hole).then(
          (res) => {
            console.log(res)
          }
        ).catch(err => { console.log(err) })
        this.showModal = false
      }
    },

    showPopUp () {
      this.showModal = true
    },

    getHole (hole) {
      this.updateLoading(1)
      TeetimeService.getHole(this.outing.id, this.golfers[0].tgroup_id, hole).then(
        (response) => {
          this.hole = response.data.hole
          this.golfers = response.data.golfers
          this.hasScore = (+response.data.hasScore === 1)
          this.postReady = (+response.data.postReady === 1)
          if (!this.hasScore) {
            this.golfers.forEach(e => {
              if (e.holescore === 0) {
                e.holescore = this.hole.par
              }
            })
          }
        }
      ).catch(err => {
        this.$notify({
          group: 'errors',
          title: err.response.data.error,
          type: 'error'
        })
      }).finally(() => { this.$emit('update', { full: false, hole: hole }); this.updateLoading(-1) })
    },

    moveHole (direction) {
      let hole = 1
      if (this.hole && this.hole.hole) {
        hole = this.hole.hole
      }

      if (direction > 0) {
        hole++
      } else if (direction < 0) {
        hole--
      }

      if (hole === 0) {
        hole = 18
      }
      if (hole === 19) {
        hole = 1
      }

      this.getHole(hole)
    },

    changeScore (golfer, direction) {
      let change = 0

      if (direction > 0) {
        change++
      } else if (direction < 0) {
        change--
      }

      golfer.holescore = (+golfer.holescore) + change
    },

    saveHole () {
      this.updateLoading(1)
      this.showPopUp()
      let hole = 1
      if (this.hole && this.hole.hole) {
        hole = +this.hole.hole
      }
      TeetimeService.saveHole(+this.outing.id, hole, this.golfers).then(
        (response) => {
          let nexthole = response.data.nexthole
          if (!nexthole || nexthole > 18 || nexthole < 1) {
            nexthole = 1
          }
          this.getHole(nexthole)
        }
      ).catch(err => {
        this.$notify({
          group: 'errors',
          title: err.response.data.error,
          type: 'error'
        })
      }).finally(() => { this.$emit('update', { full: false, hole: hole }); this.updateLoading(-1) })
    },

    openCard (golfer) {
      this.g2tId = golfer.id
      this.showCard = true
    },

    updateLoading (val) {
      this.loadingCount += val
      if (this.loadingCount < 0) {
        this.loadingCount = 0
      }
    }
  },

  computed: {
    loading () {
      return this.loadingCount > 0
    }
  },

  filters: {
    initial (val) {
      if (val) {
        return val.substring(0, 1)
      }
    }
  }
}
</script>
