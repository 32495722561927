import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UserService from '@/services/UserService'

const GolfService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = UserService.baseUrl()
    // this.setHeader()
  },

  setHeader () {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${UserService.getToken()}`
  },

  getGolfers (resource, params) {
    this.setHeader()
    return Vue.axios.get(`golfer/all`).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },

  getGolfer (userId) {
    this.setHeader()
    return Vue.axios.get(`golfer/` + userId).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  }
}

export default GolfService
