import RoundService from '@/services/RoundService'

const getDefaultState = () => {
  return {
    round: {
      course: {},
      golfers: [],
      region_id: null,
      teebox_id: null,
      date: null,
      parorbetter: null,
      tournament: null,
      score: null,
      alcohol: '0'
    }
  }
}

const state = getDefaultState()

// notice : actions handle asynchronous data
const actions = {
  async searchCourse (context, filters) {
    const response = await RoundService.search(filters)
    context.commit('setCourses', response)
    return response
  },
  reset ({ commit }) {
    commit('resetState')
  }
}
// notice : mutation only handle synchronous data
const mutations = {
  setRoundCourse (state, course) {
    state.round.course = course
    state.round.region_id = +course.region_id
  },
  setRound (state, round) {
    state.round.region_id = round.region_id
    state.round.teebox_id = round.teebox_id
    state.round.date = round.date
    state.round.parorbetter = round.parorbetter
    state.round.tournament = round.tournament
    state.round.score = round.score
  },
  updateGolfersTournament (state) {
    if (state.round && state.round.tournament) {
      state.round.golfers.forEach(golfer => {
        golfer['tournament'] = state.round.tournament
      })
    }
  },
  setSelectedUsers (state, selectedUsers) {
    state.round.golfers = selectedUsers
    if (state.round.golfers && state.round.golfers.length) {
      state.round.golfers.forEach(golfer => {
        golfer.teebox_id = state.round.teebox_id
        golfer.alcohol = '0'
      })
    }
  },
  resetState: (state) => {
    Object.assign(state, getDefaultState())
  }
}
const getters = {
  round: (state) => {
    return state.round
  },
  roundRegionId: (state) => {
    return state.round.course.region_id
  },
  roundTeeboxId: (state) => {
    return state.round.teebox_id
  },
  roundRegion: (state, getters, rootState, rootGetters) => {
    let regionId = getters.roundRegionId
    let regions = rootGetters['info/regions']
    let roundRegion = regions.filter(region => {
      return region.id === regionId
    })[0]
    return roundRegion
  },
  roundTeebox: (state, getters, rootState, rootGetters) => {
    let teeboxId = getters.roundTeeboxId
    let teeboxes = rootGetters['teebox/teeboxes']
    let roundTeebox = teeboxes.filter(teebox => {
      return teebox.id === teeboxId
    })[0]

    return roundTeebox
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
