<style scoped lang="scss" src="./RoundsTable.scss">
</style>

<template src='./RoundsTable.html'>
</template>

<script>
import UserService from '@/services/UserService'
import Page from '@/components/Page'
import CollapseBtn from '@/components/CollapseBtn'
import EditRound from '@/components/roundForm/EditRound'
import DeleteRound from '@/components/roundForm/DeleteRound'

export default {
  name: 'RoundsTable',
  components: {
    Page,
    CollapseBtn,
    DeleteRound,
    EditRound
  },
  props: {
    title: {
      type: String,
      default () {
        return 'Handicap Lookup'
      }
    },
    filters: {
      type: Object
    },
    roundsInfo:
    {
      type: Object,
      default () {
        return {}
      }
    },
    ifShowFilter: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data: function () {
    return {
      user: null
    }
  },
  created () {
    this.user = UserService.getUser()
  },
  computed: {
    showPagination: function () {
      if (this.filters && this.filters.hidepag) {
        return false
      }
      return true
    }
  },
  methods: {
    onChangePageTo: function (page) {
      this.filters['page'] = page
      this.sentFilters()
    },
    changeSortTo: function (sort) {
      if (this.filters['sort'] === sort) {
        this.filters['desc'] = !this.filters['desc']
      } else {
        this.filters['sort'] = sort
        this.filters['desc'] = true
      }
      // reset page to 1 when sorting
      this.filters['page'] = 1
      this.sentFilters()
    },
    sentFilters: function () {
      this.$emit('updateFilters', this.filters)
    },
    onGetCollapse: function (collapse, index) {
      this.roundsInfo.results[index].isCollapsed = collapse
      this.$forceUpdate()
    },
    isExpanded: function (round) {
      if (round.isCollapsed === false) {
        return true
      } else {
        return false
      }
    },
    getMobileDate: function (date) {
      let mobiledate = date.split('-')
      return mobiledate[1] + '/' + mobiledate[2]
    }
  },
  filters: {
    mmdd: function (val) {
      var date = new Date(val)
      let mm = date.getMonth()
      let dd = date.getDate()

      if (mm < 10) {
        mm = '0' + mm
      }
      if (dd < 10) {
        dd = '0' + dd
      }

      return mm + '/' + dd
    },
    limitchar: function (val, limit) {
      return val.length > limit ? val.substring(0, limit + 1) : val
    }
  }
}
</script>
