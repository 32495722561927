<template>
  <div>
    <b-card>
      <b-row class="text-center">
        <b-col cols="12">
          <div class="h3">{{ outing.course }} - <small>{{ outing.date | momentformat('MM/DD') }}</small></div>
          <div>
            <span v-if="outing.tgroup_id && outing.tgroup_id != 0">Group {{ outing.tgroup_id }} - </span>
            {{ outing.numplayers }} players
          </div>
          <div>
            <span v-if="outing.player_names">{{ outing.player_names }} </span>
          </div>
          <b-col cols="12" class="mb-2">
            <b-button @click="goTo()" :variant="outing.accesscode ? 'success' : 'secondary'">
              {{ outing.accesscode ? 'Score outing' : 'View outing' }}
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button @click="showModal" :variant="'danger'" v-if="outing.accesscode">
              Cancel
            </b-button>
          </b-col>
        </b-col>
      </b-row>
    </b-card>
    <!-- Confirmation Modal -->
    <b-modal v-model="isModalVisible" title="Confirm Cancelation" @ok="cancel(outing.id)" ok-title="Confirm" cancel-title="Cancel">
      Are you sure you want to cancel this outing?
    </b-modal>
  </div>
</template>
<script>
import TeetimeService from '@/services/TeetimeService'

export default {
  props: {
    outing: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = true
    },
    cancel (teetimeId) {
      TeetimeService.cancelOuting(teetimeId).then(
        (response) => {
          this.$emit('outingCancelled', teetimeId)
          console.log('Outing cancelled successfully')
          this.isModalVisible = false
        }).catch(error => {
        console.error('Failed to cancel outing:', error)
      })
    },
    goTo () {
      let link = this.outing.id + '/' + this.outing.tgroup_id
      if (this.outing.accesscode && this.outing.accesscode !== '') {
        link = this.outing.accesscode
      }
      this.$router.push('/leaderboard/' + link)
    }
  }
}
</script>
