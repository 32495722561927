
<style scoped>
.footer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.footer {
  min-height: 120px;
  position: relative;
}
@media screen and (max-width: 600px) {
  .footer {
    min-height: 100px;
    position: relative;
  }
}
</style>

<template>
  <div class="mt-3 mb-2 border-top footer">
    <div class="footer-text">
      <a
        style="display:inline-block;"
        href="http://www.aimitservices.com"
      >AIM IT Services, LLC</a>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
