<template src='./FeelerCard.html'>

</template>

<style scoped src='./FeelerCard.css'>
</style>

<script>
import UserService from '@/services/UserService'

export default {
  props: {
    feelers: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    user: function () {
      return UserService.getUser()
    }
  }
}
</script>
