<style lang="scss" src='./DetailFilter.scss' scoped>
</style>

<template src='./DetailFilter.html'>

</template>

<script>
import moment from 'moment'
import CollapseBtn from '@/components/CollapseBtn'
import AimCommonMixin from '@/mixins/aim.common.mixin'

export default {
  components: {
    CollapseBtn
  },
  mixins: [AimCommonMixin],
  props: {
    title: {
      type: String,
      default () {
        return 'Filter'
      }
    },
    ignoreFilter: {
      type: Array,
      default () {
        return []
      }
    },
    filters: {
      type: Object,
      default: function () {
        return {
          start_date: null,
          end_date: null
        }
      }
    }
  },
  data: function () {
    return {
      isCollapsed: false
    }
  },
  computed: {
    isLoading () {
      return this.$isLoading()
    }
  },
  methods: {
    onClickLastMonth () {
      this.filters.start_date = null
      this.filters.end_date = null
      let today = new Date()
      let lastMonth = moment(today).subtract(1, 'months').format('YYYY-MM-DD')
      this.filters.start_date = lastMonth
      this.filters.end_date = moment(today).format('YYYY-MM-DD')
      // if select last month, sort by date 'DESC'
      this.filters.sort = 'date'
      this.filters.desc = true
      this.onClickApply()
    },
    onClickLastYear () {
      let today = new Date()
      let lastYear = moment(today).subtract(12, 'months').format('YYYY-MM-DD')
      this.filters.start_date = lastYear
      this.filters.end_date = moment(today).format('YYYY-MM-DD')
      // if select last year, sort by date 'DESC'
      this.filters.sort = 'date'
      this.filters.desc = true
      this.onClickApply()
    },
    onClickCancel () {
      this.filters.start_date = null
      this.filters.end_date = null
      this.onClickApply()
    },
    onClickApply () {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
      let copyFilterObj = JSON.parse(JSON.stringify(this.filters))
      let filterWithoutNullValue = this.getObjWithoutNullValueMixin(copyFilterObj)
      this.$emit('updateFilter', filterWithoutNullValue)
    },
    onGetCollapse: function (collapse) {
      this.isCollapsed = collapse
    }
  }
}
</script>
