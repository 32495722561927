<style lang="scss" src="./FeelerForm.scss" scoped></style>
<template src="./FeelerForm.html"></template>
<script>
import Error from '@/components/Error'

export default {
  components: {
    Error
  },
  data () {
    return {
      errors: [],
      ranges: ['Early Morning', 'Late Morning', 'Early Afternoon', 'Late Afternoon']
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default () {
        return false
      }
    },
    feeler: {
      type: Object,
      default: function () {
        return {}
      }
    },
    regions: {
      type: Array,
      default: function () {
        return []
      }
    },
    user: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  methods: {
    onSubmitForm: function () {
      this.errors = []
      if (!this.feeler.date) {
        this.errors.push('Date is Required!')
      }
      if (!this.feeler.timerange) {
        this.errors.push('Time is Required!')
      }
      if (!this.feeler.region_id) {
        this.errors.push('Region is Required!')
      }
      if (this.errors.length === 0) {
        this.setFormDay()
        this.sendForm()
      }
    },
    sendForm: function () {
      this.$emit('sendForm', this.feeler)
    },
    setFormDay () {
      this.feeler.day = this.getCurtDay(new Date(this.feeler.date))
    },
    getCurtDay (date) {
      var weekdays = new Array(7)
      weekdays[0] = 'Monday'
      weekdays[1] = 'Tuesday'
      weekdays[2] = 'Wednesday'
      weekdays[3] = 'Thursday'
      weekdays[4] = 'Friday'
      weekdays[5] = 'Saturday'
      weekdays[6] = 'Sunday'
      return weekdays[date.getDay()]
    }
  }
}
</script>
