import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UserService from '@/services/UserService'

const UserInfoService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = UserService.baseUrl()
    // this.setHeader()
  },
  setHeader () {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${UserService.getToken()}`
  },
  getUserByUsername (data) {
    this.setHeader()
    return Vue.axios.post(`user/getUserByUsername`, data).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  generateTempPwd (data) {
    this.setHeader()
    return Vue.axios.post(`user/generateTempPwd`, data).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  changeEmailAndPwd (data) {
    this.setHeader()
    return Vue.axios.post(`user/changeEmailAndPwd`, data).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  resetTempPwd (data) {
    this.setHeader()
    return Vue.axios.post(`user/resetTempPwd`, data).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  loginByHash (data) {
    this.setHeader()
    return Vue.axios.post(`login/byHash`, data).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  search (query) {
    this.setHeader()
    return Vue.axios.get(`user/search?${query}`).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  settingsInit () {
    this.setHeader()
    return Vue.axios.get(`user/settingsInit`).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  updateInfo (info) {
    this.setHeader()
    return Vue.axios.post(`user/updateInfo`, { user: info }).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  updateEmail (info) {
    this.setHeader()
    return Vue.axios.post(`user/updateEmail`, info).catch(error => {
      throw new Error(`[RWV] UserInfoService ${error}`)
    })
  },
  updatePassword (info) {
    this.setHeader()
    return Vue.axios.post(`user/updatePassword`, info)
  }
}

export default UserInfoService
