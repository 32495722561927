<style scoped>
</style>

<template >
  <TeetimeForm
    :teetime="teetime"
    :isEdit="isEdit"
    :courses='courses'
    :contests='contests'
    :user='user'
    :teeboxes='teeboxes'
    @updateTeetime="onUpdateTeetime($event)"
    @onSearchTeeboxes="onGetSearchTeeboxesFilter($event)"
  ></TeetimeForm>
</template>

<script>
import TeetimeForm from '@/components/teetimeForm/TeetimeForm'
import CourseService from '@/services/CourseService'
import ContestService from '@/services/ContestService'
import TeeboxService from '../../services/TeeboxService'
import TeetimeService from '../../services/TeetimeService'

export default {
  name: 'EditTeetime',
  components: {
    TeetimeForm
  },
  data: function () {
    return {
      isEdit: true,
      teetime: {
        ampm: 'am',
        date: null,
        day: null,
        hour: null,
        min: null,
        num_wanted: null,
        course_id: null,
        rate: null,
        tournament: 0,
        prepay: 0,
        contest_id: 0,
        contact_info: null,
        gross_skins: 0,
        net_skins: 0,
        send_email: null,
        // send_text: null,
        // send_text_signed: null,
        teebox_id: null
      },
      courses: [],
      contests: [],
      user: null,
      teeboxes: []
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    async init () {
      await this.getCourses()
      await this.getContests()
      this.getTeetime()
    },
    async getTeetime () {
      this.$updateloading(1)
      let res = await TeetimeService.getTeetime(this.$route.params['tId'])
      this.$updateloading(-1)
      this.setFormInitData(res.data)
    },
    async setFormInitData (data) {
      // need to wait this function get teebox back
      await this.searchSelectCourseTeebox({
        course_id: {
          operator: '=',
          value: data.teetime.course_id
        }
      })
      this.user = data.submitUser
      let teetime = data.teetime
      this.teetime.date = teetime.date
      this.teetime.day = teetime.day
      this.teetime.num_wanted = teetime.numwanted
      this.teetime.course_id = teetime.course_id
      this.teetime.rate = teetime.rate
      this.teetime.tournament = teetime.tournament
      this.teetime.prepay = teetime.prepay
      this.teetime.contest_id = teetime.contest_id
      this.teetime.contact_info = teetime.contactinfo
      this.teetime.gross_skins = teetime.grossskins
      this.teetime.net_skins = teetime.netskins
      this.teetime.teebox_id = +teetime.teebox_id
      this.setTimes(teetime.time)
    },
    formatDate: function (d) {
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      let year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
      return [year, month, day].join('-')
    },
    setTimes (timeStr) {
      let times = timeStr.split(':')
      let hour = +times[0]
      if (hour >= 12) {
        this.teetime['ampm'] = 'pm'
      } else {
        this.teetime['ampm'] = 'am'
      }
      this.teetime['hour'] = hour > 12 ? hour - 12 : hour
      this.teetime['min'] = times[1]
    },
    async getCourses () {
      this.$updateloading(1)
      let res = await CourseService.getCourses()
      this.$updateloading(-1)
      this.courses = res.data.courses
    },
    async getContests () {
      let res = await ContestService.getContests()
      this.$updateloading(-1)
      this.contests = res.data.contests
    },
    async searchSelectCourseTeebox (filters) {
      this.$updateloading(1)
      let res = await TeeboxService.searchTeebox(filters)
      this.$updateloading(-1)
      this.teeboxes = res.data.teeboxes
    },
    onUpdateTeetime (teetime) {
      this.$updateloading(1)
      TeetimeService.editTeetime(+this.$route.params['tId'], teetime).then(res => {
        this.$router.push({ path: '/home' })
        this.$updateloading(-1)
      }).catch(error => {
        console.log(error)
        this.$updateloading(-1)
      })
    },
    onGetSearchTeeboxesFilter: function (filters) {
      this.searchSelectCourseTeebox(filters)
    }
  }
}
</script>
