<style lang="scss" scoped>
  .table-lines,.table-lines td,.table-lines th {
    border-collapse: collapse;
    border: 1px solid black;
    padding: 0.5rem 0;
  }

  td {
    width: 33.3%;
  }

  table {
    width: 100%;
  }

  .winner {
    background-color: gold;
  }
  .dormie {
    color: brown;
  }
  .up {
    color: green;
  }
  .down {
    color: red;
  }
</style>
<template>
  <div style="overflow: auto;">
    <div class="text-center h5">
      <b>Sixes</b>
    </div>
    <div class="mb-4" v-if="scores.current && scores.current.team1 && scores.current.team2">
      Current Teams: <b>{{ scores.current.team1 }}</b> vs. <b>{{ scores.current.team2 }}</b>
    </div>
    <table class="text-center table-lines">
      <template v-for="(s, k) in orderedScores">
        <tr :key="s.key">
          <td :class="s.team1.class">{{ s.team1.name }}</td>
          <td>{{ k == 0 ? 'Holes' : '' }}</td>
          <td :class="s.team2.class">{{ s.team2.name }}</td>
        </tr>
        <tr :key="s.key" :class="s.dormie ? 'dormie' : ''">
          <td :class="scoreColor(s.team1.score)"><b>{{ s.team1.score | scoreFormat }}</b></td>
          <td :class="middleColor(s, k)">{{ s.dormie ? 'D' : '' }}{{ s.done ? s.holes.start + '-' + s.holes.end : '' }}{{ isNew(s) && k == 0 ? 'NEW' : '' }}</td>
          <td :class="scoreColor(s.team2.score)"><b>{{ s.team2.score | scoreFormat }}</b></td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    scores: {
      type: Object,
      default: () => { return [] }
    }
  },

  methods: {
    scoreColor (val) {
      if (val == null) {
        return ''
      }
      if (isNaN(val)) {
        return ''
      }
      const num = +val
      return (num === 0 ? '' : (num < 0 ? 'down' : 'up'))
    },

    middleColor (s, idx) {
      if (this.scores.notifynew && idx === 0) {
        return 'up'
      }
      return ''
    },

    isNew (s) {
      return (s.team1.score === null && s.team2.score === null)
    }
  },

  filters: {
    initial (val) {
      return val.substring(0, 1)
    },
    scoreFormat (val) {
      if (val == null) {
        return ''
      }
      if (isNaN(val)) {
        return val
      }
      const num = +val
      return (num === 0 ? 'AS' : (num < 0 ? (num * -1) + 'DN' : num + 'UP'))
    }
  },

  computed: {
    orderedScores () {
      const tmp = [...this.scores.games]
      tmp.sort((a, b) => (+a.game > +b.game) ? -1 : (+a.game === +b.game ? 0 : 1))
      return tmp
    }
  }
}
</script>
