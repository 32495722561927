<template>
  <div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Change Info</h5>
            <div class="card-text">
              <div class="form-group">
                <label for="firstname">Firstname</label>
                <input type="text" class="form-control" id="firstname"
                  v-model="infoForm.firstname" :readonly="!editInfo"
                  @blur="$v.infoForm.firstname.$touch()"
                  :class="getErrors('infoForm', 'firstname', infovalidators.firstname).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('infoForm', 'firstname', infovalidators.firstname)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="lastname">Lastname</label>
                <input type="text" class="form-control" id="lastname"
                  v-model="infoForm.lastname" :readonly="!editInfo"
                  @blur="$v.infoForm.lastname.$touch()"
                  :class="getErrors('infoForm', 'lastname', infovalidators.lastname).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('infoForm', 'lastname', infovalidators.lastname)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="phone">Phone Number</label>
                <input type="text" class="form-control" id="phone" v-model="infoForm.phone" :readonly="!editInfo">
              </div>
              <div class="form-group">
                <label for="phone">DOB</label>
                <input type="text" class="form-control" id="phone" v-model="infoForm.dob" :readonly="!editInfo"
                  @blur="$v.infoForm.dob.$touch()" placeholder="MM-DD-YYYY"
                  :class="getErrors('infoForm', 'dob', infovalidators.dob).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('infoForm', 'dob', infovalidators.dob)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Address</label>
                <input type="text" class="form-control" id="address" v-model="infoForm.address" :readonly="!editInfo">
              </div>
              <div class="form-group">
                <label for="city">City</label>
                <input type="text" class="form-control" id="city" v-model="infoForm.city" :readonly="!editInfo">
              </div>
              <div class="form-group">
                <label for="state">State</label>
                <input type="text" class="form-control" id="state"
                  v-model="infoForm.state" :readonly="!editInfo"
                  maxlength="2"
                  @blur="$v.infoForm.state.$touch()"
                  :class="getErrors('infoForm', 'state', infovalidators.state).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('infoForm', 'state', infovalidators.state)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="zip">Zip</label>
                <input type="text" class="form-control" id="zip" v-model="infoForm.zip" :readonly="!editInfo">
              </div>
              <div class="form-group">
                <label for="aga">GHIN (if you have one)</label>
                <input type="text" class="form-control" id="aga" v-model="infoForm.aga" :readonly="!editInfo">
              </div>
              <div>Recieve Post Confirmation Email</div>
              <div class="form-check mb-3">
                <input type="checkbox" class="form-check-input" id="postemail" v-model="infoForm.postemail" :disabled="!editInfo">
                <label for="postemail" class="form-check-label">Yes</label>
              </div>
              <label>Do you want to be notified of tee time opportunities?</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" :value="'None'"
                  :checked="infoForm.teetimenotify == 'None'" v-model="infoForm.teetimenotify" :disabled="!editInfo">
                <label class="form-check-label">No</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" :value="'Text'"
                  :checked="infoForm.teetimenotify == 'Text'" v-model="infoForm.teetimenotify" :disabled="!editInfo">
                <label class="form-check-label">Text</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" :value="'Email'"
                  :checked="infoForm.teetimenotify == 'Email'" v-model="infoForm.teetimenotify" :disabled="!editInfo">
                <label class="form-check-label">Email</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" :value="'Both'"
                  :checked="infoForm.teetimenotify == 'Both'" v-model="infoForm.teetimenotify" :disabled="!editInfo">
                <label class="form-check-label">Both</label>
              </div>
            </div>
            <button class="btn btn-lg float-right" @click="editInfo = true" v-show="!editInfo"><span class="fa fa-edit"></span></button>
            <button class="btn btn-lg btn-primary float-right" @click="updateInfo()" v-show="editInfo">Submit</button>
            <button class="btn btn-lg float-right"
              @click="editInfo = false; reset('infoForm')" v-show="editInfo"
              style="color:red;"
            ><span class="fa fa-times-circle" style="transform: scale(1.4)"></span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Change Email</h5>
            <div class="card-text">
              <div class="form-group">
                <label for="email_username">Username</label>
                <input class="form-control" id="email_username"
                  v-model="emailForm.username" :readonly="!editEmail"
                  @blur="$v.emailForm.username.$touch()"
                  :class="getErrors('emailForm', 'username', emailvalidators.username).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('emailForm', 'username', emailvalidators.username)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="email_password">Password</label>
                <input class="form-control" type="password" id="email_password"
                  v-model="emailForm.password" :readonly="!editEmail"
                  @blur="$v.emailForm.password.$touch()"
                  :class="getErrors('emailForm', 'password', emailvalidators.password).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('emailForm', 'password', emailvalidators.password)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="email">New Email</label>
                <input type="email" class="form-control" id="email"
                  v-model="emailForm.email" :readonly="!editEmail"
                  @blur="$v.emailForm.email.$touch()"
                  :class="getErrors('emailForm', 'email', emailvalidators.email).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('emailForm', 'email', emailvalidators.email)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
            </div>
            <button class="btn btn-lg btn-primary float-right" @click="updateEmail()" v-show="editEmail">Submit</button>
            <button class="btn btn-lg float-right"
              @click="editEmail = false; reset('emailForm')" v-show="editEmail"
              style="color:red;"
            ><span class="fa fa-times-circle" style="transform: scale(1.4)"></span></button>
            <button class="btn btn-lg float-right" @click="editEmail = true" v-show="!editEmail"><span class="fa fa-edit"></span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Change Password</h5>
            <div class="card-text">
              <div class="form-group">
                <label for="password_username">Username</label>
                <input class="form-control" id="password_username"
                  v-model="passwordForm.username" :readonly="!editPassword"
                  @blur="$v.passwordForm.username.$touch()"
                  :class="getErrors('passwordForm', 'username', pwordvalidators.username).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('passwordForm', 'username', pwordvalidators.username)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="password_password">OLD Password</label>
                <input class="form-control" type="password" id="password_password"
                  v-model="passwordForm.oldPassword" :readonly="!editPassword"
                  @blur="$v.passwordForm.oldPassword.$touch()"
                  :class="getErrors('passwordForm', 'oldPassword', pwordvalidators.oldPassword).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('passwordForm', 'oldPassword', pwordvalidators.oldPassword)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="pass">New Password</label>
                <input type="password" class="form-control" id="pass"
                  v-model="passwordForm.password"
                  :readonly="!editPassword"
                  @blur="$v.passwordForm.password.$touch()"
                  :class="getErrors('passwordForm', 'password', pwordvalidators.password).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('passwordForm', 'password', pwordvalidators.password)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="pass2">New Password (again)</label>
                <input type="password" class="form-control" id="pass2"
                  v-model="passwordForm.passwordRepeat"
                  :readonly="!editPassword"
                  @blur="$v.passwordForm.passwordRepeat.$touch()"
                  :class="getErrors('passwordForm', 'passwordRepeat', pwordvalidators.passwordRepeat).length > 0 ? 'is-invalid' : ''"
                >
                <div class="invalid-feedback">
                  <span v-for="(i, k) in getErrors('passwordForm', 'passwordRepeat', pwordvalidators.passwordRepeat)" :key="k">
                    {{ i }}<br>
                  </span>
                </div>
              </div>
            </div>

            <button class="btn btn-lg float-right" @click="editPassword = true" v-show="!editPassword"><span class="fa fa-edit"></span></button>
            <button class="btn btn-lg btn-primary float-right" @click="updatePassword()" v-show="editPassword">Submit</button>
            <button class="btn btn-lg float-right"
              @click="editPassword = false; reset('passwordForm')" v-show="editPassword"
              style="color:red;"
            ><span class="fa fa-times-circle" style="transform: scale(1.4)"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserInfoService from '@/services/UserInfoService'
import moment from 'moment'
import UserService from '@/services/UserService'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  sameAs,
  maxLength
} from 'vuelidate/lib/validators'

const dobFormat = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return moment(value, 'MM-DD-YYYY', true).isValid()
  }
}

export default {
  data: () => {
    return {
      infoForm: {
        firstname: null,
        lastname: null,
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        aga: '',
        postemail: true,
        teetimenotify: 'Both',
        dob: ''
      },
      emailForm: {
        username: null,
        password: null,
        email: null
      },
      passwordForm: {
        username: null,
        oldPassword: null,
        password: null,
        passwordRepeat: null
      },
      currentEmail: '',
      editInfo: false,
      editEmail: false,
      editPassword: false,
      requiredValidation: ['required'],
      infovalidators: {
        firstname: [
          { type: 'required', message: 'Firstname is required' }
        ],
        lastname: [
          { type: 'required', message: 'Lastname is required' }
        ],
        state: [
          { type: 'maxLength', message: 'State must be 2 characters' }
        ],
        dob: [
          { type: 'required', message: 'DOB is required' },
          { type: 'dobFormat', message: 'Please enter a valid date in MM-DD-YYYY format' }
        ]
      },
      emailvalidators: {
        username: [
          { type: 'required', message: 'Username is required' }
        ],
        password: [
          { type: 'required', message: 'Password is required' }
        ],
        email: [
          { type: 'required', message: 'Email is required' },
          { type: 'email', message: 'Please enter a valid email' }
        ]
      },
      pwordvalidators: {
        username: [
          { type: 'required', message: 'Username is required' }
        ],
        oldPassword: [
          { type: 'required', message: 'Password is required' }
        ],
        password: [
          { type: 'required', message: 'Password is required' }
        ],
        passwordRepeat: [
          { type: 'sameAsPassword', message: 'Passwords must match' },
          { type: 'required', message: 'Password is required' }
        ]
      }
    }
  },
  mixins: [ validationMixin ],
  validations: {
    infoForm: {
      firstname: {
        required
      },
      lastname: {
        required
      },
      state: {
        maxLength: maxLength(2)
      },
      dob: {
        required,
        dobFormat
      }
    },
    emailForm: {
      username: {
        required
      },
      password: {
        required
      },
      email: {
        required,
        email
      }
    },
    passwordForm: {
      username: {
        required
      },
      oldPassword: {
        required
      },
      password: {
        required
      },
      passwordRepeat: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init: function (type = 0) {
      UserInfoService.settingsInit().then(
        (response) => {
          this.infoForm = response.data.user
          if (this.infoForm.dob) {
            this.infoForm.dob = moment(this.infoForm.dob, 'YYYY-MM-DD').format('MM-DD-YYYY')
          }
          this.currentEmail = response.data.email
          this.emailForm.email = response.data.email
          this.editInfo = false
          this.editEmail = false
          this.editPassword = false
        }
      )
    },
    updateInfo: function () {
      this.$v.infoForm.firstname.$touch()
      this.$v.infoForm.lastname.$touch()
      this.$v.infoForm.state.$touch()
      if (this.getErrors('infoForm', 'firstname', this.infovalidators.firstname).length > 0 ||
          this.getErrors('infoForm', 'lastname', this.infovalidators.lastname).length > 0 ||
          this.getErrors('infoForm', 'state', this.infovalidators.state).length > 0) {
        this.$updateloading(-1)
      } else {
        if (this.infoForm.dob) {
          this.infoForm.dob = moment(this.infoForm.dob, 'MM-DD-YYYY').format('YYYY-MM-DD')
          let user = UserService.getUserObject()
          user.user.dob = this.infoForm.dob
          const dob = new Date(this.infoForm.dob)
          const today = new Date()
          let age = today.getFullYear() - dob.getFullYear()
          const m = today.getMonth() - dob.getMonth()
          if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
            age--
          }
          user.user.age = age
          localStorage.setItem('desertnomadsuser', JSON.stringify(user))
        }
        UserInfoService.updateInfo(this.infoForm).then(
          (response) => {
            this.init()
            this.$notify({
              group: 'notices',
              title: 'Update Successful',
              text: 'Your information has been updated',
              type: 'info'
            })
            this.$updateloading(-1)
          },
          (error) => {
            this.$updateloading(-1)
            this.$notify({
              group: 'errors',
              title: 'Error',
              text: error.response.data.error,
              type: 'danger'
            })
          }
        )
      }
    },
    updateEmail: function () {
      this.$updateloading(1)
      this.$v.emailForm.username.$touch()
      this.$v.emailForm.password.$touch()
      this.$v.emailForm.email.$touch()
      if (this.getErrors('emailForm', 'username', this.emailvalidators.username).length > 0 ||
          this.getErrors('emailForm', 'password', this.emailvalidators.password).length > 0 ||
          this.getErrors('emailForm', 'email', this.emailvalidators.email).length > 0) {
        this.$updateloading(-1)
      } else {
        UserInfoService.updateEmail(this.emailForm).then(
          (response) => {
            this.init()
            this.$notify({
              group: 'notices',
              title: 'Update Successful',
              text: 'Your email has been updated',
              type: 'info'
            })
            this.$updateloading(-1)
          },
          (error) => {
            this.$updateloading(-1)
            this.$notify({
              group: 'errors',
              title: 'Error',
              text: error.response.data.error,
              type: 'danger'
            })
          }
        )
      }
    },
    updatePassword: function () {
      this.$updateloading(1)
      this.$v.passwordForm.$touch()
      if (this.getErrors('passwordForm', 'username', this.pwordvalidators.username).length > 0 ||
          this.getErrors('passwordForm', 'oldPassword', this.pwordvalidators.oldPassword).length > 0 ||
          this.getErrors('passwordForm', 'password', this.pwordvalidators.password).length > 0 ||
          this.getErrors('passwordForm', 'passwordRepeat', this.pwordvalidators.passwordRepeat).length > 0) {
        this.$updateloading(-1)
      } else {
        UserInfoService.updatePassword(this.passwordForm).then(
          res => {
            this.$updateloading(-1)
            localStorage.setItem('desertnomadsuser', JSON.stringify(res.data))
            this.init()
            this.$notify({
              group: 'notices',
              title: 'Update Successful',
              text: 'Your password has been updated',
              type: 'info'
            })
          },
          (error) => {
            this.$updateloading(-1)
            this.$notify({
              group: 'errors',
              title: 'Error',
              text: error.response.data.error,
              type: 'danger'
            })
          }
        )
      }
    },
    getErrors: function (form, field, validatiors) {
      const err = []
      if (!this.$v[form][field].$dirty) return err

      validatiors.forEach(e => {
        !this.$v[form][field][e.type] && err.push(e.message)
      })

      return err
    },
    reset: function (form) {
      this.$v[form].$reset()
    }
  }
}
</script>
