<style scoped>
</style>

<template >
  <div style="width:100%">
    <div
      class="input-group flex-nowrap mt-1 mb-2"
      v-if="selectors && selectors.length"
    >
      <div class="input-group-prepend">
        <span
          class="input-group-text"
          for="dataSelector"
        >Options</span>
      </div>

      <select
        class="custom-select"
        id="dataSelector"
        v-model="sel"
        @change="changeSelect"
      >
        <option
          v-for='(obj,index) in selectors'
          :value='obj.key ? obj.key : obj.id'
          :key='index'
        >{{obj.value}}</option>
      </select>
    </div>

    <div
      v-if="!selectedValue"
      class="col-12 col-md-6 mt-2 text-center"
    >
      <div class="alert alert-primary">
        <span>
          Please Select a {{suggestion}}.
        </span>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'AimSelector',
  props: {
    selectedValue: null,
    selectors:
    {
      type: Array,
      default () {
        return []
      }
    },
    suggestion: {
      type: String,
      default: function () {
        return 'Option'
      }
    }
  },
  data: function () {
    return {
      sel: null
    }
  },
  watch: {
    selectedValue: function (val, oldVal) {
      if (!this.sel && val && (val !== oldVal)) {
        this.sel = this.selectedValue
      }
    }
  },
  methods: {
    changeSelect: function (event) {
      this.$emit('updateSelect', event.target.value)
    }
  }
}
</script>
