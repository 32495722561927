<script>
export default {
  methods: {
    // check data array is valid
    isDataValid: function (arr) {
      return arr && arr.length
    },
    getObjIndexInArr: function (arr, obj) {
      if (!arr) {
        return -1
      }
      return arr.findIndex((selected) => {
        return selected.id === obj.id
      })
    },
    objExistedInSelectedArrayMixin: function (arr, obj) {
      if (!this.isDataValid(arr)) {
        return false
      }
      return this.getObjIndexInArr(arr, obj) !== -1
    },
    // toggle one object the of array
    toggleOneMixin: function (arr, obj) {
      if (!this.objExistedInSelectedArrayMixin(arr, obj)) {
        arr.pop()
        arr.push(obj)
      } else {
        arr.pop()
      }
    },
    // add one object the bottom of array
    pushOneMixin: function (arr, obj) {
      let index = this.getObjIndexInArr(arr, obj)
      if (index !== -1) {
        this.removeOneMixin(arr, obj)
      } else {
        arr.push(obj)
      }
    },
    // add one object the top of array
    unshiftOneMixin: function (arr, obj) {
      arr.unshift(obj)
    },
    // remove one object from array using index
    removeOneMixin: function (arr, obj) {
      if (this.isDataValid(arr) === false) {
        return
      }
      let index = this.getObjIndexInArr(arr, obj)
      if (index !== -1) {
        arr.splice(index, 1)
      }
    },
    // edit one object  array with index
    editOneMixin: function (arr, index, newObj) {
      if (this.isDataValid(arr) === false) {
        return
      }
      if (this.isIndexValid(arr, index) === false) {
        return
      }
      arr[index] = newObj
    }
  }
}
</script>
