<style  scoped>
</style>

<template >
  <div class="mt-4 mb-4">
    <div class="row">
      <div class="col-12 mx-auto align-middle">
        <div class="card p-3 h-100">
          <div class="card-body ">
            <h3 class="card-title text-center">
              Thank you for visiting the Desert Nomads Golf Club
            </h3>
            <hr>
            <div class="text-center pt-3  pb-3">
              <p class="card-text ">
                Email questions or comments to
              </p>
              <a
                class="card-link"
                :href="mailTo"
              > membership@desertnomads.org</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data: function () {
    return {
      mailTo: 'mailto:membership@desertnomads.org?subject=Desert Nomads Inquiry'
    }
  }
}
</script>
