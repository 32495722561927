<template>
  <i
    class="fas"
    :class="{'fa-angle-down' : isCollapsed, 'fa-angle-up' : !isCollapsed}"
    @click="onClickCollapse"
  ></i>

</template>

<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data: function () {
    return {
      isCollapsed: this.collapsed
    }
  },
  methods: {
    onClickCollapse: function () {
      this.isCollapsed = !this.isCollapsed
      this.$emit('onClickCollapse', this.isCollapsed)
    }
  }
}
</script>

<style scoped>
</style>
