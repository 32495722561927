import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UserService from '@/services/UserService'

const InfoService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = UserService.baseUrl()
    // this.setHeader()
  },

  setHeader () {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${UserService.getToken()}`
  },
  getRegions () {
    this.setHeader()
    return Vue.axios.get(`info/region`).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },
  addFeeler (data) {
    return Vue.axios.post(`info/feeler`, data).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },
  getMembers () {
    this.setHeader()
    return Vue.axios.get(`info/members`).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },
  getFeelers () {
    this.setHeader()
    return Vue.axios.get(`info/feeler`).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },
  getFeeler (feelerId) {
    this.setHeader()
    return Vue.axios.get(`info/feeler/${feelerId}`).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  },
  editFeeler (feelerId, data) {
    return Vue.axios.patch(`info/feeler/${feelerId}`, data).catch(error => {
      throw new Error(`[RWV] InfoService ${error}`)
    })
  }
}

export default InfoService
