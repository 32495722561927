import { render, staticRenderFns } from "./RoundForm.html?vue&type=template&id=423f8fa4&scoped=true&external"
import script from "./RoundForm.vue?vue&type=script&lang=js"
export * from "./RoundForm.vue?vue&type=script&lang=js"
import style0 from "./RoundForm.css?vue&type=style&index=0&id=423f8fa4&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "423f8fa4",
  null
  
)

export default component.exports