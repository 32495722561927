<script>
export default {
  methods: {
    // get a filters object like
    // filters: {
    //   sort: 'name',
    //   desc: false,
    //   size: 10,
    //   page: 1
    // }
    // complie and encode it into string like 'key=value&key=value'
    SerializeFiltersMixin: function (filters) {
      var str = []
      for (var p in filters) {
        if (filters.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(filters[p]))
        }
      }
      return str.join('&')
    },
    // get object, loop through it, remove the null value
    getObjWithoutNullValueMixin: function (obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    },
    scrollToTopMixin: function () {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
      }, 100)
    }
  }
}
</script>
