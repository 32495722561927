import UserInfoService from '@/services/UserInfoService'

const getDefaultState = () => {
  return {
    usersInfo: null,
    selectedUsers: []
  }
}

// initial state
const state = getDefaultState()

// notice : actions handle asynchronous data
const actions = {
  async searchUser (context, filters) {
    const response = await UserInfoService.search(filters)
    context.commit('setUsersInfo', response)
    return response
  },
  reset ({ commit }) {
    commit('resetState')
  }
}
// notice : mutation only handle synchronous data
const mutations = {
  setUsersInfo (state, resp) {
    state.usersInfo = resp.data.userInfo
  },
  setSelectedUsers (state, users) {
    state.selectedUsers = users
  },
  resetState: (state) => {
    Object.assign(state, getDefaultState())
  }
}
const getters = {
  usersInfo: (state) => {
    return state.usersInfo
  },
  selectedUsers: (state) => {
    return state.selectedUsers
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
