<style>
</style>

<template>
  <div>
    <CheckRobot
      @sendUsername='onGetUsername($event)'
      v-if="step == 1"
    ></CheckRobot>
    <ChooseResetType
      @sendNewEmail="onGetNewEmail($event)"
      @sendPwd="toSendPwd($event)"
      :user="user"
      v-else-if="(step == 2) && user && user.email"
    ></ChooseResetType>
    <FinishReset v-else-if="(step == 3)"></FinishReset>
    <Error class="col-12 col-md-5 mx-auto" v-if="errors && errors.length" :errors="errors"></Error>
  </div>
</template>

<script>
import CheckRobot from './components/CheckRobot'
import ChooseResetType from './components/ChooseResetType'
import FinishReset from './components/FinishReset'
import UserInfoService from '../../services/UserInfoService'
import Error from '@/components/Error'

export default {
  name: 'ResetPwdOrEmail',
  components: {
    CheckRobot,
    ChooseResetType,
    FinishReset,
    Error
  },
  data: function () {
    return {
      step: 1,
      username: null,
      user: null,
      errors: []
    }
  },
  methods: {
    onGetStatus (step) {
      this.step = step
    },
    getUserByUsername (data) {
      this.$updateloading(1)
      UserInfoService.getUserByUsername(data)
        .then(res => {
          this.$updateloading(-1)
          this.user = res.data.user
        }).catch(error => {
          this.$updateloading(-1)
          this.errors = []

          this.errors.push(error)
        })
    },
    onGetUsername (username) {
      this.username = username
      this.step = 2
      this.getUserByUsername({ 'username': username })
    },
    onGetNewEmail (data) {
      this.$updateloading(1)
      UserInfoService.changeEmailAndPwd(data)
        .then(res => {
          this.$updateloading(-1)
          this.step = 3
        })
        .catch(error => {
          this.$updateloading(-1)
          this.errors = []
          this.errors.push(error)
        })
    },
    toSendPwd (data) {
      this.$updateloading(1)
      UserInfoService.generateTempPwd(data)
        .then(res => {
          this.$updateloading(-1)
          this.step = 3
        }).catch(error => {
          this.$updateloading(-1)
          this.errors = []
          this.errors.push(error)
        })
    }
  }
}
</script>
