<style scoped>
</style>

<template >
  <div>
    <div class="row mb-md-3">
      <div class="col-12 offset-md-1 col-md-6 mb-2 mb-md-0">
        <CourseGeneralInfoCard
          class="h-100"
          :course="course"
          :courseRecord="courseRecord"
        ></CourseGeneralInfoCard>
      </div>

      <div class="col-12 col-md-4 mb-2 mb-md-0">
        <TeeboxTable
          class="h-100"
          :teeboxes="teeboxes"
        ></TeeboxTable>
      </div>
    </div>
    <div class="row mb-md-3">
      <div class="col-12 mb-2 mb-md-0">
        <CourseConditionsCard
          class="h-100"
          :course="course"
        ></CourseConditionsCard>
      </div>
    </div>
    <div class="row mb-md-3">
      <div class="col-12 col-md-6 offset-md-3">
        <AimSelector
          :selectors="selectors"
          :selectedValue="defaultSelectorValue"
          @updateSelect="onGetSelect($event)"
        ></AimSelector>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-10 offset-md-1">
        <router-view
          :course="course"
          :teeboxes="teeboxes"
        ></router-view>
      </div>
    </div>
  </div>

</template>

<script>
import CourseService from '@/services/CourseService'
import CourseGeneralInfoCard from '@/components/CourseGeneralInfoCard'
import TeeboxTable from '@/components/teeboxTable'
import CourseConditionsCard from '@/components/CourseConditionsCard'
import AimSelector from '@/components/Aim/AimSelector'
import RoundService from '@/services/RoundService'
import AimCommonMixin from '@/mixins/aim.common.mixin'

export default {
  name: 'ViewCoures',
  mixins: [AimCommonMixin],
  components: {
    CourseGeneralInfoCard,
    TeeboxTable,
    CourseConditionsCard,
    AimSelector
  },
  data: function () {
    return {
      course: null,
      teeboxes: null,
      courseRecord: null,
      selectors: [
        { value: 'View My Scores', key: 'View Course Rounds' },
        { value: 'View Member Scores', key: 'View Member Rounds' },
        { value: 'View Course Handicaps', key: 'View Course Handicaps' }
      ],
      defaultSelectorValue: null
    }
  },
  mounted: function () {
    this.initSelectorValue()
    this.getCourse()
    this.setCourseRecord()
  },
  methods: {
    initSelectorValue: function () {
      let routeName = this.$route.name
      this.selectors.forEach((selector) => {
        if (selector.key === routeName) {
          console.log(selector.key)
          console.log(routeName)
          this.defaultSelectorValue = routeName
        }
      })
    },
    getCourse () {
      this.$updateloading(1)
      CourseService.getCourseInfo(+this.$route.params.cId).then((resp) => {
        this.$updateloading(-1)
        this.course = resp.data.course
        this.teeboxes = resp.data.teeboxes
      }).catch(errors => {
        this.$updateloading(-1)
        console.log(errors)
      })
    },
    onGetFilters (filters) {
      this.filters = filters
      this.getCourse()
    },
    onGetSelect (routeName) {
      this.$router.push({ name: routeName })
    },
    setCourseRecord () {
      this.$updateloading(1)
      RoundService.getCourseRecords(+this.$route.params.cId).then(res => {
        this.$updateloading(-1)
        this.courseRecord = res.data.results
      }).catch(errors => {
        this.$updateloading(-1)
        console.log(errors)
      })
    }
  }
}
</script>
