<template>
    <div>
        <b-form-group label="Select Time Period">
            <b-form-select v-model="selectedPeriod" @change="updateChart">
                <b-form-select-option :value="1">1 Year</b-form-select-option>
                <b-form-select-option :value="3">3 Years</b-form-select-option>
                <b-form-select-option :value="5">5 Years</b-form-select-option>
            </b-form-select>
        </b-form-group>
        <b-button @click="updateHandicapType">
            {{ isAntiHandicap ? 'Show Handicap' : 'Show Anti-Handicap' }}
        </b-button>
        <line-chart ref="lineChart" :chart-data="chartData" :options="chartOptions"></line-chart>
    </div>
</template>

<script>
import { Line } from 'vue-chartjs'

export default {
  name: 'PlayerHandicapChart',
  components: {
    LineChart: Line
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    antiHandicap: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      selectedPeriod: 1,
      isAntiHandicap: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Monthly Lowest Handicap',
            // backgroundColor: '#42b983',
            borderColor: '#42b983',
            fill: false,
            data: []
          }
        ]
      },
      chartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                reverse: false, // Invert y-axis to show improvement with lower handicaps
                beginAtZero: true,
                callback: function (value) { // Add a callback to format the ticks
                  return value
                }
              }
            }
          ]
        }
      }
    }
  },
  mounted () {
    this.updateChart()
  },
  methods: {
    updateHandicapType () {
      this.isAntiHandicap = !this.isAntiHandicap
      this.updateChart()
    },
    updateChart () {
      const data = this.isAntiHandicap ? this.antiHandicap : this.data
      // Filter data based on the selected period
      const periodData = this.filterDataByPeriod(data, this.selectedPeriod)
      const monthlyData = this.aggregateMonthlyData(periodData)
      this.chartData.labels = monthlyData.map(item => item.date)
      this.chartData.datasets[0].data = monthlyData.map(item => item.handicap)
      this.chartData.datasets[0].label = this.isAntiHandicap ? 'Monthly Lowest Anti-Handicap' : 'Monthly Lowest Handicap'
      this.$nextTick(() => {
        if (this.$refs.lineChart) {
          this.$refs.lineChart.renderChart(this.chartData, this.chartOptions)
        }
      })
    },
    filterDataByPeriod (data, period) {
      const currentDate = new Date()
      const pastDate = new Date()
      pastDate.setFullYear(currentDate.getFullYear() - period)
      return data.filter(item => new Date(item.date) >= pastDate)
    },
    aggregateMonthlyData (data) {
      const monthlyData = {}

      data.forEach(item => {
        const date = new Date(item.date)
        const month = date.getMonth()
        const year = date.getFullYear()
        const key = `${year}-${month + 1}`

        if (!monthlyData[key] || monthlyData[key] > item.handicap) {
          monthlyData[key] = item.handicap
        }
      })

      return Object.keys(monthlyData).map(key => ({
        date: key,
        handicap: monthlyData[key]
      }))
    }
  }
}
</script>

<style scoped>
</style>
