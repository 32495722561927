import TeeboxService from '@/services/TeeboxService'

const getDefaultState = () => {
  return {
    teeboxes: null
  }
}

const state = getDefaultState()

// notice : actions handle asynchronous data
const actions = {
  async searchTeeboxes (context, filters) {
    const response = await TeeboxService.searchTeebox(filters)
    context.commit('setTeeboxes', response)
    return response
  },
  reset ({ commit }) {
    commit('resetState')
  },

  setTeeboxes (context, teeboxes) {
    context.commit('setTeeboxes', { data: { teeboxes: teeboxes } })
  }
}

// notice : mutation only handle synchronous data
const mutations = {
  setTeeboxes (state, response) {
    state.teeboxes = response.data.teeboxes
  },
  resetState: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const getters = {
  teeboxes: (state) => {
    return state.teeboxes
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
