<style lang="scss" scoped>
</style>

<template>
  <div class="wrapper align-top">
    <i class="badge badge-primary">
      <span
        class="fas fa-check-circle"
      ></span>
    </i>
  </div>
</template>

<script>
export default {
  methods: {
  }
}
</script>
