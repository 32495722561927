<style scoped>
</style>

<template >
  <div class="row">
    <div class="col-12 mx-auto align-middle">
      <CourseList
        :postingRound="true"
        :filters="filters"
        :courseInfo="courseInfo"
        :title="'Step 1 : Select Round Course'"
        @updateFilters="onGetFilters($event)"
      >

        <!-- below is the slot in CourseList-->
        <template v-slot:filter>
          <CourseFilter
            :filters="filters"
            @updateFilter="onGetFilters($event)"
          ></CourseFilter>

          <div
            class="card mb-1"
            v-if="selectedCourses && selectedCourses.length"
          >
            <div class="card-header">
              <div class="row">
                <div class="col-12">
                  <b>Selected Course</b>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-12">
                  <AimBadge
                    class="mr-2 mb-1"
                    v-for='(course, index) in selectedCourses'
                    :key='index'
                    :infos='[course.name]'
                    @updateDelete="onGetDelete($event, course)"
                  >
                  </AimBadge>

                  <button
                    v-if="selectedCourses && selectedCourses[0]"
                    class="btn btn-info float-right"
                    @click="goToNextStep()"
                  >Go To Next Step</button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- previous is the slot in CourseList-->
      </CourseList>
    </div>

    <SelectCourseModal
      :course='selectedCourses[0]'
      @updateConfirm='onGetConfirm($event)'
    >
    </SelectCourseModal>
  </div>
</template>

<script>
import CourseList from '@/components/courseList/CourseList'
import CourseFilter from '@/components/courseFilter/CourseFilter'
import AimBadge from '@/components/Aim/AimBadge'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AimCommonMixin from '@/mixins/aim.common.mixin'
import AimActionMixin from '@/mixins/aim.action.mixin'
import SelectCourseModal from '@/components/modals/SelectCourseModal'

export default {
  name: 'SetRoundCourse',
  mixins: [AimCommonMixin, AimActionMixin],
  components: {
    CourseList,
    CourseFilter,
    AimBadge,
    SelectCourseModal
  },
  data: function () {
    return {
      filters: {
        sort: 'recent',
        desc: false,
        size: 10,
        page: 1
      }
    }
  },
  computed: {
    // use getter to get => course module's => coursesInfo getter
    ...mapGetters({
      courseInfo: 'course/coursesInfo',
      selectedCourses: 'course/selectedCourses',
      getRound: 'round/round'
    })
  },
  mounted: function () {
    this.searchCourses()
  },
  created () {
    this.resetRound()
    this.resetCourse()
    this.scrollToTopMixin()
  },
  methods: {
    //  get actions
    ...mapActions([
      'course/searchCourse', 'round/reset', 'userInfo/reset', 'course/reset', 'teebox/reset'
    ]),
    ...mapMutations([
      'course/setSelectedCourses', 'round/setRoundCourse'
    ]),
    async searchCourses () {
      this.$updateloading(1)
      // this.SerializeFiltersMixin is from mixins
      // we can diclare common use functions all around the project
      // we can import as much more mixins as we want
      // if current component has this.SerializeFiltersMixin() declared,
      // it will use current compoent and shadow the mixin functions.
      // otherwise use the functio from the mixins
      let filterStr = this.SerializeFiltersMixin(this.filters)
      // because we use mapActions first, it get course module's => searchCourse action
      // then we can use this to call
      await this['course/searchCourse'](filterStr)
      this.$updateloading(-1)
    },
    resetRound () {
      var temp = this.getRound.course
      this['round/reset']()
      this['userInfo/reset']()
      this['round/setRoundCourse'](temp)
    },
    resetCourse () {
      console.log('reseting course and teeboxes')
      this['course/reset']()
      this['teebox/reset']()
    },
    onGetFilters (filters) {
      this.filters = filters
      this.searchCourses()
    },
    onGetDelete: function (evt, course) {
      this.removeOneMixin(this.selectedCourses, course)
      this['course/setSelectedCourses'](this.selectedCourses)
    },
    onGetConfirm: function () {
      this.goToNextStep()
    },
    goToNextStep () {
      if (this.selectedCourses && this.selectedCourses.length) {
        this.pushCourseToRoundModule()
      }
    },
    pushCourseToRoundModule () {
      this['round/setRoundCourse'](this.selectedCourses[0])
      this.$router.push({ path: '/postRound/round' })
    }
  }
}
</script>
