import { render, staticRenderFns } from "./EditTeebox.vue?vue&type=template&id=6aa8c8dc&scoped=true"
import script from "./EditTeebox.vue?vue&type=script&lang=js"
export * from "./EditTeebox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa8c8dc",
  null
  
)

export default component.exports