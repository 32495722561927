<style scoped src="./RoundsSummaryTable.css">
</style>

<template src='./RoundsSummaryTable.html'>
</template>

<script>
export default {
  name: 'RoundsSummaryTable',
  data: () => {
    return {
      show: false
    }
  },
  props: {
    roundReviews: {
      type: Object
    },
    userid: {
      type: Number
    }
  },
  computed: {
    eightyPlus () {
      if (this.roundReviews && this.roundReviews.dob) {
        const dob = new Date(this.roundReviews.dob)
        const today = new Date()
        let age = today.getFullYear() - dob.getFullYear()
        const m = today.getMonth() - dob.getMonth()

        // Check if the current month is before the birth month or
        // if it's the same month but the current day is before the birthday
        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
          age--
        }
        const handicap = this.roundReviews.handicap
        // Ensure both values are treated as numbers
        const total = Number(age) + Number(handicap)
        return total > 80 ? 'Yes' : 'No'
      }
      return 'N/A'
    }
  },
  methods: {
    onNavToDetail: function (type) {
      this.$emit('onNavTo', type)
    },
    handicapChart: function () {
      this.$emit('handicapChart')
    }
  }
}
</script>
