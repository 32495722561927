
<style lang="scss" scoped>
  .recordSpace {
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }
</style>

<template>
  <div style="width:100%;">
    <div
      v-if="course"
      class="card mb-1 h-100"
    >
      <div class="card-header">
        <div class="row">
          <div class="col-12">
            <b>{{course.name}}</b>

            <div class="float-right">
              <router-link
                href
                :to="`/editCourse/${course.id}`"
              >
                update
              </router-link>
            </div>
          </div>

        </div>
      </div>

      <div class="card-body">
        <div
          class="row"
          v-if="course.address1"
        >
          <div class="col-4">
            <b>Address:</b>
          </div>
          <div class="col-8 text-right">
            <span v-if="course.address1"> {{course.address1}}</span>
          </div>
        </div>

        <div
          class="row"
          v-if="course.city || course.state || course.zip"
        >
          <div class="col-12 text-right">
            <span v-if="course.city"> {{course.city}}</span>
            <span v-if="course.state"> , {{course.state}}</span>
            <span v-if="course.zip"> , {{course.zip}}</span>
          </div>
        </div>

        <div class="row  text-right">
          <div class="col-12">
            <AimPhoneIcon
              class="mr-4 text-left"
              :phone="course.phonenum"
            ></AimPhoneIcon>

            <AimWebsiteIcon
              v-if="course.website"
              class="mr-4 text-left"
              :website="course.website"
            >
            </AimWebsiteIcon>
          </div>
          <div
            style="width:100%"
            class="h-100"
          >
            <div
              class="card table-title border-bottom-0 "
              v-if="courseRecord"
            >
              <div class="card-header text-center">
                <b>Nomad Course Record</b>
              </div>
            </div>

            <div class="table-responsive mb-0 table-striped border-top-0 table-bordered">
              <table class="table mb-0 table-striped">
                <thead>
                  <tr>
                    <th class="text-center align-middle">Player</th>
                    <th class="text-center align-middle">Date
                    </th>
                    <th class="text-center align-middle">Tee
                    </th>
                    <th class="text-center align-middle">Score
                    </th>
                  </tr>
                </thead>
                <tbody v-if="courseRecord">
                  <tr
                    v-for='record in courseRecord'
                    :key="record.id"
                  >
                    <td class="text-center align-middle">
                      <div v-for="n in record.names" :key="n.id" :class="record.names.length > 1 ? 'recordSpace' : ''">
                        {{ n }}
                      </div>
                    </td>
                    <td class="text-center align-middle">
                      <div v-for="d in record.dates" :key="d.id" :class="record.dates.length > 1 ? 'recordSpace' : ''">
                        {{ d }}
                      </div>
                    </td>
                    <td class="text-center align-middle">
                      <div v-for="d in record.tees" :key="d.id" :class="record.tees.length > 1 ? 'recordSpace' : ''">
                        {{ d }}
                      </div>
                    </td>
                    <td class="text-center align-middle">{{record.score}} ({{record.handicap}})</td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <tr>
                    <td
                      class="text-text-center"
                      colspan=3
                    >
                      <b>No records Info</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AimPhoneIcon from '@/components/Aim/icons/AimPhoneIcon'
import AimWebsiteIcon from '@/components/Aim/icons/AimWebsiteIcon'

export default {
  components: {
    AimPhoneIcon,
    AimWebsiteIcon
  },
  props: {
    course: {
      type: Object,
      default: function () {
        return null
      }
    },
    courseRecord: {
      type: Array,
      default: function () {
        return null
      }
    }
  }
}
</script>
